import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { smallIcon } from '../../functional/min-support';

class SimpleButton extends Component {

    static propTypes = {
        value: PropTypes.string,
        icon: PropTypes.string,
        color: PropTypes.string,
        size: PropTypes.number,
        style: PropTypes.object,
        outline: PropTypes.bool,

        onPress: PropTypes.func,
    };

    render () {
        let style = { fontSize: 12 };
        if (this.props.style) {
            style = { ...style, ...this.props.style };
        }
        const className = `btn btn-rounded ${ this.props.outline ? ' btn-outline-primary' : 'btn-invisible-primary' }`;
        return (
            <div className={className} onClick={this.props.onPress ? this.props.onPress : () => {}} style={style}>
                {
                    this.props.icon && smallIcon(this.props.icon, this.props.color ? this.props.color : '#363f5a', this.props.size ? this.props.size : 14, { paddingRight: 4 })
                }
                {this.props.value}
            </div>
        );
    }

}

export default SimpleButton;
