/**
 * @label Stable Imports
 */

import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';

/**
 * @label Unstable Imports
 */

import LaddaButton, { EXPAND_RIGHT, S } from 'react-ladda';

/**
 * @label Functionality Imports
 */

import { renderIf } from '../../../functional/min-support';

/**
 * @label Component Imports
 */

import SimpleButton from '../../input/SimpleButton';

/**
 * @label Asset Imports
 */

/**
 * Main structure for application modals.
 * @returns {JSX.Element}
 */

const BaseModal = (props) => {

    /**
     * @label Functionality : Start
     */

    const handleKeyboard = (event) => {
        if (props.ignoreKeyboard) return;
        if (event.keyCode === 13) { // Enter Key
            if (!props.hideDone && isReadyToSend()) {
                props.onDone();
            }
        }
    };

    const isReadyToSend = () => {
        if (props.hideDone) return false;
        return props.doneActive;
    };

    const handleDone = () => {
        if (isReadyToSend()) {
            props.onDone();
        }
    };

    /**
     * @label Functionality : End
     */


    /**
     * @label Render
     */

    const baseStyle = {};
    const mainStyle = {};
    const doneStyle = {};
    if (props.stableHeight) {
        mainStyle.height = '90%';
    }
    if (props.stableWidth) {
        mainStyle.width = '90%';
    }
    if (isReadyToSend()) {
        doneStyle.backgroundColor = '#888888';
    }

    if (props.overExpand) {
        baseStyle.paddingTop = '8px';
        mainStyle.height = '98%';
        mainStyle.width = '98%';
        mainStyle.maxHeight = '98%';
        mainStyle.maxWidth = '98%';
    }

    return (
        <Modal
            open={props.open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            onClose={props.onClose}
            BackdropProps={{ timeout: 500 }}
            className={'base-modal'}
            style={baseStyle}>
            <Fade in={props.open}>
                <div className={'modal-container'} tabIndex={0} onKeyDown={handleKeyboard} style={mainStyle}>

                    <div className={'modal-top-container'}>
                        <div className={'modal-close-container'}>
                            {
                                renderIf(Boolean(props.title),
                                    <div className={'modal-title-text'}>
                                        {
                                            props.title
                                        }
                                    </div>)
                            }
                            {
                                renderIf(!props.hideClose,
                                    <IconButton onClick={props.onClose} edge="start" color="inherit" aria-label="close">
                                        <CloseIcon color={'#00000033'} style={{ color: '#00000066' }} width={30} height={30}/>
                                    </IconButton>)
                            }
                        </div>
                        {
                            Boolean(props.renderFixedElements) && props.renderFixedElements()
                        }
                    </div>

                    <div className={'modal-content-container'}>
                        {
                            props.children
                        }
                    </div>

                    <div className={'modal-button-container'}>
                        {
                            renderIf(props.onMore,
                                <SimpleButton style={{ marginRight: !props.hideDone ? 10 : 0 }} value={props.onMoreText} onPress={props.onMore} icon={props.onMoreIcon ? props.onMoreIcon : null}/>)
                        }

                        {
                            props.onMoreButtons &&
                                props.onMoreButtons.map((button) => {
                                    return (
                                        <SimpleButton style={{ marginRight: 10 }} value={button.label} onPress={button.onPress} icon={button.icon ? button.icon : null}/>
                                    );
                                })
                        }

                        {
                            renderIf(!props.hideDone,
                                <LaddaButton
                                    disabled={!isReadyToSend()}
                                    loading={Boolean(props.loading)}
                                    onClick={handleDone}
                                    data-size={S}
                                    data-style={EXPAND_RIGHT}
                                    style={{ borderRadius: 4 }}
                                    className="btn btn-secondary">

                                    {props.doneActionTitle ? props.doneActionTitle : 'Salveaza'}

                                </LaddaButton>)
                        }

                        {
                            renderIf(false,
                                <div onClick={handleDone} style={doneStyle} className={'modal-button-done'}>
                                    {props.doneActionTitle ? props.doneActionTitle : 'Salveaza'}
                                </div>)
                        }
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};

/**
 * @label PropTypes
 */

BaseModal.propTypes = {
    open: PropTypes.bool.isRequired,

    title: PropTypes.string,

    hideDone: PropTypes.bool,
    hideClose: PropTypes.bool,

    doneActive: PropTypes.bool,

    stableHeight: PropTypes.bool,
    stableWidth: PropTypes.bool,

    renderFixedElements: PropTypes.func,

    onClose: PropTypes.func.isRequired,
    onMore: PropTypes.func,
    onDone: PropTypes.func,

    onMoreText: PropTypes.string,

    children: PropTypes.array,
};

export default BaseModal;
