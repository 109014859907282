/**
 * @label Stable Imports
 */

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Alert, Card, CardBody, Col, Form } from 'reactstrap';
import Recaptcha from 'react-recaptcha';

/**
 * @label Unstable Imports
 */

import LaddaButton, { EXPAND_LEFT } from 'react-ladda';

/**
 * @label Functionality Imports
 */

import { attemptCreateAccount } from '../../functional/logic';
import { applicationLogin } from '../../store/application-store';
import {
    inputValidationEmail,
    inputValidationEquals,
    inputValidationNonNull,
    inputValidationStringLengthBetween,
    inputValidationStringNonEmpty,
} from '../../functional/min-support';

/**
 * @label Component Imports
 */

import TextFieldInput from '../../components/input/TextFieldInput';
import SingleChoiceField from '../../components/input/SingleChoiceField';
import { counties } from '../../functional/counties';

/**
 * @label Asset Imports
 */

/**
 * Login Page.
 * @returns {JSX.Element}
 */

const CreateAccountAffiliatePage = (props) => {

    /**
     * @label Hooks : Start
     */

    const dispatch = useDispatch();
    const history = useHistory();

    /**
     * @label Hooks : End
     */


    /**
     * @label State : Start
     */

    const [ username, setUsername ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ secondPassword, setSecondPassword ] = useState('');
    const [ firstName, setFirstName ] = useState('');
    const [ lastName, setLastName ] = useState('');
    const [ county, setCounty ] = useState(null);

    const [ cid, setCid ] = useState('');
    const [ tri, setTri ] = useState('');
    const [ bankTitle, setBankTitle ] = useState('');
    const [ bankAccount, setBankAccount ] = useState('');

    const [ loading, setLoading ] = useState(false);
    const [ successful, setSuccessful ] = useState(false);
    const [ error, setError ] = useState(null);

    const [ captchaLoaded, setCaptchaLoaded ] = useState(false);
    const [ captchaVerified, setCaptchaVerified ] = useState(null);

    /**
     * @label State : End
     */


    /**
     * @label Functionality : Start
     */

    const handleCreateAccountPressed = (event) => {
        setSuccessful(false);
        setError('Crearea conturilor noi este momentan dezactivata.');
        return;
        if (event) event.preventDefault();
        setLoading(true);
        if (captchaLoaded && !captchaVerified) {
            setLoading(false);
            setSuccessful(false);
            setError('Trebuie sa completezi captcha-ul inainte de a te inregistra.');
            return;
        }

        if (!inputValidationStringNonEmpty(username)) {
            setLoading(false);
            setSuccessful(false);
            setError('Trebuie sa introduci numele de utilizator.');
            return;
        }

        if (!inputValidationEmail(email)) {
            setLoading(false);
            setSuccessful(false);
            setError('Trebuie sa introduci un email valid.');
            return;
        }

        if (!inputValidationNonNull(county)) {
            setLoading(false);
            setSuccessful(false);
            setError('Trebuie sa alegeti judetul.');
            return;
        }

        if (!inputValidationStringLengthBetween(password, 4, 100)) {
            setLoading(false);
            setSuccessful(false);
            setError('Parola trebuie sa aiba cel putin 4 caractere.');
            return;
        }

        if (!inputValidationEquals(secondPassword, password)) {
            setLoading(false);
            setSuccessful(false);
            setError('Parolele introduse nu se potrivesc.');
            return;
        }

        if (!inputValidationStringNonEmpty(firstName)) {
            setLoading(false);
            setSuccessful(false);
            setError('Trebuie sa introduci prenumele.');
            return;
        }
        if (!inputValidationStringNonEmpty(lastName)) {
            setLoading(false);
            setSuccessful(false);
            setError('Trebuie sa introduci numele.');
            return;
        }

        if (!inputValidationStringNonEmpty(cid)) {
            setLoading(false);
            setSuccessful(false);
            setError('Trebuie sa introduci codul de identificare fiscala.');
            return;
        }

        if (!inputValidationStringNonEmpty(tri)) {
            setLoading(false);
            setSuccessful(false);
            setError('Trebuie sa introduci numarul de inregistrare la registrul cometului.');
            return;
        }

        if (!inputValidationStringNonEmpty(bankTitle)) {
            setLoading(false);
            setSuccessful(false);
            setError('Trebuie sa introduci numele bancii.');
            return;
        }

        if (!inputValidationStringNonEmpty(bankAccount)) {
            setLoading(false);
            setSuccessful(false);
            setError('Trebuie sa introduci codul iban al contului bancar.');
            return;
        }

        attemptCreateAccount(firstName, lastName, username, password, email, county, cid, tri, bankTitle, bankAccount, (successful, data) => {
            if (successful) {
                console.log('success', data);
                dispatch(applicationLogin(data.user));
                history.push('/dashboard');
            } else {
                console.log('error', data);
                setLoading(false);
                setSuccessful(false);
                setError('Numele de utilizator este deja utilizat.');
            }
        });
    };

    /**
     * @label Functionality : End
     */


    /**
     * @label Effects : Start
     */

    useEffect(() => {
    }, []);

    /**
     * @label Effects : End
     */


    /**
     * @label Render
     */

    return (
        <div className="login-page">
            <div className="login-page-overlay" style={{ overflowY: 'scroll', paddingTop: 20, paddingBottom: 20 }}>
                <Col md="4" className="m-auto align-middle d-flex align-items-center py-2" >
                    <Card className="flex-grow-1">
                        <CardBody>
                            <h4 className="card_title text-primary">Creaza cont afiliat Olacrm</h4>
                            { successful && <Alert color="primary">Inregistrare reusita.</Alert> }
                            { error && <Alert color="danger">Inregistrare esuata. {error}</Alert> }
                            <Form onSubmit={handleCreateAccountPressed}>
                                <TextFieldInput
                                    title
                                    required
                                    classname={'mb-4'}
                                    validated={inputValidationStringNonEmpty(username)}
                                    label={'Nume utilizator'}
                                    value={username}
                                    icon={'ft-user'}
                                    placeholder={'Introduceti numele utilizatorului'}
                                    onChange={(newValue) => { setUsername(newValue); }}/>

                                <TextFieldInput
                                    title
                                    required
                                    classname={'mb-4'}
                                    validated={inputValidationEmail(email)}
                                    label={'Adresa de email'}
                                    value={email}
                                    icon={'ft-at-sign'}
                                    placeholder={'Introduceti adresa de email'}
                                    onChange={(newValue) => { setEmail(newValue); }}/>

                                <TextFieldInput
                                    title
                                    required
                                    classname={'mb-4'}
                                    validated={inputValidationStringNonEmpty(firstName)}
                                    label={'Prenumele'}
                                    value={firstName}
                                    icon={'ft-align-justify'}
                                    placeholder={'Introduceti prenumele'}
                                    onChange={(newValue) => { setFirstName(newValue); }}/>

                                <TextFieldInput
                                    title
                                    required
                                    classname={'mb-4'}
                                    validated={inputValidationStringNonEmpty(lastName)}
                                    label={'Numele'}
                                    value={lastName}
                                    icon={'ft-align-justify'}
                                    placeholder={'Introduceti numele'}
                                    onChange={(newValue) => { setLastName(newValue); }}/>

                                <SingleChoiceField
                                    required
                                    validated={inputValidationNonNull(county)}

                                    search
                                    icon={'ft-map-pin'}
                                    label={'Judetul'}
                                    options={counties}
                                    value={county}
                                    style={{ marginBottom: 24 }}
                                    onSelect={(value) => {
                                        setCounty(value);
                                    }}/>

                                <TextFieldInput
                                    title
                                    required
                                    classname={'mb-4'}
                                    validated={inputValidationStringNonEmpty(cid)}
                                    label={'Cod identificare fiscala'}
                                    value={cid}
                                    icon={'ft-info'}
                                    placeholder={'Introduceti codule de identificare fiscala'}
                                    onChange={(newValue) => { setCid(newValue); }}/>

                                <TextFieldInput
                                    title
                                    required
                                    classname={'mb-4'}
                                    validated={inputValidationStringNonEmpty(tri)}
                                    label={'Nr. inregistrare reg. comertului'}
                                    value={tri}
                                    icon={'blank'}
                                    placeholder={'Introduceti nr. de inregistrare'}
                                    onChange={(newValue) => { setTri(newValue); }}/>

                                <TextFieldInput
                                    title
                                    required
                                    classname={'mb-4'}
                                    validated={inputValidationStringNonEmpty(bankTitle)}
                                    label={'Numele bancii'}
                                    value={bankTitle}
                                    icon={'ft-credit-card'}
                                    placeholder={'Introduceti numele bancii la care are firma contul bancar'}
                                    onChange={(newValue) => { setBankTitle(newValue); }}/>

                                <TextFieldInput
                                    title
                                    required
                                    classname={'mb-4'}
                                    validated={inputValidationStringNonEmpty(bankAccount)}
                                    label={'IBAN cont bancar'}
                                    value={bankAccount}
                                    icon={'blank'}
                                    placeholder={'Introduceti codul IBAN al contului bancar'}
                                    onChange={(newValue) => { setBankAccount(newValue); }}/>

                                <TextFieldInput
                                    title
                                    required
                                    classname={'mb-4'}
                                    validated={inputValidationStringLengthBetween(password, 4, 100)}
                                    label={'Parola'}
                                    value={password}
                                    icon={'ft-unlock'}
                                    secure
                                    placeholder={'Introduceti parola'}
                                    onChange={(newValue) => { setPassword(newValue); }}/>

                                <TextFieldInput
                                    title
                                    required
                                    classname={'mb-4'}
                                    validated={inputValidationEquals(secondPassword, password)}
                                    label={'Confirmati parola'}
                                    value={secondPassword}
                                    icon={'ft-lock'}
                                    secure
                                    placeholder={'Introduceti parola din nou'}
                                    onChange={(newValue) => { setSecondPassword(newValue); }}/>

                                <Recaptcha
                                    sitekey="6LcgL3IfAAAAAFLpGBdwD5YLWh8ieT6tEr_4TCfY"
                                    render="explicit"
                                    expiredCallback={() => { setCaptchaVerified(null); }}
                                    onloadCallback={() => { console.log('load'); setCaptchaLoaded(true); }}
                                    verifyCallback={(verification) => { console.log('verify'); setCaptchaVerified(verification); }}/>

                                <div className="submit-btn-area mt-4">

                                    <LaddaButton
                                        loading={loading}
                                        onClick={handleCreateAccountPressed}
                                        data-style={EXPAND_LEFT}
                                        className="btn btn-primary">

                                        Inregistrare

                                    </LaddaButton>

                                </div>

                                <div className="form-footer text-center mt-4">
                                    <p className="text-muted">Ai deja cont? <Link to={{ pathname: '/login' }}><i className="mdi mdi-lock"></i>Autentifica-te</Link></p>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </div>
        </div>
    );
};


export default CreateAccountAffiliatePage;
