/**
 * @label Stable Imports
 */

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Alert, Card, CardBody, Col, Form } from 'reactstrap';

/**
 * @label Unstable Imports
 */

import LaddaButton, { EXPAND_LEFT } from 'react-ladda';

/**
 * @label Functionality Imports
 */

import { attemptAuthenticate } from '../../functional/logic';
import { applicationLogin } from '../../store/application-store';
import { getLoginUsername } from '../../functional/storage';
import { inputValidationStringLengthBetween, inputValidationStringNonEmpty, is } from '../../functional/min-support';

/**
 * @label Component Imports
 */

import TextFieldInput from '../../components/input/TextFieldInput';
import Recaptcha from 'react-recaptcha';

/**
 * @label Asset Imports
 */

/**
 * Login Page.
 * @returns {JSX.Element}
 */

const LoginPage = (props) => {

    /**
     * @label Hooks : Start
     */

    const dispatch = useDispatch();
    const history = useHistory();

    /**
     * @label Hooks : End
     */


    /**
     * @label State : Start
     */

    const [ username, setUsername ] = useState('');
    const [ password, setPassword ] = useState('');

    const [ loading, setLoading ] = useState(false);
    const [ successful, setSuccessful ] = useState(false);
    const [ error, setError ] = useState(null);

    const [ captchaLoaded, setCaptchaLoaded ] = useState(false);
    const [ captchaVerified, setCaptchaVerified ] = useState(null);

    /**
     * @label State : End
     */


    /**
     * @label Functionality : Start
     */

    const attemptToGetLastUsername = () => {
        const lastUsername = getLoginUsername();
        if (is(lastUsername) && lastUsername !== '') {
            setUsername(lastUsername);
        }
    };

    const handleLoginPressed = (event) => {
        if (event) event.preventDefault();
        setLoading(true);

        if (captchaLoaded && !captchaVerified) {
            setLoading(false);
            setSuccessful(false);
            setError('Trebuie sa completezi captcha-ul inainte de a te autentifica.');
            return;
        }

        if (!inputValidationStringNonEmpty(username)) {
            setLoading(false);
            setSuccessful(false);
            setError('Trebuie sa introduci numele de utilizator.');
            return;
        }
        if (!inputValidationStringLengthBetween(password, 4, 100)) {
            setLoading(false);
            setSuccessful(false);
            setError('Parola trebuie sa aiba cel putin 4 caractere.');
            return;
        }

        attemptAuthenticate(username, password, (successful, data) => {
            if (successful) {
                dispatch(applicationLogin(data.user));
                history.push('/dashboard');
            } else {
                setLoading(false);
                setSuccessful(false);
                setError('Utilizatorul si parola nu se potrivesc.');
            }
        });
    };

    /**
     * @label Functionality : End
     */


    /**
     * @label Effects : Start
     */

    useEffect(() => {
        attemptToGetLastUsername();
    }, []);

    /**
     * @label Effects : End
     */


    /**
     * @label Render
     */

    return (
        <div className="login-page">
            <div className="login-page-overlay">
                <Col md="4" className="m-auto h-100 align-middle d-flex align-items-center">
                    <Card className="flex-grow-1">
                        <CardBody>
                            <h4 className="card_title text-primary">Autentificare Olacrm</h4>
                            { successful && <Alert color="primary">Autentificare reusita.</Alert> }
                            { error && <Alert color="danger">Autentificare esuata. {error}</Alert> }
                            <Form onSubmit={handleLoginPressed}>
                                <TextFieldInput
                                    title
                                    required
                                    classname={'mb-4'}
                                    validated={inputValidationStringNonEmpty(username)}
                                    label={'Nume utilizator'}
                                    value={username}
                                    icon={'ft-lock'}
                                    placeholder={'Introduceti numele utilizatorului'}
                                    onChange={(newValue) => { setUsername(newValue); }}/>

                                <TextFieldInput
                                    title
                                    required
                                    classname={'mb-4'}
                                    validated={inputValidationStringLengthBetween(password, 4, 100)}
                                    label={'Parola'}
                                    value={password}
                                    icon={'ft-lock'}
                                    secure
                                    placeholder={'Introduceti parola'}
                                    onChange={(newValue) => { setPassword(newValue); }}/>

                                <Recaptcha
                                    sitekey="6LcgL3IfAAAAAFLpGBdwD5YLWh8ieT6tEr_4TCfY"
                                    render="explicit"
                                    expiredCallback={() => { setCaptchaVerified(null); }}
                                    onloadCallback={() => { console.log('load'); setCaptchaLoaded(true); }}
                                    verifyCallback={(verification) => { console.log('verify'); setCaptchaVerified(verification); }}/>

                                <div className="submit-btn-area mt-4">

                                    <LaddaButton
                                        loading={loading}
                                        onClick={handleLoginPressed}
                                        data-style={EXPAND_LEFT}
                                        className="btn btn-primary">

                                        Autentificare

                                    </LaddaButton>

                                </div>

                                <div className="form-footer text-center mt-4">
                                    {/*<p className="text-muted">Vrei cont de afiliat? <Link to={{ pathname: '/register' }}><i className="mdi mdi-lock"></i>Inregistreaza-te</Link></p>*/}
                                    {/*<p className="text-muted">Nu stii ce este asta?<Link to={{ pathname: 'https://utildeco.ro' }} target="_blank"><i className="mdi mdi-lock"></i>Citeste mai multe</Link></p>*/}
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </div>
        </div>
    );
};


export default LoginPage;
