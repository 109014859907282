import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


import { addOperator } from '../../functional/api';
import BaseModal from './model/BaseModal';
import TextFieldInput from '../input/TextFieldInput';
import { kToastActionError } from '../general/Toast';
import { inputValidationNonEmpty, inputValidationSingleChoice } from '../../functional/min-support';
import Form from '../input/Form';
import SingleChoiceField from '../input/SingleChoiceField';
import {
    applicationAddSnack,
    applicationCloseLoadingModal,
    applicationOpenLoadingModal,
} from '../../store/application-store';

class Modal extends Component {
    
    static propTypes = {
        user: PropTypes.object,
        open: PropTypes.bool,
        
        onClose: PropTypes.func,

        toast: PropTypes.func,
        openLoading: PropTypes.func,
        closeLoading: PropTypes.func,
    };
    
    state = {
        firstName: '',
        lastName: '',
        username: '',
        password: '',
        gender: 'female',

        formComplete: false,
    };

    render () {

        return (
            <BaseModal
                title={'Adauga utilizator'}
                stableHeight
                open={this.props.open}
                doneActive={this.state.formComplete}
                onClose={this.onClose}
                onDone={this.onDone}>

                <div className={'w-100'}>

                    <Form complete={this.state.formComplete} setComplete={(complete) => { this.setState({ formComplete: complete }); }}>

                        <TextFieldInput
                            required
                            validated={inputValidationNonEmpty(this.state.firstName)}

                            icon={'ft-align-justify'}
                            value={this.state.firstName}
                            style={{ marginBottom: 4 }}
                            label={'Prenume utilizator'}
                            onChange={(newValue) => {
                                this.setState({
                                    firstName: newValue,
                                });
                            }}/>

                        <TextFieldInput
                            required
                            validated={inputValidationNonEmpty(this.state.lastName)}

                            icon={'ft-align-justify'}
                            value={this.state.lastName}
                            style={{ marginBottom: 4 }}
                            label={'Nume utilizator'}
                            onChange={(newValue) => {
                                this.setState({
                                    lastName: newValue,
                                });
                            }}/>

                        <TextFieldInput
                            required
                            validated={inputValidationNonEmpty(this.state.username)}

                            icon={'ft-lock'}
                            value={this.state.username}
                            style={{ marginBottom: 4 }}
                            label={'Nume autentificare'}
                            onChange={(newValue) => {
                                this.setState({
                                    username: newValue,
                                });
                            }}/>

                        <TextFieldInput
                            required
                            validated={inputValidationNonEmpty(this.state.password)}

                            icon={'ft-lock'}
                            value={this.state.password}
                            style={{ marginBottom: 4 }}
                            label={'Parola autentificare'}
                            onChange={(newValue) => {
                                this.setState({
                                    password: newValue,
                                });
                            }}/>
    
                        <SingleChoiceField
                            required
                            validated={inputValidationSingleChoice(this.state.gender)}
        
                            icon={'ft-users'}
                            label={'Genul utilizatorului'}
                            options={[
                                {
                                    label: 'Femeie',
                                    value: 'female',
                                },
                                {
                                    label: 'Barbat',
                                    value: 'male',
                                },
                            ]}
                            value={this.state.gender}
                            style={{ marginBottom: 4 }}
                            onSelect={(value) => {
                                this.setState({
                                    gender: value,
                                });
                            }}/>

                    </Form>

                </div>

            </BaseModal>
        );
    }

    onClose = () => {
        this.props.onClose();
    };

    onDone = () => {
        const request = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            username: this.state.username,
            password: this.state.password,
            gender: this.state.gender,
        };

        this.props.openLoading();

        addOperator(request, (successful) => {
            if (successful) {
                this.clear();
                this.props.toast('Utilizator inregistrat cu succes.');
            } else {
                this.props.toast('Operatiune esuata!', kToastActionError);
            }
            this.props.closeLoading();
            this.onClose();
        });
    };

    componentWillReceiveProps (nextProps, nextContext) {
        if (!this.props.open && nextProps.open) {
            this.update();
        }
    }
    
    update = () => {

    };

    clear = () => {
        this.setState({
            firstName: '',
            lastName: '',
            username: '',
            password: '',
            type: 'female',
        });
    };

}

const mapStateToProps = (state) => {
    return {
        user: state.application.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toast: (label, action) => { return dispatch(applicationAddSnack(label, action)); },

        openLoading: () => { return dispatch(applicationOpenLoadingModal()); },
        closeLoading: () => { return dispatch(applicationCloseLoadingModal()); },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);