import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getOperators } from '../functional/api';
import { withRouter } from 'react-router-dom';
import { kFilterOperatorFields, kFilterOperatorFieldsDefault } from '../functional/constants';
import ListPage from '../layout/page/ListPage';
import TextFieldDisplay from '../components/input/TextFieldDisplay';
import AddOperatorModal from '../components/modal/AddOperatorModal';
import { toClientPage, toUserPage } from '../functional/navigation';
import { compareGeneratorString, prettyDateAndTime } from '../functional/min-support';
import TablePage from '../layout/page/TablePage';

class UsersPage extends Component {

    static propTypes = {
        history: PropTypes.object,
    };

    static defaultState = {
        items: null,
        modalOpen: false,
    };

    state = UsersPage.defaultState;

    componentDidMount () {
        this.update();
    }

    generalUpdate = () => {
        this.setState(UsersPage.defaultState, this.update);
    };

    update = () => {
        getOperators((successful, data) => {
            if (successful) {
                data = data.map((item) => {
                    return {
                        ...item,
                        level_text: getLevelNameBasedOnLevel(item.level),
                        firm: item.bankTitle && item.bankAccount && item.cid && item.cid ? 'Date complete' : item.bankTitle || item.bankAccount || item.cid || item.cid ? 'Date partiale' : 'Fara date',
                    };
                });
                console.log('operators', data);
                this.setState({
                    items: data,
                });
            } else {
                console.error('no entries');
            }
        });
    };

    render () {
        return (
            <TablePage
                onRefresh={this.generalUpdate}
                grid
                deepSearch={false}
                loading={this.state.items === null}
                actions={[
                    {
                        title: 'Adauga utilizator',
                        icon: { title: 'ft-plus' },
                        onPress: () => {
                            this.setState({
                                modalOpen: true,
                            });
                        },
                    },
                ]}
                allListItems={this.state.items}
                renderListItem={(item, dark) => {
                    return (
                        <ListItem
                            key={item.id}
                            item={item}
                            dark={dark}
                            onPress={this.onItemClick}/>
                    );
                }}

                tableFields={{
                    'name': {
                        title: 'Nume',
                        transform: (item) => { return item.fullName; },
                        action: (item) => { this.onItemClick(item); },
                        compare: compareGeneratorString('fullName'),
                        icon: 'ft-user',
                    },
                    'username': {
                        title: 'Utilizator',
                        transform: (item) => { return item.username; },
                        action: (item) => { this.onItemClick(item); },
                        compare: compareGeneratorString('username'),
                        icon: 'ft-lock',
                    },
                    'level': {
                        title: 'Tipul',
                        transform: (item) => { return item.level_text; },
                        compare: compareGeneratorString('level_text'),
                        icon: 'ft-clock',
                    },
                    'firm': {
                        title: 'Firma',
                        transform: (item) => { return item.firm; },
                        weight: 0.5,
                        icon: 'ft-credit-card',
                    },
                    'created': {
                        title: 'Creat la',
                        transform: (item) => { return prettyDateAndTime(item.created); },
                        compare: compareGeneratorString('created'),
                        icon: 'ft-clock',
                    },

                    'view': {
                        weight: 0.5,
                        title: '',
                        transform: (item) => { return 'Vezi'; },
                        action: (item) => { this.onItemClick(item); },
                    },
                }}
                defaultSort={'created'}

                defaultFields={kFilterOperatorFieldsDefault}
                allFields={kFilterOperatorFields}>

                <AddOperatorModal
                    open={this.state.modalOpen}
                    onClose={() => {
                        this.setState({
                            modalOpen: false,
                        });
                        this.update();
                    }}/>

            </TablePage>
        );
    }

    onItemClick = (item) => {
        toUserPage(item._id);
    };

}

export const getLevelNameBasedOnLevel = (level) => {
    if (level >= 600) {
        return 'Cont de administrator global';
    } else if (level >= 400) {
        return 'Cont de administrator';
    } else if (level >= 200) {
        return 'Cont de operator';
    } else if (level >= 100) {
        return 'Cont de afiliat';
    } else if (level >= 0) {
        return 'Vizitator';
    }
    return 'neidentificat';
};

const ListItem = (props) => {
    return (
        <div className={'grid-list-item'} style={{ height: 'fit-content' }}>

            <div className={'inside-card'} style={{ backgroundColor: props.dark ? '#dddddd' : '#eeeeee' }}>

                <div className={'internal-container'}>
                    <TextFieldDisplay
                        oneLine
                        value={props.item.fullName}
                        style={{ marginBottom: 4 }}/>

                    <TextFieldDisplay
                        oneLine
                        value={props.item.username}
                        icon={'ft-lock'}
                        style={{ marginBottom: 4 }}/>

                    <TextFieldDisplay
                        oneLine
                        value={getLevelNameBasedOnLevel(props.item.level)}
                        icon={'ft-layers'}
                        style={{ marginBottom: 4 }}/>

                    <TextFieldDisplay
                        oneLine
                        value={props.item.bankTitle && props.item.bankAccount && props.item.cid && props.item.tri ? 'Are date fiscale' : 'Nu are date fiscale'}
                        icon={'ft-watch'}
                        style={{ marginBottom: 4 }}/>
                </div>

                <div className={'item-action-button'}>
                    <div onClick={() => { props.onPress(props.item); }} className={'item-action-button-inside'}>
                        {'Vezi utilizatorul'}
                    </div>
                </div>

            </div>

        </div>
    );
};

ListItem.propTypes = {
    item: PropTypes.object,
    onPress: PropTypes.func,

    empty: PropTypes.bool,
};

export default withRouter(UsersPage);
