import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


import { addEstimative, getStockOwn } from '../../functional/api';
import { openPDFViewModal } from '../../store/actions/application-store-actions';
import BaseModal from './model/BaseModal';
import { inputValidationSpecificAllStockHasQuantityAndPrice } from '../../functional/min-support';
import TextFieldInput from '../input/TextFieldInput';
import { kToastActionError } from '../general/Toast';
import Form from '../input/Form';
import MultipleChoiceField from '../input/MultipleChoiceField';
import TextFieldDisplay from '../input/TextFieldDisplay';
import {
    applicationAddSnack,
    applicationCloseLoadingModal,
    applicationOpenLoadingModal,
} from '../../store/application-store';

const kSaleTypes = [
    {
        value: 'client',
        label: 'Client',
        description: 'Produsele sunt vandute unui client obisnuit.',
    },
    {
        value: 'manager',
        label: 'Manager partener',
        description: 'Produsele sunt vandute unui manager partener.',
    },
];

class Modal extends Component {
    
    static propTypes = {
        open: PropTypes.bool,
        
        onClose: PropTypes.func,
        
        toast: PropTypes.func,
        openLoading: PropTypes.func,
        closeLoading: PropTypes.func,
        
        key: PropTypes.any,
    };
    
    state = {
        clientId: -1,
        stock: [],
        stockSelected: [],
        stockQuantity: {},
        stockPrice: {},
        
        name: '',
        
        saleType: 'client',
        
        workHours: '',
        workCost: '',
        
        addedFields: [],
        
        formComplete: false,
    };
    
    render () {
        return (
            <BaseModal
                title={'Adauga deviz estimativ'}
                stableHeight
                open={this.props.open}
                doneActive={this.state.formComplete}
                onClose={this.onClose}
                onDone={this.onDone}>
                
                <div className={'w-100'}>
                    
                    <Form complete={this.state.formComplete} setComplete={(complete) => {
                        this.setState({ formComplete: complete });
                    }}>
    
                        <div
                            style={{
                                width: '100%',
                                padding: 10,
                                marginBottom: 10,
                                backgroundColor: '#eeeeee',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-end',
                                flex: 5,
                                borderRadius: 4,
                            }}>
        
                            <TextFieldInput
                                flex={1}
                                style={{ flex: 1 }}
                                label={'Numar de ore'}
                                value={this.state.workHours}
                                onChange={(newValue) => {
                                    this.setState({ workHours: newValue });
                                }}/>
        
                            <TextFieldInput
                                flex={1}
                                style={{ flex: 1 }}
                                label={'Cost per ora (RON)'}
                                value={this.state.workCost}
                                onChange={(newValue) => {
                                    this.setState({ workCost: newValue });
                                }}/>
    
                        </div>
                        
                        {
                            this.state.addedFields.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        style={{
                                            width: '100%',
                                            padding: 10,
                                            marginBottom: 10,
                                            backgroundColor: '#eeeeee',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'flex-end',
                                            flex: 5,
                                            borderRadius: 4,
                                        }}>
    
                                        <TextFieldInput
                                            flex={1}
                                            style={{ flex: 4 }}
                                            label={'Titlu actiune'}
                                            value={item.name}
                                            onChange={(newValue) => {
                                                const addedFields = this.state.addedFields;
                                                addedFields[ index ].name = newValue;
                                                this.setState({ addedFields });
                                            }}/>
        
                                        <TextFieldInput
                                            flex={1}
                                            style={{ flex: 1 }}
                                            label={'Cost (RON)'}
                                            value={item.cost}
                                            onChange={(newValue) => {
                                                const addedFields = this.state.addedFields;
                                                addedFields[ index ].cost = newValue;
                                                this.setState({ addedFields });
                                            }}/>
    
                                    </div>
                                );
                            })
                        }
    
                        <div
                            style={{
                                width: '100%',
                                padding: 10,
                                marginBottom: 10,
                                backgroundColor: '#eeeeee',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-end',
                                flex: 5,
                                borderRadius: 4,
                            }}>
        
                            <TextFieldDisplay
                                style={{ flex: 4 }}
                                label={'Altele'}
                                value={'Adauga un alt camp'}
                                action={'edit'}
                                onPress={this.addField}/>
    
                        </div>
                        
                        <MultipleChoiceField
                            required
                            requireCompletion
                            validated={true} // inputValidationLengthNonZero(this.state.stockSelected)
                            search
                            
                            minimumChoices={0}
                            maximumChoices={100}
                            
                            icon={'count'}
                            label={'Alege piesele care sunt incluse'}
                            options={this.state.stock}
                            values={this.state.stockSelected}
                            style={{ marginBottom: 4 }}
                            onSelect={(values) => {
                                this.setState({
                                    stockSelected: values,
                                });
                            }}/>
                        
                        <div
                            requireCompletion
                            required
                            validated={inputValidationSpecificAllStockHasQuantityAndPrice(this.state.stockSelected, this.state.stockQuantity, this.state.stockPrice)}>
                            {
                                this.state.stockSelected.map((item) => {
                                    let found = this.state.stock.filter((i) => { return i.value === item; });
                                    if (found.length > 0) {
                                        found = found[ 0 ];
                                        return (
                                            <StockEntry
                                                item={found}
                                                onChange={(value, newCount, newPrice) => {
                                                    
                                                    if (parseFloat(newCount) > parseFloat(found.total)) {
                                                        newCount = found.total;
                                                    }
                                                    const q = this.state.stockQuantity;
                                                    q[ value ] = newCount;
                                                    
                                                    const p = this.state.stockPrice;
                                                    p[ value ] = newPrice;
                                                    
                                                    this.setState({
                                                        stockQuantity: q,
                                                        stockPrice: p,
                                                    });
                                                }}
                                                counter={found.counter}
                                                maxQuantity={found.total}
                                                quantity={this.state.stockQuantity.hasOwnProperty(found.value) ? this.state.stockQuantity[ found.value ] : ''}
                                                price={this.state.stockPrice.hasOwnProperty(found.value) ? this.state.stockPrice[ found.value ] : ''}
                                            />
                                        );
                                    } else {
                                        return null;
                                    }
                                })
                            }
                        </div>
                    
                    </Form>
                
                </div>
            
            </BaseModal>
        );
    }
    
    addField = () => {
        const addedFields = this.state.addedFields;
        addedFields.push({ name: '', cost: '' });
        this.setState({
            addedFields,
        });
    };
    
    onClose = () => {
        this.props.onClose();
    };
    
    onDone = () => {
        const selected = this.state.stockSelected;
        const stock = [];
        for (let i = 0; i < selected.length; ++i) {
            stock.push({
                count: this.state.stockQuantity[ selected[ i ] ],
                price: this.state.stockPrice[ selected[ i ] ],
                value: selected[ i ],
            });
        }
        
        const request = {
            stock,
            ticketId: this.props.parentKey,
            workHours: this.state.workHours,
            workCost: this.state.workCost,
            others: this.state.addedFields,
        };
        
        this.props.openLoading();
        
        addEstimative(request, (successful, result) => {
            if (successful) {
                this.clear();
                const loadId = result;
                
            } else {
                this.props.toast('Operatiune esuata!', kToastActionError);
            }
            this.props.closeLoading();
            this.onClose();
        });
    };
    
    componentWillReceiveProps (nextProps, nextContext) {
        if (!this.props.open && nextProps.open) {
            this.update();
        }
    }
    
    update = () => {
        getStockOwn((successful, data) => {
            if (successful) {
                this.setState({
                    stock: data.map((item) => {
                        return {
                            value: item.id,
                            label: item.name,
                            description: `Ai in stoc: ${ item.total } ${ item.counter }. Pretul din baza de date: ${ item.price } RON`,
                            total: item.total,
                            counter: item.counter,
                            price: item.price,
                        };
                    }),
                });
            } else {
                console.error('no add action info');
            }
        });
    };
    
    clear = () => {
        this.setState({
            clientId: -1,
            stock: [],
            stockSelected: [],
            stockQuantity: {},
            stockPrice: {},
            name: '',
            saleType: 'client',
        });
    };
    
}

const StockEntry = (props) => {
    return (
        <div
            style={{
                width: '100%',
                padding: 10,
                marginBottom: 10,
                backgroundColor: '#eeeeee',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
                flex: 5,
                borderRadius: 4,
            }}>
            
            <TextFieldDisplay
                style={{ flex: 4 }}
                label={'piesa'}
                value={props.item.label}
                icon={'intervention'}/>
            
            <TextFieldInput
                flex={1}
                style={{ flex: 1 }}
                label={`Numar ${ props.counter }`}
                value={props.quantity}
                onChange={(newValue) => {
                    props.onChange(props.item.value, newValue, props.price);
                }}/>
            
            <TextFieldInput
                flex={1}
                style={{ flex: 1 }}
                label={'Pret per U.M. (RON)'}
                value={props.price}
                onChange={(newValue) => {
                    props.onChange(props.item.value, props.quantity, newValue);
                }}/>
        
        </div>
    );
};

StockEntry.propTypes = {
    item: PropTypes.object,
    onPress: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {
        user: state.application.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toast: (label, action) => { return dispatch(applicationAddSnack({ label, action })); },
        
        openLoading: () => { return dispatch(applicationOpenLoadingModal()); },
        closeLoading: () => { return dispatch(applicationCloseLoadingModal()); },
    
        openPDFViewModal: (link) => { return dispatch(openPDFViewModal(link)); },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);