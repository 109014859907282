import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


import { createTicket, markDebugTicket } from '../../functional/api';
import BaseModal from './model/BaseModal';
import SingleChoiceField from '../input/SingleChoiceField';
import {
    inputValidationNonEmpty,
    inputValidationSingleChoice,
    inputValidationYesSelected,
} from '../../functional/min-support';
import TextFieldInput from '../input/TextFieldInput';
import { kToastActionError } from '../general/Toast';
import Form from '../input/Form';
import {
    applicationAddSnack,
    applicationCloseLoadingModal,
    applicationOpenLoadingModal,
} from '../../store/application-store';

class Modal extends Component {
    
    static propTypes = {
        open: PropTypes.bool,
        
        onClose: PropTypes.func,

        toast: PropTypes.func,
        openLoading: PropTypes.func,
        closeLoading: PropTypes.func,
    };
    
    state = {
        debug: '',
        cancelValue: -1,

        formComplete: false,
    };

    render () {
        return (
            <BaseModal
                title={'Semnaleaza problema'}
                stableHeight
                open={this.props.open}
                doneActive={this.state.formComplete}
                onClose={this.onClose}
                onDone={this.onDone}>

                <div className={'w-100'}>

                    <Form complete={this.state.formComplete} setComplete={(complete) => { this.setState({ formComplete: complete }); }}>
                        <TextFieldInput
                            required
                            validated={inputValidationNonEmpty(this.state.debug)}
                            advancedPadding
                            icon={'ft-hash'}
                            value={this.state.debug}
                            style={{ marginBottom: 12 }}
                            label={'Detaliile problemei'}
                            onChange={(newValue) => {
                                this.setState({
                                    debug: newValue,
                                });
                            }}/>
                        <SingleChoiceField
                            required
                            validated={inputValidationYesSelected(this.state.cancelValue)}
        
                            icon={'warn'}
                            label={'Esti sigur ca doresti semnalarea solicitarii?'}
                            options={[ { label: 'Da, doresc', value: 'yes' } ]}
                            value={this.state.cancelValue}
                            style={{ marginBottom: 4 }}
                            onSelect={(value) => {
                                this.setState({
                                    cancelValue: value,
                                });
                            }}/>
                    </Form>
                    
                </div>

            </BaseModal>
        );
    }
    
    onClose = () => {
        this.props.onClose();
    };

    onDone = () => {
        this.props.openLoading();

        markDebugTicket(this.props.ticketId, this.state.debug, () => {
            this.props.toast('Problema a fost semnalata cu succes.');
            this.props.closeLoading();
            this.props.onClose();
        });
    };

    componentWillReceiveProps (nextProps, nextContext) {
        if (!this.props.open && nextProps.open) {
            this.update();
        }
    }
    
    update = () => {
    
    };

    clear = () => {
        this.setState({
            debug: '',
        });
    };

}

const mapStateToProps = (state) => {
    return {
        user: state.application.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toast: (label, action) => { return dispatch(applicationAddSnack({ label, action })); },

        openLoading: () => { return dispatch(applicationOpenLoadingModal()); },
        closeLoading: () => { return dispatch(applicationCloseLoadingModal()); },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);