import React from 'react';

const Divider = (props) => {
    let style = {};
    if (props.style) {
        style = props.style;
    }
    return (
        <div style={{ width: '100%', height: 1, backgroundColor: '#ccc', ...style }}/>
    );
};

export default Divider;
