import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getBriefListSuppliers } from '../../../functional/api';
import PickerModal from '../model/PickerModal';
import { allMonths } from '../../../functional/min-support';

class Picker extends Component {

    static propTypes = {
        open: PropTypes.bool,
        value: PropTypes.any,

        onClose: PropTypes.func,
        onDone: PropTypes.func,

        setLabel: PropTypes.func,
    };

    state = {
        options: [],

        modalOpen: false,
    };

    componentDidMount () {
        this.update();
    }

    componentWillReceiveProps (nextProps, nextContext) {
        if (this.props.value != nextProps.value) {
            this.updateLabel(nextProps.value);
        }
    }

    update = () => {
        this.setState({
            options: allMonths(),
        }, () => {
            this.updateLabel(this.props.value);
        });
    };

    render () {
        return (
            <PickerModal
                open={this.props.open}
                options={this.state.options}
                initialSelectedValues={[ this.props.value ]}

                title={'Alege luna'}

                search={true}
                searchInDescription={false}

                minimumChoices={1}
                maximumChoices={1}

                onClose={this.props.onClose}
                onDone={this.props.onDone}>

            </PickerModal>
        );
    }

    updateLabel = (newValue) => {
        if (this.props.setLabel) {
            for (let i = 0; i < this.state.options.length; ++i) {
                if (this.state.options[ i ].value === newValue) {
                    this.props.setLabel(this.state.options[ i ].label);
                    return;
                }
            }
            this.props.setLabel(-1);
        }
    };

}

export default Picker;