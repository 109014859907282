import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { smallIcon } from '../../functional/min-support';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

class ImageFieldDisplay extends Component {
    
    static propTypes = {
        local: PropTypes.bool,
        value: PropTypes.any,
    };
    
    state = {
        imageViewModal: false,
    };

    render () {
        let source = '';
        
        let style = {};
        if (this.props.style) {
            style = this.props.style;
        }
        
        let imageStyle = {};
        if (this.props.imageStyle) {
            imageStyle = this.props.imageStyle;
        }
        
        let placeholderStyle = {
            borderRadius: 8,
            width: '100%',
            height: '100%',
            backgroundColor: '#cccccc',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        };
        
        placeholderStyle = { ...placeholderStyle, ...imageStyle };
        
        if (this.props.value) {
            if (this.props.local) {
                source = this.props.value;
            } else {
                source = this.props.value;
            }
        }
        
        let full = source;
        if (this.props.full) {
            full = this.props.full;
        }
        
        return (
            <div className={'image-field image-field-squish'} style={style}>
    
                {
                    this.state.imageViewModal &&
                    <Lightbox mainSrc={full}
                        enableZoom={true}
                        onCloseRequest={() => { return this.setState({ imageViewModal: false }); }}/>
                }
                
                {
                    source &&
                    <img src={source} style={imageStyle} className={'image-field-itself'}/>
                }
                
                {
                    source &&
                    <div className="image-overlay" style={imageStyle}>
                        <div className="btn btn-rounded btn-invisible-primary" onClick={this.openImageViewModal}>
                            {
                                smallIcon('ft-eye', '#544c4c', 14, { paddingRight: 4 })
                            }
                            {'Vezi imaginea'}
                        </div>
                    </div>
                }
                
                {
                    !source &&
                    <div style={placeholderStyle}>
                        {
                            smallIcon('ft-image', '#999999', 34)
                        }
                    </div>
                }
            </div>
        );
    }
    
    openImageViewModal = () => {
        this.setState({
            imageViewModal: true,
        });
    };
    
}

export default ImageFieldDisplay;