import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImageUploadModal from '../modal/model/ImageUploadModal';

class ImageFieldInput extends Component {
    
    static propTypes = {
        local: PropTypes.bool,
        value: PropTypes.any,
        
        onChange: PropTypes.func,
    };
    
    state = {
        imagePickerModal: false,
    };

    render () {
        let source = '';
         
        let style = {};
        if (this.props.style) {
            style = this.props.style;
        }
        if (this.props.value) {
            if (this.props.local) {
                source = this.props.value;
            } else {
                source = this.props.value;
            }
        }
        
        return (
            <div className={'image-field'} style={style}>
    
                {
                    source &&
                    <div className={'image-field-container'}>
                        <img src={source} className={'image-field-itself'} style={{ cursor: 'pointer', paddingLeft: 10 }} onClick={this.openImagePickerModal}/>
                    </div>
                }
                
                {
                    !source &&
                    <div className={'image-field-container'}>
                        <div className={'image-field-placeholder'} style={{ cursor: 'pointer', paddingLeft: 10 }} onClick={this.openImagePickerModal}>
                            {'Nicio imagine adaugata'}
                        </div>
                    </div>
                }
                
                <ImageUploadModal
                    open={this.state.imagePickerModal}
                    title={'Alege imaginea'}
                    onClose={() => {
                        this.setState({
                            imagePickerModal: false,
                        });
                    }}
                    onDone={(image, imageURL) => {
                        this.setState({
                            imagePickerModal: false,
                        });
                        this.onChange(image, imageURL);
                    }}/>
            </div>
        );
    }
    
    openImagePickerModal = () => {
        this.setState({
            imagePickerModal: true,
        });
    };
    
    onChange = (image, imageURL) => {
        this.props.onChange(image, imageURL);
    };
    
}

export default ImageFieldInput;