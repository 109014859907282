export const kLSSessionToken = 'customer-management-session-token';
export const kLSUserUid = 'customer-management-uid';
export const kLSApplication = 'customer-management-application';

export const kLSUserUsername = 'customer-management-username-login';

export const getLoginUsername = () => {
    return localStorage.getItem(kLSUserUsername);
};

export const saveLoginUsername = (value) => {
    localStorage.setItem(kLSUserUsername, value);
};

export const deleteLoginUsername = () => {
    localStorage.removeItem(kLSUserUsername);
};

export const getCredentials = () => {
    const userUid = localStorage.getItem(kLSUserUid);
    const token = localStorage.getItem(kLSSessionToken);
    const applicationId = localStorage.getItem(kLSApplication);
    return { username: userUid, token, applicationId };
};

export const saveCredentials = (userUid, token) => {
    localStorage.setItem(kLSUserUid, userUid);
    localStorage.setItem(kLSSessionToken, token);
};

export const deleteCredentials = () => {
    localStorage.removeItem(kLSUserUid);
    localStorage.removeItem(kLSSessionToken);
};

export const getApplicationId = () => {
    return localStorage.getItem(kLSApplication);
};

export const saveApplicationId = (applicationId) => {
    localStorage.setItem(kLSApplication, applicationId);
};

export const savePageData = (page, data) => {
    try {
        localStorage.setItem(page, JSON.stringify(data));
    } catch (e) {
        console.error('Error saving page data', e);
    }
};

export const getPageData = (page) => {
    return JSON.parse(localStorage.getItem(page));
};

export const hasPageData = (page) => {
    return localStorage.getItem(page) !== null;
};

export const deletePageData = (page) => {
    localStorage.removeItem(page);
};