/**
 * @label Stable Imports
 */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

/**
 * @label Functionality Imports
 */

import {
    applicationRemoveSnack,
    applicationSnackMonitorSelector,
    applicationSnackSelector,
} from '../../store/application-store';

/**
 * @label Other Exports
 */

export const kToastActionNormal = 1;
export const kToastActionError = 2;
export const kToastActionWarn = 3;

/**
 * Manages bottom Toast Notifications
 * @returns {JSX.Element}
 */

const Toast = (props) => {

    /**
     * @label Hooks : Start
     */

    const dispatch = useDispatch();

    /**
     * @label Hooks : End
     */


    /**
     * @label State : Start
     */

    const [ currentSnack, setCurrentSnack ] = useState(null);

    /**
     * @label State : End
     */


    /**
     * @label Store : Start
     */

    const snack = useSelector(applicationSnackSelector);
    const snackMonitor = useSelector(applicationSnackMonitorSelector);

    /**
     * @label Store : End
     */


    /**
     * @label Functionality : Start
     */

    const getClassName = (action) => {
        switch (action) {
        case kToastActionNormal:
            return 'snack-normal';
        case kToastActionError:
            return 'snack-error';
        case kToastActionWarn:
            return 'snack-warn';
        }
        return 'snack-normal';
    };

    const checkForSnack = () => {
        if (snack.length > 0) {
            if (!currentSnack) {
                setCurrentSnack(snack[ 0 ]);
                dispatch(applicationRemoveSnack());
                setTimeout(() => {
                    setCurrentSnack(null);
                }, 2000);
            }
        } else {
            setTimeout(() => {
                checkForSnack(snack);
            }, 3000);
        }
    };

    /**
     * @label Functionality : End
     */


    /**
     * @label Effects : Start
     */

    useEffect(() => {
        checkForSnack();
    }, [ snack, snackMonitor ]);

    /**
     * @label Effects : End
     */


    /**
     * @label Render
     */

    let className = 'snack-normal';
    let color = '#333333';
    if (currentSnack && currentSnack.hasOwnProperty('action')) {
        className = getClassName(currentSnack.action);
    }

    if (className === 'snack-normal') {
        color = '#105324';
    } else if (className === 'snack-error') {
        color = '#7e0e12';
    } else if (className === 'snack-warn') {
        color = '#7b7a08';
    }

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            open={ Boolean(currentSnack) }>

            <SnackbarContent className={className} style={{ backgroundColor: color, color: '#ffffff', fontFamily: 'Roboto', fontSize: 14 }} message={currentSnack ? currentSnack.label : '...'}/>

        </Snackbar>
    );
};

export default Toast;