import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InternalToolbar from '../../components/general/InternalToolbar';
import { filter, range } from '../../functional/min-support';
import PickerModal from '../../components/modal/model/PickerModal';

class ListPage extends Component {

    static baseIncrement = 20;

    static loadOffset = 100;

    static propTypes = {
        onRefresh: PropTypes.func,
        loading: PropTypes.bool,
        deepSearch: PropTypes.bool,

        grid: PropTypes.bool,

        actionText: PropTypes.string,
        onAction: PropTypes.func,
        
        allListItems: PropTypes.array,
        renderListItem: PropTypes.func,
        renderEmpty: PropTypes.func,

        allFields: PropTypes.array,
        defaultFields: PropTypes.array,

        children: PropTypes.array,
    };
    
    state = {
        search: '',
        fields: [],
        filterModal: false,

        maxElement: ListPage.baseIncrement,
        
        toolbarShadow: false,
    };

    lastSize = 0;

    matchFields = (fields) => {
        const matchedFields = [];
        for (let i = 0; i < this.props.allFields.length; ++i) {
            if (fields.includes(this.props.allFields[ i ].value)) {
                matchedFields.push(this.props.allFields[ i ]);
            }
        }
        return matchedFields.map((item) => { return item.label; });
    };

    componentDidMount () {
        this.setState({
            search: '',
            maxElement: ListPage.baseIncrement,
            fields: this.props.defaultFields,
        });
        this.lastSize = 0;
    }

    searchChange = (newValue) => {
        this.setState({
            search: newValue,
            maxElement: ListPage.baseIncrement,
        });
        this.lastSize = 0;
    };

    handleScroll = (e) => {

        /*
         *if(e.target.scrollTop > 100 && !this.state.toolbarShadow) {
         *  this.setState({toolbarShadow: true});
         *} else if(e.target.scrollTop <= 100 && this.state.toolbarShadow){
         *  this.setState({toolbarShadow: false});
         *}
         */

        const bottom = e.target.scrollHeight - e.target.scrollTop < ListPage.loadOffset + e.target.clientHeight;
        if (bottom && this.state.maxElement > this.lastSize) {
            this.lastSize = this.state.maxElement;
            this.addToList();
        }
    };

    addToList = () => {
        this.setState({
            maxElement: this.state.maxElement + ListPage.baseIncrement,
        });
    };

    render () {
        const overallStyle = {};
        
        const style = {};
        if (this.props.grid) {
            style.display = 'flex';
            style.flexDirection = 'row';
            style.flexWrap = 'wrap';
            style.justifyContent = 'space-around';
        }
        
        if (this.props.paddingOverall) {
            style.padding = 20;
        }
        
        let list = [];
        if (!this.props.loading) {
            const elements = filter(this.props.allListItems, this.state.fields, this.state.search, this.props.deepSearch).slice(0, this.state.maxElement);
            if (this.props.grid) {
                list = elements.map((element, index) => {
                    const dark = (index % 2 === 1 && index % 8 < 4) || (index % 2 === 0 && index % 8 >= 4);
                    return this.props.renderListItem(element, dark);
                });
            } else {
                list = elements.map(this.props.renderListItem);
            }
        }
        
        let emptyElementsNb = 0;
        if (this.props.grid) {
            emptyElementsNb = (4 - (list.length % 4)) % 4;
            style.paddingRight = 20;
            style.paddingTop = 20;
        }
        
        const toolbarActions = [
            {
                title: 'Filtre',
                onPress: this.openFilterModal,
                icon: { title: 'ft-filter' },
            },
        ];
        if (this.props.actions) {
            toolbarActions.push(...this.props.actions);
        }
        
        const toolbarLabels = [
            {
                connection: 'dupa:',
                tags: this.matchFields(this.state.fields),
            },
        ];
        if (this.props.labels) {
            toolbarLabels.push(...this.props.labels);
        }

        return (
            <React.Fragment>

                <div className={'list-page'} style={overallStyle}>

                    <InternalToolbar
                        refresh
                        onRefresh={this.props.onRefresh}
                        search
                        onSearchChange={this.searchChange}
                        searchValue={this.state.search}
                        actions={toolbarActions}
                        labels={toolbarLabels}/>
                        
                    {
                        false && this.props.renderHeaderElement && this.props.headerElements && this.props.headerElements.length > 0 &&
                            <div className={'header-elements'}>
                                {
                                    this.props.headerElements.map((item) => {
                                        return this.props.renderHeaderElement(item);
                                    })
                                }
                            </div>
                    }
                    
                    {
                        this.props.loading &&
                        <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                            <div className="spinner_loader secondary_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        </div>
                    }
                    
                    {
                        !this.props.loading &&
                        <div className={'list-container'} style={style} onScroll={this.handleScroll}>
    
                            {
                                list
                            }
    
                            {
                                this.props.grid && range(0, emptyElementsNb).map(this.renderEmpty)
                            }

                        </div>
                    }

                </div>

                <PickerModal
                    title={'Alege campurile cautarii'}
                    open={this.state.filterModal}
                    options={this.props.allFields}
                    initialSelectedValues={this.state.fields}
                    minimumChoices={1}
                    maximumChoices={100}
                    onClose={this.closeFilterModal}
                    onDone={this.doneFilterModal}
                    returnMatchedFields/>

                {
                    this.props.children
                }

            </React.Fragment>
        );
    }
    
    renderEmpty = () => {
        return <div className={'grid-list-item-empty'}/>;
    };

    openFilterModal = () => {
        this.setState({
            filterModal: true,
        });
    };

    closeFilterModal = () => {
        this.setState({
            filterModal: false,
        });
    };

    doneFilterModal = (newFilters, matchedFields) => {
        this.setState({
            fields: newFilters,
        });
        this.closeFilterModal();
    };
}

export default ListPage;