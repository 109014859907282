import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


import { addProductEvent } from '../../functional/api';
import BaseModal from './model/BaseModal';
import TextFieldInput from '../input/TextFieldInput';
import { kToastActionError } from '../general/Toast';
import {
    inputValidationInteger,
    inputValidationNonEmpty,
    inputValidationSingleChoice,
} from '../../functional/min-support';
import Form from '../input/Form';
import SingleChoiceField from '../input/SingleChoiceField';
import {
    applicationAddSnack,
    applicationCloseLoadingModal,
    applicationOpenLoadingModal,
} from '../../store/application-store';

const kTypesOfTransfer = [
    {
        value: 'stock-history-type-correction',
        label: 'Corectie stoc',
        description: 'Se efectueaza o corectie de stoc.',
    },
];

class Modal extends Component {
    
    static propTypes = {
        user: PropTypes.object,
        parentKey: PropTypes.any,

        open: PropTypes.bool,
        
        onClose: PropTypes.func,

        toast: PropTypes.func,
        openLoading: PropTypes.func,
        closeLoading: PropTypes.func,
    };
    
    state = {
        quantity: '',
        description: '',

        formComplete: false,
        
        shouldChooseHosting: false,
        hostingType: 'stock-history-type-correction',
    };

    componentDidMount () {
        this.setState({
            destinationId: 0,
            sourceId: 0,
        });
    }

    render () {

        return (
            <BaseModal
                title={'Adauga actiune produs'}
                open={this.props.open}
                doneActive={this.state.formComplete}
                onClose={this.onClose}
                onDone={this.onDone}>

                <div className={'w-100'}>

                    <Form complete={this.state.formComplete} setComplete={(complete) => { this.setState({ formComplete: complete }); }}>
    
                        <SingleChoiceField
                            required
                            validated={inputValidationSingleChoice(this.state.hostingType)}
        
                            icon={'ft-code'}
                            label={'Tipul actiunii'}
                            options={kTypesOfTransfer}
                            value={this.state.hostingType}
                            style={{ marginBottom: 4 }}
                            onSelect={(value) => {
                                this.setState({
                                    hostingType: value,
                                });
                            }}/>

                        <TextFieldInput
                            required
                            validated={inputValidationInteger(this.state.quantity)}

                            icon={'ft-layers'}
                            value={this.state.quantity}
                            style={{ marginBottom: 4 }}
                            label={'Cantitatea (+ / -)'}
                            onChange={(newValue) => {
                                this.setState({
                                    quantity: newValue,
                                });
                            }}/>

                        <TextFieldInput
                            required
                            validated={inputValidationNonEmpty(this.state.description)}

                            icon={'ft-info'}
                            value={this.state.description}
                            style={{ marginBottom: 4 }}
                            label={'Descrierea intrarii / iesirii'}
                            onChange={(newValue) => {
                                this.setState({
                                    description: newValue,
                                });
                            }}/>

                    </Form>

                </div>

            </BaseModal>
        );
    }

    onClose = () => {
        this.props.onClose();
    };

    onDone = () => {
        if (!this.props.parentKey) {
            return;
        }

        const request = {
            variation: this.state.quantity,
            description: this.state.description,
            productId: this.props.parentKey,
            type: this.state.hostingType,
        };

        this.props.openLoading();

        addProductEvent(request, (successful) => {
            if (successful) {
                this.clear();
                this.props.toast('Actiune produs inregistrata cu succes.');
            } else {
                this.props.toast('Operatiune esuata!', kToastActionError);
            }
            this.props.closeLoading();
            this.onClose();
        });
    };

    componentWillReceiveProps (nextProps, nextContext) {
        if (!this.props.open && nextProps.open) {
            this.update();
        }
    }
    
    update = () => {

    };

    clear = () => {
        this.setState({
            quantity: '',
            description: '',
        });
    };

}

const mapDispatchToProps = (dispatch) => {
    return {
        toast: (label, action) => { return dispatch(applicationAddSnack({ label, action })); },

        openLoading: () => { return dispatch(applicationOpenLoadingModal()); },
        closeLoading: () => { return dispatch(applicationCloseLoadingModal()); },
    };
};

export default connect(null, mapDispatchToProps)(Modal);