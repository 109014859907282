import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    addNote,
    changeFileName,
    editNote,
    getClient,
    saveFile,
    setClientVerified,
    uploadResource,
} from '../functional/api';
import InternalToolbar from '../components/general/InternalToolbar';
import TextFieldDisplay from '../components/input/TextFieldDisplay';
import { filter, prettyDateAndTime } from '../functional/min-support';
import { toTicketPage } from '../functional/navigation';
import AddHeadquartersModal from '../components/modal/AddHeadquartersModal';
import { colorByStatus } from '../functional/status';
import { applicationAddSnack, applicationOpenUpdateModal, applicationSetItemPageTitle } from '../store/application-store';
import SimpleButton from '../components/input/SimpleButton';
import TextFieldInput from '../components/input/TextFieldInput';
import { kToastActionError } from '../components/general/Toast';

class ClientPage extends Component {

    static propTypes = {
        history: PropTypes.object,
    };

    static defaultState = {
        item: null,
        search: '',
        modalOpen: false,

        notes: [],
        noteInEdit: null,
        newNote: null,
    };

    state = ClientPage.defaultState;

    componentWillReceiveProps (nextProps, nextContext) {
        if (this.props.updateModal && !nextProps.updateModal) {
            this.update();
        }
    }

    componentDidMount () {
        this.props.onSetItemPageTitle();
        this.update();
    }

    generalUpdate = () => {
        this.setState(ClientPage.defaultState, this.update);
    }

    update = () => {
        const key = this.props.match.params.key;
        getClient(key, (successful, data) => {
            console.log('client', data);
            if (successful) {
                this.setState({
                    item: data,
                });
                if (data.notes) {
                    this.setState({
                        notes: data.notes,
                    });
                }
                this.props.onSetItemPageTitle(data.title);
            }
        });
    };

    onDocumentChange = (document, documentURL) => {
        this.setState({
            document,
            documentURL,
        });
        uploadResource(document, (successful, data) => {
            if (!successful) {
                return;
            }
            const url = data.versions[ 0 ].extended;
            saveFile('Demo file', this.props.match.params.key, url, 'contracts', (fileSaved, info) => {
                console.log('file info', fileSaved, info);
            });
        });
    }

    onChangeDocument = (event, type) => {
        const document = event.target.files[ 0 ];
        const documentURL = URL.createObjectURL(event.target.files[ 0 ]);
        uploadResource(document, (successful, data) => {
            if (!successful) {
                return;
            }
            const url = data.versions[ 0 ].extended;
            saveFile(document.name, this.props.match.params.key, url, type, (fileSaved, info) => {
                console.log('file info', fileSaved, info);
                this.update();
            });
        });
    }

    setNoteInEditBody = (body) => {
        if (this.state.noteInEdit === 'new') {
            this.setState({
                newNote: { info: { body } },
            });
        } else if (this.state.noteInEdit !== null) {
            const notesLocal = this.state.notes;
            for (let i = 0; i < notesLocal.length; ++i) {
                if (notesLocal[ i ]._id === this.state.noteInEdit) {
                    notesLocal[ i ].info.body = body;
                }
            }
            this.setState({ notes: notesLocal });
        }
    }

    saveCurrentNewNote = (immediateCallback) => {
        if (this.state.noteInEdit !== 'new' || !this.state.newNote) {
            return;
        }
        const info = this.state.newNote.info;
        const notes = this.state.notes;
        if (info.body.length <= 0) {
            return;
        }
        addNote(this.props.match.params.key, 'text', info, () => {

        });
        notes.unshift({ _id: this.localEditNoteNb++, type: 'text', info });
        this.setState({ newNote: null, noteInEdit: null, notes }, immediateCallback ? immediateCallback : () => {});
    }

    onDocumentChangeSuccess = () => {
        this.props.toast('Schimbarea documentului a fost efectuata cu succes.');
        this.update();
    }

    onDocumentChangeFail = () => {
        this.props.toast('Schimbarea documentului a esuat.', kToastActionError);
        this.update();
    }

    saveCurrentEditNote = (immediateCallback) => {
        if (this.state.noteInEdit === 'new' || this.state.noteInEdit === null || this.state.newNote) {
            return;
        }
        const note = this.state.notes.find((n) => { return n._id === this.state.noteInEdit; });
        if (!note) {
            return;
        }
        console.log('note.info', note.info);
        editNote(this.state.noteInEdit, note.info, () => {

        });
        this.setState({ newNote: null, noteInEdit: null }, immediateCallback ? immediateCallback : () => {});
    }

    startNewNote = () => {
        this.setState({ newNote: { info: { body: '' } }, noteInEdit: 'new' });
    }

    render () {
        let emailAddresses = this.state.item ? this.state.item.emailAddresses : null;

        const documents = this.state.item ? this.state.item.files : null;
        const contracts = documents ? documents.filter((entry) => { return entry.path === 'contracts'; }) : [];
        const invoices = documents ? documents.filter((entry) => { return entry.path === 'invoices'; }) : [];
        const invoicesFiscal = documents ? documents.filter((entry) => { return entry.path === 'invoices-fiscal'; }) : [];
        const invoicesNotFiscal = documents ? documents.filter((entry) => { return entry.path === 'invoices-not-fiscal'; }) : [];
        const offers = documents ? documents.filter((entry) => { return entry.path === 'offers'; }) : [];
        const otherFiles = documents ? documents.filter((entry) => { return entry.path === 'others'; }) : [];

        let tickets = this.state.item ? this.state.item.tickets : null;
        const toolbarActions = [
            {
                title: 'Adauga adresa de email',
                onPress: () => { this.setState({ modalOpen: true }); },
                icon: { title: 'ft-plus' },
            },
        ];
        const toolbarLabels = [
            {
                connection: 'adresele de email dupa:',
                tags: [ 'Numele si functia persoanei de contact', 'Adresa de email' ],
            },
            {
                connection: 'solicitarile dupa:',
                tags: [ 'Numarul solicitarii', 'Statusul solicitarii' ],
            },
        ];
        if (emailAddresses && this.state.search.length > 0) {
            emailAddresses = filter(emailAddresses, [ 'title', 'address', 'personName', 'personRank' ], this.state.search, false);
        }

        if (tickets && this.state.search.length > 0) {
            tickets = filter(tickets, [ 'title', 'status' ], this.state.search, false);
        }

        return (
            <React.Fragment>
                <InternalToolbar
                    backButton
                    refresh
                    onRefresh={this.generalUpdate}
                    search
                    searchValue={this.state.search}
                    onSearchChange={(newValue) => { this.setState({ search: newValue }); }}
                    actions={toolbarActions}
                    labels={toolbarLabels}/>
                <div className="w-100 h-100 overflow-hidden d-flex flex-row">

                    <div className="h-100 overflow-scroll" style={{ flex: 1, padding: 20 }}>
                        {
                            this.state.item &&
                            <React.Fragment>
                                <div className={'general-card'}>

                                    <TextFieldDisplay
                                        title
                                        value={this.state.item.title}
                                        style={{ marginBottom: 4 }}
                                        action={'ft-edit'}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item._id,
                                                this.state.item.title,
                                                'client',
                                                'title'
                                            );
                                        }}/>

                                    <TextFieldDisplay
                                        label={'cod identificare fiscala'}
                                        value={this.state.item.cid}
                                        icon={'ft-info'}
                                        action={'ft-edit'}
                                        style={{ marginBottom: 4 }}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item._id,
                                                this.state.item.cid,
                                                'client',
                                                'cid'
                                            );
                                        }}/>

                                    <TextFieldDisplay
                                        label={'nr. inregistrare la registrul comertului'}
                                        value={this.state.item.tri}
                                        icon={'blank'}
                                        action={'ft-edit'}
                                        style={{ marginBottom: 4 }}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item._id,
                                                this.state.item.tri,
                                                'client',
                                                'tri'
                                            );
                                        }}/>

                                    <TextFieldDisplay
                                        label={'banca'}
                                        value={this.state.item.bankTitle}
                                        icon={'ft-credit-card'}
                                        action={'ft-edit'}
                                        style={{ marginBottom: 4 }}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item._id,
                                                this.state.item.bankTitle,
                                                'client',
                                                'bankTitle'
                                            );
                                        }}/>

                                    <TextFieldDisplay
                                        label={'IBAN cont bancar'}
                                        value={this.state.item.bankAccount}
                                        icon={'blank'}
                                        action={'ft-edit'}
                                        style={{ marginBottom: 4 }}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item._id,
                                                this.state.item.bankAccount,
                                                'client',
                                                'bankAccount'
                                            );
                                        }}/>

                                    <TextFieldDisplay
                                        label={'adresa sediului social'}
                                        value={this.state.item.address}
                                        icon={'ft-map-pin'}
                                        action={'ft-edit'}
                                        style={{ marginBottom: 4 }}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item._id,
                                                this.state.item.address,
                                                'client',
                                                'address'
                                            );
                                        }}/>

                                    <TextFieldDisplay
                                        label={'nr. telefon contact'}
                                        value={this.state.item.contactPhone}
                                        icon={'ft-phone'}
                                        action={'ft-edit'}
                                        style={{ marginBottom: 4 }}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item._id,
                                                this.state.item.contactPhone,
                                                'client',
                                                'contactPhone'
                                            );
                                        }}/>

                                    {
                                        this.state.item && this.state.item.clientGroup &&
                                        <TextFieldDisplay
                                            label={'Apartine grupului de clienti'}
                                            value={this.state.item.clientGroup.title}
                                            icon={'ft-folder'}
                                            style={{ marginBottom: 4 }}/>
                                    }

                                    {
                                        this.state.item && !this.state.item.verified &&
                                        <TextFieldDisplay
                                            label={'Acest client nu este inca verificat'}
                                            value={'Marcheaza ca verificat'}
                                            icon={'ft-check-circle'}
                                            action={'ft-edit'}
                                            onPress={() => {
                                                setClientVerified(this.props.match.params.key, (successful, result) => {
                                                    console.log('succ res', successful, result);
                                                    this.update();
                                                });
                                            }}
                                            style={{ marginBottom: 4 }}/>
                                    }

                                </div>


                                <h4 style={{ marginTop: 20, marginBottom: 20 }}>
                                    Contracte
                                </h4>

                                {
                                    contracts.length > 0 &&
                                    contracts.map((item) => {
                                        return (
                                            <DocumentListItem key={item.id} item={item} onChangeSuccess={this.onDocumentChangeSuccess} onChangeFail={this.onDocumentChangeFail}/>
                                        );
                                    })
                                }

                                {
                                    contracts.length <= 0 &&
                                    <h5 style={{ marginTop: 0 }}>
                                        Niciun contract
                                    </h5>
                                }

                                <input ref={(ref) => { return this.contractElement = ref; }} type={'file'} id={'file-upload-element'} onChange={(e) => { this.onChangeDocument(e, 'contracts'); }} style={{ width: 0, height: 0, display: 'none' }} accept="*"/>
                                <TextFieldDisplay
                                    label={'Adauga un contract'}
                                    value={'Alege'}
                                    icon={'ft-plus-circle'}
                                    action={'edit'}
                                    onPress={() => {
                                        if (this.contractElement) {
                                            this.contractElement.click();
                                        }
                                    }}
                                    style={{ marginTop: 20 }}/>


                                <h4 style={{ marginTop: 20, marginBottom: 20 }}>
                                    Oferte
                                </h4>

                                {
                                    offers.length > 0 &&
                                    offers.map((item) => {
                                        return (
                                            <DocumentListItem key={item.id} item={item} onChangeSuccess={this.onDocumentChangeSuccess} onChangeFail={this.onDocumentChangeFail}/>
                                        );
                                    })
                                }

                                {
                                    offers.length <= 0 &&
                                    <h5 style={{ marginTop: 0 }}>
                                        Nicio oferta
                                    </h5>
                                }

                                <input ref={(ref) => { return this.offerElement = ref; }} type={'file'} id={'file-upload-element'} onChange={(e) => { this.onChangeDocument(e, 'offers'); }} style={{ width: 0, height: 0, display: 'none' }} accept="*"/>
                                <TextFieldDisplay
                                    label={'Adauga o oferta'}
                                    value={'Alege'}
                                    icon={'ft-plus-circle'}
                                    action={'edit'}
                                    onPress={() => {
                                        if (this.offerElement) {
                                            this.offerElement.click();
                                        }
                                    }}
                                    style={{ marginTop: 20 }}/>

                                <h4 style={{ marginTop: 20, marginBottom: 20 }}>
                                    Facturi proforme
                                </h4>

                                {
                                    invoicesNotFiscal.length > 0 &&
                                    invoicesNotFiscal.map((item) => {
                                        return (
                                            <DocumentListItem key={item.id} item={item} onChangeSuccess={this.onDocumentChangeSuccess} onChangeFail={this.onDocumentChangeFail}/>
                                        );
                                    })
                                }

                                {
                                    invoicesNotFiscal.length <= 0 &&
                                    <h5 style={{ marginTop: 0 }}>
                                        Nicio factura proforma
                                    </h5>
                                }

                                <input ref={(ref) => { return this.invoiceElement = ref; }} type={'file'} id={'file-upload-element'} onChange={(e) => { this.onChangeDocument(e, 'invoices-not-fiscal'); }} style={{ width: 0, height: 0, display: 'none' }} accept="*"/>
                                <TextFieldDisplay
                                    label={'Adauga o factura proforma'}
                                    value={'Alege'}
                                    icon={'ft-plus-circle'}
                                    action={'edit'}
                                    onPress={() => {
                                        if (this.invoiceElement) {
                                            this.invoiceElement.click();
                                        }
                                    }}
                                    style={{ marginTop: 20 }}/>

                                <h4 style={{ marginTop: 20, marginBottom: 20 }}>
                                    Facturi fiscale
                                </h4>

                                {
                                    invoicesFiscal.length > 0 &&
                                    invoicesFiscal.map((item) => {
                                        return (
                                            <DocumentListItem key={item.id} item={item} onChangeSuccess={this.onDocumentChangeSuccess} onChangeFail={this.onDocumentChangeFail}/>
                                        );
                                    })
                                }

                                {
                                    invoicesFiscal.length <= 0 &&
                                    <h5 style={{ marginTop: 0 }}>
                                        Nicio factura fiscala
                                    </h5>
                                }

                                <input ref={(ref) => { return this.invoiceElement = ref; }} type={'file'} id={'file-upload-element'} onChange={(e) => { this.onChangeDocument(e, 'invoices-fiscal'); }} style={{ width: 0, height: 0, display: 'none' }} accept="*"/>
                                <TextFieldDisplay
                                    label={'Adauga o factura fiscala'}
                                    value={'Alege'}
                                    icon={'ft-plus-circle'}
                                    action={'edit'}
                                    onPress={() => {
                                        if (this.invoiceElement) {
                                            this.invoiceElement.click();
                                        }
                                    }}
                                    style={{ marginTop: 20 }}/>


                                <h4 style={{ marginTop: 20, marginBottom: 20 }}>
                                    Alte documente
                                </h4>

                                {
                                    otherFiles.length > 0 &&
                                    otherFiles.map((item) => {
                                        return (
                                            <DocumentListItem key={item.id} item={item} onChangeSuccess={this.onDocumentChangeSuccess} onChangeFail={this.onDocumentChangeFail}/>
                                        );
                                    })
                                }

                                {
                                    invoices.length <= 0 &&
                                    <h5 style={{ marginTop: 0 }}>
                                        Niciun alt document
                                    </h5>
                                }

                                <input ref={(ref) => { return this.otherFilesElement = ref; }} type={'file'} id={'file-upload-element'} onChange={(e) => { this.onChangeDocument(e, 'others'); }} style={{ width: 0, height: 0, display: 'none' }} accept="*"/>
                                <TextFieldDisplay
                                    label={'Adauga un alt document'}
                                    value={'Alege'}
                                    icon={'ft-plus-circle'}
                                    action={'edit'}
                                    onPress={() => {
                                        if (this.otherFilesElement) {
                                            this.otherFilesElement.click();
                                        }
                                    }}
                                    style={{ marginTop: 20 }}/>


                                {
                                    emailAddresses && emailAddresses.length > 0 &&
                                        <h4 style={{ marginTop: 20 }}>
                                            Adrese de email asociate
                                        </h4>
                                }

                                {
                                    emailAddresses && emailAddresses.length > 0 &&
                                    emailAddresses.map((item) => {
                                        return (
                                            <div key={item.id} className="d-flex flex-column" style={{ marginBottom: 20 }}>
                                                <div className={'general-card'} style={{ width: '100%' }}>

                                                    <TextFieldDisplay
                                                        icon={'ft-info'}
                                                        label={'Nume persoana de contact'}
                                                        value={item.personName ? item.personName : item.title}
                                                        style={{ marginBottom: 4 }}/>

                                                    <TextFieldDisplay
                                                        icon={'ft-info'}
                                                        label={'Functie persona de contact'}
                                                        value={item.rank ? item.rank : ''}
                                                        style={{ marginBottom: 4 }}/>

                                                    <TextFieldDisplay
                                                        icon={'ft-at-sign'}
                                                        value={item.address}/>
                                                </div>

                                            </div>
                                        );
                                    })
                                }

                                {
                                    emailAddresses && emailAddresses.length <= 0 &&
                                    <h4 style={{ marginTop: 20 }}>
                                        Nicio adresa de email asociata
                                    </h4>
                                }

                            </React.Fragment>
                        }

                        {
                            !this.state.item &&
                            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                                <div className="spinner_loader secondary_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            </div>
                        }

                    </div>

                    <div className="h-100 overflow-scroll" style={{ width: '40%', padding: 20, paddingLeft: 0 }}>

                        {
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <h4>Notite</h4>
                                        <SimpleButton value={'Adauga'} onPress={() => {
                                            if (this.state.noteInEdit === 'new') {
                                                this.saveCurrentNewNote(this.startNewNote);
                                            } else if (this.state.noteInEdit !== null) {
                                                this.saveCurrentEditNote(this.startNewNote);
                                            } else {
                                                this.startNewNote();
                                            }
                                        }} icon={'ft-plus'}/>
                                    </div>
                                    {
                                        this.state.notes.length <= 0 && !this.state.newNote &&
                                        <h5>Nicio notita</h5>
                                    }
                                </div>
                                {
                                    this.state.noteInEdit === 'new' && this.state.newNote !== null &&
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <TextFieldInput
                                            classname={'mb-1'}
                                            value={this.state.newNote.info.body}
                                            icon={'ft-edit'}
                                            iconColor={'#934A5F'}
                                            placeholder={'Introduceti notitele'}
                                            onKeyDown={(e) => {
                                                console.log('keydown');
                                                if (e.keyCode === 13) {
                                                    this.saveCurrentNewNote();
                                                    return true;
                                                }
                                            }}
                                            onChange={(newValue) => { this.setNoteInEditBody(newValue); }}/>
                                        <SimpleButton value={''} onPress={() => {
                                            this.saveCurrentNewNote();
                                        }} icon={'ft-check'}/>
                                    </div>
                                }
                                {
                                    this.state.notes.filter((note) => { return !note.ticketID; }).map((note) => {
                                        if (note._id === this.state.noteInEdit) {
                                            return (
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                    <TextFieldInput
                                                        style={{ flex: 1 }}
                                                        classname={'mb-1 flex-1'}
                                                        value={note.info.body}
                                                        icon={'ft-edit'}
                                                        iconColor={'#934A5F'}
                                                        placeholder={'Introduceti notitele'}
                                                        onKeyDown={(e) => {
                                                            console.log('keydown');
                                                            if (e.keyCode === 13) {
                                                                this.saveCurrentEditNote();
                                                                return true;
                                                            }
                                                        }}
                                                        onChange={(newValue) => { this.setNoteInEditBody(newValue); }}/>

                                                    <SimpleButton value={''} onPress={() => {
                                                        this.saveCurrentEditNote();
                                                    }} icon={'ft-check'}/>
                                                </div>

                                            );
                                        } else {
                                            return (
                                                <TextFieldDisplay
                                                    classname={'mb-1'}
                                                    value={note.info.body}
                                                    onPress={() => {
                                                        if (this.state.noteInEdit === 'new') {
                                                            this.saveCurrentNewNote(() => {
                                                                this.setState({ noteInEdit: note._id });
                                                            });
                                                        } else if (this.state.noteInEdit !== null && this.state.noteInEdit !== note._id) {
                                                            this.saveCurrentEditNote(() => {
                                                                this.setState({ noteInEdit: note._id });
                                                            });
                                                        } else {
                                                            this.setState({ noteInEdit: note._id });
                                                        }
                                                    }}
                                                    icon={'ft-edit'}/>
                                            );
                                        }
                                    })
                                }
                            </div>
                        }

                        {
                            this.state.item && tickets &&
                            <React.Fragment>
                                {
                                    tickets.length > 0 && tickets.map((item) => {
                                        // console.log('solicitare', item);
                                        const notes = this.state.notes.filter((note) => { return note.ticketID === item._id; });
                                        return (
                                            <div key={item.id} className="general-card card-small-padding" style={{ marginBottom: 20 }}>

                                                <div className="d-flex flex-row justify-content-between">
                                                    <div style={{ flex: 1, paddingRight: 20 }}>
                                                        <TextFieldDisplay
                                                            style={{ marginBottom: 4 }}
                                                            value={item.title}
                                                            icon={'ft-check-square'}/>

                                                        <TextFieldDisplay
                                                            textBackgroundColor={colorByStatus(item.status)}
                                                            textColor={'#ffffff'}
                                                            value={item.status}
                                                            icon={'ft-code'}
                                                            style={{ marginBottom: 4 }}/>

                                                        <TextFieldDisplay
                                                            value={prettyDateAndTime(item.created)}
                                                            icon={'ft-clock'}
                                                            style={{ marginBottom: 4 }}/>

                                                        <TextFieldDisplay
                                                            value={item.description}
                                                            icon={'ft-file-text'}
                                                            style={{ marginBottom: 4 }}/>

                                                        <TextFieldDisplay
                                                            icon={'blank'}
                                                            action={'ft-eye'}
                                                            onPress={() => { toTicketPage(item._id); }}
                                                            value={'Vezi solicitarea'}/>

                                                        {
                                                            notes.map((note) => {
                                                                if (note._id === this.state.noteInEdit) {
                                                                    return (
                                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                            <TextFieldInput
                                                                                style={{ flex: 1 }}
                                                                                classname={'mb-1 flex-1'}
                                                                                value={note.info.body}
                                                                                icon={'ft-edit'}
                                                                                iconColor={'#934A5F'}
                                                                                placeholder={'Introduceti notitele'}
                                                                                onChange={(newValue) => { this.setNoteInEditBody(newValue); }}/>

                                                                            <SimpleButton value={''} onPress={() => {
                                                                                this.saveCurrentEditNote();
                                                                            }} icon={'ft-check'}/>
                                                                        </div>

                                                                    );
                                                                } else {
                                                                    return (
                                                                        <TextFieldDisplay
                                                                            classname={'mb-1'}
                                                                            value={note.info.body}
                                                                            onPress={() => {
                                                                                if (this.state.noteInEdit === 'new') {
                                                                                    this.saveCurrentNewNote(() => {
                                                                                        this.setState({ noteInEdit: note._id });
                                                                                    });
                                                                                } else if (this.state.noteInEdit !== null && this.state.noteInEdit !== note._id) {
                                                                                    this.saveCurrentEditNote(() => {
                                                                                        this.setState({ noteInEdit: note._id });
                                                                                    });
                                                                                } else {
                                                                                    this.setState({ noteInEdit: note._id });
                                                                                }
                                                                            }}
                                                                            icon={'ft-edit'}/>
                                                                    );
                                                                }
                                                            })
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }

                                {
                                    tickets && tickets.length <= 0 &&
                                    <h4>
                                        Nicio solicitare atribuita
                                    </h4>
                                }
                            </React.Fragment>
                        }

                        {
                            !this.state.item &&
                            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                                <div className="spinner_loader secondary_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            </div>
                        }

                    </div>
                </div>

                {
                    this.state.item &&
                    <AddHeadquartersModal
                        parentKey={this.state.item._id}
                        open={this.state.modalOpen}
                        onClose={() => {
                            this.setState({
                                modalOpen: false,
                            });
                            this.update();
                        }}/>
                }

            </React.Fragment>
        );
    }
}

const DocumentListItem = (props) => {
    const [ editing, setEditing ] = useState(false);
    const [ field, setField ] = useState('');

    useEffect(() => {
        setField(props.item.title);
    }, [ props.item ]);

    const item = props.item;

    if (editing) {
        return (
            <div className="d-flex flex-column" style={{ marginBottom: 20 }}>
                <div className={'general-card'} style={{ width: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <TextFieldInput
                            classname={'mb-1'}
                            value={field}
                            icon={'ft-edit'}
                            iconColor={'#0d732d'}
                            placeholder={'Numele documentului'}
                            onChange={(newValue) => { setField(newValue); }}/>
                        <SimpleButton value={''} onPress={() => {
                            changeFileName(item._id, field, (changeSuccessful) => {
                                if (changeSuccessful) {
                                    if (props.onChangeSuccess) {
                                        props.onChangeSuccess();
                                    }
                                } else {
                                    if (props.onChangeFail) {
                                        props.onChangeFail();
                                    }
                                }
                            });
                            setEditing(false);
                        }} icon={'ft-check'}/>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="d-flex flex-column" style={{ marginBottom: 20 }}>
                <div className={'general-card'} style={{ width: '100%' }}>

                    <div style={{ display: 'flex' }}>
                        <TextFieldDisplay
                            icon={'ft-info'}
                            value={item.title}
                            action={'ft-edit'}
                            onPress={() => {
                                setEditing(true);
                            }}
                            style={{ marginBottom: 4, marginRight: 10, flex: 1 }}/>
                        <TextFieldDisplay
                            icon={'ft-download-cloud'}
                            value={'Descarca'}
                            action={'view'}
                            onPress={() => {
                                window.open(item.url, '_blank');
                            }}
                            style={{ marginBottom: 4, marginRight: 10, flex: 1 }}/>
                    </div>
                </div>

            </div>
        );
    }
};


const mapStateToProps = (state) => {
    return {
        user: state.application.user,
        updateModal: state.application.modals.updateModal,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetItemPageTitle: (value) => { return dispatch(applicationSetItemPageTitle(value)); },
        openUpdateModal: (id, previous, type, on) => { return dispatch(applicationOpenUpdateModal({ id, previous, type, on })); },

        toast: (label, action) => { return dispatch(applicationAddSnack({ label, action })); },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClientPage));
