/**
 * @label Stable Imports
 */

import React from 'react';

/**
 * @label Component Imports
 */

import PageError from '../../components/general/PageError';

/**
 * Not Found Public Page.
 * @returns {JSX.Element}
 */

const NotFoundPublicPage = (props) => {

    /**
     * @label Render
     */

    return (
        <>
            <PageError
                art={'•404•'}
                title={'404 - Pagina nu exista'}
                message={'Pagina pe care o cauti este posibil sa fie redenumita, stearsa sau indisponibila momentan. Incearca mai tarziu.'}
                button={{ title: 'Inapoi la Autentificare', link: '/login' }}/>
        </>
    );
};

export default NotFoundPublicPage;