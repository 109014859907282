import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getSale } from '../functional/api';
import InternalToolbar from '../components/general/InternalToolbar';
import TextFieldDisplay from '../components/input/TextFieldDisplay';
import { filter, prettyDateAndTime } from '../functional/min-support';
import { toClientPage, toStockItemPage } from '../functional/navigation';
import { translateSaleProducts } from '../functional/logic';
import ImageFieldDisplay from '../components/input/ImageFieldDisplay';
import { applicationOpenUpdateModal, applicationSetItemPageTitle } from '../store/application-store';

class SalePage extends Component {

    static propTypes = {
        history: PropTypes.object,
    };

    static defaultState = {
        item: null,
        search: '',
    };

    state = SalePage.defaultState;

    componentWillReceiveProps (nextProps, nextContext) {
        if (this.props.updateModal && !nextProps.updateModal) {
            this.update();
        }
    }

    componentDidMount () {
        this.props.onSetItemPageTitle();
        this.update();
    }

    generalUpdate = () => {
        this.setState(SalePage.defaultState, this.update);
    };

    update = () => {
        const key = this.props.match.params.key;
        getSale(key, (successful, data) => {
            if (successful) {
                this.setState({
                    item: data,
                });
                this.props.onSetItemPageTitle(data.name);
            }
        });
    };

    render () {
        let products = this.state.item ? translateSaleProducts(this.state.item.stock) : null;
        const toolbarActions = [
            {
                title: 'Vezi aviz',
                onPress: () => {

                },
                icon: { title: 'ft-file' },
            },
            {
                title: 'Adauga produs',
                onPress: () => { this.setState({ modalOpen: true }); },
                icon: { title: 'ft-plus' },
            },
        ];
        const toolbarLabels = [
            {
                connection: 'in lista de produse dupa:',
                tags: [ 'Denumirea piesei' ],
            },
        ];
        if (products && this.state.search.length > 0) {
            products = filter(products, [ 'name' ], this.state.search, false);
        }
        return (
            <React.Fragment>
                <InternalToolbar
                    backButton
                    refresh
                    onRefresh={this.generalUpdate}
                    search
                    searchValue={this.state.search}
                    onSearchChange={(newValue) => { this.setState({ search: newValue }); }}
                    actions={toolbarActions}
                    labels={toolbarLabels}/>
                <div className="w-100 h-100 overflow-hidden d-flex flex-row">

                    <div className="h-100 overflow-scroll" style={{ flex: 1, padding: 20 }}>
                        {
                            this.state.item &&
                            <React.Fragment>
                                <div className={'general-card'}>

                                    <TextFieldDisplay
                                        title
                                        value={this.state.item.name}
                                        style={{ marginBottom: 4 }}
                                        action={'ft-edit'}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item.id,
                                                this.state.item.name,
                                                'sale',
                                                'name'
                                            );
                                        }}/>

                                    <TextFieldDisplay
                                        label={'creat la data'}
                                        value={prettyDateAndTime(this.state.item.created)}
                                        icon={'ft-clock'}
                                        style={{ marginBottom: 4 }}/>

                                    <TextFieldDisplay
                                        label={'client'}
                                        value={this.state.item.client.name}
                                        icon={'ft-briefcase'}
                                        action={'ft-eye'}
                                        style={{ marginBottom: 4 }}
                                        onPress={() => {
                                            toClientPage(this.state.item.client.id);
                                        }}/>

                                    {
                                        this.state.item.client.address &&
                                        <TextFieldDisplay
                                            value={this.state.item.client.address}
                                            icon={'blank'}
                                            style={{ marginBottom: 4 }}/>
                                    }

                                    {
                                        this.state.item.client.cif &&
                                        <TextFieldDisplay
                                            value={this.state.item.client.cif}
                                            icon={'blank'}
                                            style={{ marginBottom: 4 }}/>
                                    }

                                    {
                                        this.state.item.client.reg &&
                                        <TextFieldDisplay
                                            value={this.state.item.client.reg}
                                            icon={'blank'}
                                            style={{ marginBottom: 4 }}/>
                                    }

                                    {
                                        this.state.item.client.bank &&
                                        <TextFieldDisplay
                                            value={this.state.item.client.bank}
                                            icon={'blank'}
                                            style={{ marginBottom: 4 }}/>
                                    }

                                    {
                                        this.state.item.client.iban &&
                                        <TextFieldDisplay
                                            value={this.state.item.client.iban}
                                            icon={'blank'}
                                            style={{ marginBottom: 4 }}/>
                                    }

                                </div>

                            </React.Fragment>
                        }

                        {
                            !this.state.item &&
                            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                                <div className="spinner_loader secondary_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            </div>
                        }

                    </div>

                    <div className="h-100 overflow-scroll" style={{ width: '40%', padding: 20, paddingLeft: 0 }}>

                        {
                            products && products.length > 0 &&
                            <React.Fragment>
                                {
                                    products.map((product) => {
                                        const total = product.count * (parseFloat(product.price) + parseFloat(product.price_tva));
                                        return (
                                            <div key={product.id} className="general-card card-small-padding" style={{ marginBottom: 20 }}>

                                                <div className="d-flex flex-row justify-content-between">
                                                    <div style={{ flex: 1, paddingRight: 20 }}>
                                                        <TextFieldDisplay
                                                            style={{ marginBottom: 4 }}
                                                            label={'denumire produs'}
                                                            value={product.name}
                                                            icon={'ft-package'}/>

                                                        <TextFieldDisplay
                                                            icon={'blank'}
                                                            style={{ marginBottom: 4 }}
                                                            value={`Numar bucati: ${ product.count }`}/>

                                                        <TextFieldDisplay
                                                            icon={'blank'}
                                                            style={{ marginBottom: 4 }}
                                                            value={`Pret: ${ product.price } RON / bucata`}/>

                                                        <TextFieldDisplay
                                                            icon={'blank'}
                                                            style={{ marginBottom: 4 }}
                                                            value={`TVA: ${ product.price_tva } RON / bucata`}/>

                                                        <TextFieldDisplay
                                                            icon={'blank'}
                                                            value={`Total: ${ total } RON`}/>

                                                        <TextFieldDisplay
                                                            icon={'blank'}
                                                            action={'ft-eye'}
                                                            onPress={() => { toStockItemPage(product.stock_id); }}
                                                            value={'Vezi produs'}/>
                                                    </div>

                                                    <div style={{ width: 200, height: 200, borderRadius: 8 }}>
                                                        <ImageFieldDisplay value={product.services_picture_uid_m} full={product.services_picture_uid_o}/>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </React.Fragment>
                        }

                        {
                            products && products.length <= 0 &&
                            <h4>
                                Niciun produs
                            </h4>
                        }

                        {
                            !this.state.item &&
                            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                                <div className="spinner_loader secondary_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            </div>
                        }

                    </div>
                </div>

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.application.user,
        updateModal: state.application.modals.updateModal,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetItemPageTitle: (value) => { return dispatch(applicationSetItemPageTitle(value)); },
        openUpdateModal: (id, previous, type, on) => { return dispatch(applicationOpenUpdateModal({ id, previous, type, on })); },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SalePage));
