import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


import { createGlobalProduct, uploadStockPhoto } from '../../functional/api';
import BaseModal from './model/BaseModal';
import SingleChoiceField from '../input/SingleChoiceField';
import { inputValidationFloat, inputValidationNonEmpty, inputValidationSingleChoice } from '../../functional/min-support';
import { kQuantityTypes } from '../../functional/constants';
import TextFieldInput from '../input/TextFieldInput';
import { kToastActionError } from '../general/Toast';
import Form from '../input/Form';
import ImageFieldInput from '../input/ImageFieldInput';
import {
    applicationAddSnack,
    applicationCloseLoadingModal,
    applicationOpenLoadingModal,
} from '../../store/application-store';

class Modal extends Component {
    
    static propTypes = {
        user: PropTypes.object,
        
        open: PropTypes.bool,
        
        onClose: PropTypes.func,

        toast: PropTypes.func,
        openLoading: PropTypes.func,
        closeLoading: PropTypes.func,
    };
    
    state = {
        title: '',
        class: '',
        description: '',
        model: '',
        quantityType: 'bucati',

        formComplete: false,
        
        image: null,
        imageURL: null,
        
        price: '',
    };

    render () {

        return (
            <BaseModal
                title={'Adauga produs'}
                stableHeight
                open={this.props.open}
                doneActive={this.state.formComplete}
                onClose={this.onClose}
                onDone={this.onDone}>

                <div className={'w-100'}>

                    <Form complete={this.state.formComplete} setComplete={(complete) => { this.setState({ formComplete: complete }); }}>

                        <TextFieldInput
                            title
                            required
                            validated={inputValidationNonEmpty(this.state.title)}

                            value={this.state.title}
                            style={{ marginBottom: 4 }}
                            label={'Denumirea produsului'}
                            onChange={(newValue) => {
                                this.setState({
                                    title: newValue,
                                });
                            }}/>
    
                        <TextFieldInput
                            icon={'ft-folder'}
                            value={this.state.class}
                            style={{ marginBottom: 4 }}
                            label={'Clasa produsului'}
                            onChange={(newValue) => {
                                this.setState({
                                    class: newValue,
                                });
                            }}/>
    
                        <TextFieldInput
                            icon={'ft-info'}
                            value={this.state.description}
                            style={{ marginBottom: 4 }}
                            label={'Descrierea produsului'}
                            onChange={(newValue) => {
                                this.setState({
                                    description: newValue,
                                });
                            }}/>

                        <TextFieldInput
                            icon={'ft-cpu'}
                            value={this.state.model}
                            style={{ marginBottom: 4 }}
                            label={'Modelul produsului'}
                            onChange={(newValue) => {
                                this.setState({
                                    model: newValue,
                                });
                            }}/>
    
                        <SingleChoiceField
                            required
                            validated={inputValidationSingleChoice(this.state.quantityType)}
        
                            icon={'ft-layers'}
                            label={'Alege tipul cantitatii'}
                            options={kQuantityTypes}
                            value={this.state.quantityType}
                            style={{ marginBottom: 4 }}
                            onSelect={(value) => {
                                this.setState({
                                    quantityType: value,
                                });
                            }}/>
    
                        <TextFieldInput
                            required
                            validated={inputValidationFloat(this.state.price)}
                            
                            icon={'ft-dollar-sign'}
                            value={this.state.price}
                            style={{ marginBottom: 4 }}
                            label={'Pretul de baza al produsului'}
                            onChange={(newValue) => {
                                this.setState({
                                    price: newValue,
                                });
                            }}/>
    
                        <ImageFieldInput
                            local
                            value={this.state.imageURL}
                            onChange={this.onPictureChange} />

                    </Form>

                </div>

            </BaseModal>
        );
    }
    
    onPictureChange = (image, imageURL) => {
        this.setState({
            image,
            imageURL,
        });
    };

    onClose = () => {
        this.props.onClose();
    };

    onDone = () => {
        const request = {
            title: this.state.title,
            model: this.state.model,
            class: this.state.class,
            description: this.state.description,
            counter: this.state.quantityType,
            price: this.state.price ? this.state.price : 0,
        };

        this.props.openLoading();

        createGlobalProduct(request, (successful, data) => {
            if (successful) {
                this.clear();
                this.props.toast('Produs inregistrat cu succes.');
                
                setTimeout(() => {
                    this.props.toast('Poza nu a putut fi incarcata.', kToastActionError);
                }, 2000);
                
                this.props.closeLoading();
                this.onClose();
                //this.addPicture(data);
            } else {
                this.props.toast('Operatiune esuata!', kToastActionError);
                this.props.closeLoading();
                this.onClose();
            }
        });
    };
    
    addPicture = (stockId) => {
        if (this.state.image) {
            uploadStockPhoto(this.state.image, stockId, (successful) => {
                if (successful) {
                    this.clear();
                    this.props.toast('Produs inregistrat cu succes.');
                } else {
                    this.props.toast('Adaugarea pozei esuata!', kToastActionError);
                }
                this.props.closeLoading();
                this.onClose();
            });
        } else {
            this.props.toast('Produs inregistrat cu succes.');
            this.props.closeLoading();
            this.onClose();
        }
    };

    componentWillReceiveProps (nextProps, nextContext) {
        if (!this.props.open && nextProps.open) {
            this.update();
        }
    }
    
    update = () => {
    
    };

    clear = () => {
        this.setState({
            title: '',
            class: '',
            description: '',
            model: '',
            quantityType: 'bucati',
    
            formComplete: false,
    
            image: null,
            imageURL: null,
    
            price: '',
        });
    };

}

const mapStateToProps = (state) => {
    return {
        user: state.application.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toast: (label, action) => { return dispatch(applicationAddSnack({ label, action })); },

        openLoading: () => { return dispatch(applicationOpenLoadingModal()); },
        closeLoading: () => { return dispatch(applicationCloseLoadingModal()); },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);