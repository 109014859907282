import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getAllTickets, getApplicationHistory } from '../functional/api';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PickerModal from '../components/modal/model/PickerModal';
import maleImage from '../assets/images/global/user-placeholder-male.png';
import femaleImage from '../assets/images/global/user-placeholder-female.png';
import {
    colorByStatus,
    iconByStatus,
    kStatusAwaitsResponse,
    kStatusDescription,
    kStatusFinished,
    kStatusInProgress,
    kStatusNew,
    kStatusStopped,
} from '../functional/status';
import AddTicketModal from '../components/modal/AddTicketModal';
import { kFilterTicketFields, kFilterTicketFieldsDefault } from '../functional/constants';
import { filter, parsePhraseSpecialFormatted, prettyDateAndTime, smallIcon } from '../functional/min-support';
import OperatorMultiplePicker from '../components/modal/pickers/OperatorMultiplePicker';
import ClientMultiplePicker from '../components/modal/pickers/ClientMultiplePicker';
import ListItem from '../components/ticket/TicketListItem';
import AddClientModal from '../components/modal/AddClientModal';
import AddSaleModal from '../components/modal/AddSaleModal';
import AddInvoiceModal from '../components/modal/AddInvoiceModal';
import AddStockModal from '../components/modal/AddStockModal';
import AddOrderModal from '../components/modal/AddOrderModal';
import InternalToolbar from '../components/general/InternalToolbar';
import { Card, CardBody } from 'reactstrap';
import TicketCountChart from '../components/dashboard/charts/TicketCountChart';
import { toTicketPage } from '../functional/navigation';
import TextFieldDisplay from '../components/input/TextFieldDisplay';
import SimpleButton from '../components/input/SimpleButton';
import { deletePageData, getPageData, hasPageData, savePageData } from '../functional/storage';

class DashboardPage extends Component {

    identifier = 0;

    updateInterval = null;

    static propTypes = {
        user: PropTypes.object,
    };

    static defaultState = {
        loading: true,
        search: '',
        fields: kFilterTicketFieldsDefault,
        filterModal: false,

        items: [],

        history: [],

        operators: [],
        operatorLabels: [],

        clients: [],
        clientLabels: [],

        operatorModalOpen: false,
        clientModalOpen: false,

        addTicketModalOpen: false,
        addSaleModalOpen: false,
        addInvoiceModalOpen: false,
        addClientModalOpen: false,
        addStockModalOpen: false,
        addOrderModalOpen: false,

        activeStatus: null,

        detailsVisible: false,

        activeElement: null,
    };

    state = DashboardPage.defaultState;

    get shouldDisplayGroups () {
        return !this.state.activeStatus;
    }

    componentDidMount () {
        // deletePageData('dashboard');
        if (hasPageData('dashboard')) {
            this.setState({ ...this.state, ...getPageData('dashboard') });
        }
        this.update();
        this.historyInterval = setInterval(() => {
            this.updateHistory();
        }, 2000);
    }

    componentWillUnmount () {
        if (this.updateInterval) {
            //clearInterval(this.updateInterval);
        }
        if (this.historyInterval) {
            clearInterval(this.historyInterval);
        }
        const stateToSave = { ...this.state };
        if (stateToSave.items) {
            stateToSave.items = stateToSave.items.slice(0, 50);
        }
        savePageData('dashboard', stateToSave);
    }

    generalUpdate = () => {
        this.setState(DashboardPage.defaultState, this.update);
    }

    update = () => {
        getAllTickets((successful, data) => {
            if (successful) {
                const newData = data.map((item) => {
                    item.clientInfo = { title: 'Dummy', _id: 'dummy', cid: 'dummy', tri: 'dummy', bankTitle: 'dummy', bankAccount: 'dummy', address: 'dummy', contactPhone: 'dummy' };
                    return {
                        ...item,
                    };
                });
                this.setState({
                    items: newData,
                    loading: false,
                });
            } else {
                console.error('no tickets');
                this.setState({
                    loading: false,
                });
            }
        });

        this.updateHistory();
    };

    updateHistory = () => {
        // getApplicationHistory((successful, data) => {
        //     if (successful) {
        //         console.log('history app', data);
        //         this.setState({
        //             history: data,
        //         });
        //     }
        // });
    }

    render () {
        let items = [];
        const statusGroup = {};
        let totalNb = 0;

        const filtered = this.filterForActiveStatus(this.state.items, this.state.activeStatus);
        const newFiltered = this.filterByClientAndOperator(filtered, this.state.operators);
        // console.log('filters', this.state.fields);
        items = filter(newFiltered, this.state.fields, this.state.search, true);

        statusGroup.personal = {};
        for (let i = 0; i < items.length; ++i) {
            if (!statusGroup[ items[ i ].status ]) {
                statusGroup[ items[ i ].status ] = 0;
            }
            statusGroup[ items[ i ].status ] += 1;
            totalNb += 1;
            if (items[ i ].assigned === this.props.user.id) {
                if (!statusGroup.personal[ items[ i ].status ]) {
                    statusGroup.personal[ items[ i ].status ] = 0;
                }
                statusGroup.personal[ items[ i ].status ] += 1;
            }
        }

        const toolbarActions = [
            {
                title: 'Filtre',
                onPress: this.openFilterModal,
                icon: { title: 'ft-filter' },
            },
            {
                title: 'Operatori',
                onPress: this.openOperatorModal,
                icon: { title: 'ft-users' },
            },
            {
                title: 'Clienti',
                onPress: this.openClientModal,
                icon: { title: 'ft-briefcase' },
            },
            {
                title: 'Solicitare noua',
                onPress: this.openAddTicketModal,
                icon: { title: 'ft-check-square' },
            },
        ];

        if (false && this.state.detailsVisible) {
            toolbarActions.push({
                title: 'Ascunde',
                onPress: () => { this.setState({ detailsVisible: !this.state.detailsVisible }); },
                icon: { title: this.state.detailsVisible ? 'ft-corner-up-right' : 'ft-corner-down-left' },
            });
        }

        const toolbarLabels = [
            {
                connection: 'dupa:',
                tags: this.matchFields(this.state.fields),
            },
            {
                connection: 'pentru operatorii:',
                tags: this.getSecondLabels(this.state.operatorLabels),
            },
            // {
            //     connection: 'pentru clientii:',
            //     tags: this.getSecondLabels(this.state.clientLabels),
            // },
        ];

        if (this.state.activeStatus) {
            toolbarActions.unshift({
                title: this.state.activeStatus !== 'all' ? this.state.activeStatus : 'Toate',
                onPress: () => { this.setState({ activeStatus: null, operators: [], operatorLabels: [] }); },
                style: { backgroundColor: colorByStatus(this.state.activeStatus), color: '#ffffff' },
                icon: { title: 'ft-x', color: '#ffffff', size: 14 },
            });
        }

        const rightSidebarStyle = {};
        if (!this.state.detailsVisible) {
            rightSidebarStyle.display = 'none';
        }

        return (
            <React.Fragment>

                <InternalToolbar
                    refresh
                    onRefresh={this.generalUpdate}
                    search
                    onSearchChange={this.searchChange}
                    searchValue={this.state.search}
                    actions={toolbarActions}
                    labels={toolbarLabels}/>

                <div className={'dashboard-page'}>

                    <div className={'dashboard-arranger'}>
                        <div className={'dashboard-top'}>
                            <div className={'dashboard-top-left'}>

                                {
                                    true && this.shouldDisplayGroups &&
                                    <StatusSelectGrid level={this.props.user.level} statusGroup={statusGroup} onItemPress={this.onStatusPress} all={totalNb} user={this.props.user}/>
                                }

                                {
                                    false && this.shouldDisplayGroups &&
                                    <div style={{ paddingTop: 20 }}>
                                        <TicketCountChart data={statusGroup}/>
                                    </div>
                                }

                                <div className={'dashboard-list-container'}>
                                    {
                                        this.state.loading &&
                                        <div className="w-100 h-100 d-flex align-items-center justify-content-center" style={{ minHeight: 200 }}>
                                            <div className="spinner_loader secondary_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                        </div>
                                    }

                                    {
                                        !this.state.loading && items.map((item) => {
                                            return (
                                                <ListItem active={this.state.activeElement ? this.state.activeElement._id === item._id : false} key={item.id} item={item} history={this.props.history} onPress={this.onItemPress}/>
                                            );
                                        })
                                    }
                                </div>

                            </div>
                            <div className={'dashboard-top-right'} style={rightSidebarStyle}>

                                {
                                    false && this.buttonsBasedOnLevel().map((item) => { return item; })
                                }

                                {
                                    false && this.state.history.map((event) => {
                                        return (
                                            <div key={event.id} className={'history-event-third'}>
                                                <img className="rounded-circle event-image" src={event.info.userGender === 'male' ? maleImage : femaleImage} style={{ width: 50, height: 50, marginRight: 10 }} width="50" height="50" alt="TextALt" />
                                                <div className="media-body event-body">
                                                    <span style={{ color: '#363f5a', fontSize: 14, fontWeight: 500 }}>
                                                        {event.info.userFullName}
                                                        <br/>
                                                        <small style={{ color: '#934A5F' }} className="font-weight-normal">
                                                            {prettyDateAndTime(event.created)}
                                                        </small>

                                                    </span>

                                                    <span style={{ color: '#000', paddingTop: 4, fontSize: 14 }} className="d-block">

                                                        {parsePhraseSpecialFormatted(event.phrase).map((currentPhrase) => {

                                                            if (currentPhrase.special) {
                                                                return (
                                                                    <span style={{ color: '#934A5F', fontWeight: 500 }}>
                                                                        {currentPhrase.text}
                                                                    </span>
                                                                );
                                                            } else {
                                                                return (
                                                                    currentPhrase.text
                                                                );
                                                            }
                                                        })}

                                                    </span>
                                                </div>
                                            </div>
                                        );
                                    })
                                }

                                {
                                    this.state.activeElement &&
                                    <div style={{ width: '100%' }}>

                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingBottom: 4 }}>
                                            <SimpleButton outline onPress={() => { this.setState({ detailsVisible: !this.state.detailsVisible }); }} icon={'ft-x-circle'} value={'Inchide solicitarea'}/>
                                            <SimpleButton outline onPress={() => { toTicketPage(this.state.activeElement._id); }} icon={'ft-eye'} value={'Vezi solicitarea'}/>
                                        </div>
                                    
                                        <TextFieldDisplay
                                            label={ 'Denumire' }
                                            value={ this.state.activeElement.title }
                                            style={{ marginBottom: 4 }}
                                            action={'ft-view'}
                                            onPress={() => {
                                                toTicketPage(this.state.activeElement._id);
                                            }}/>

                                        <TextFieldDisplay
                                            label={'Status'}
                                            textBackgroundColor={colorByStatus(this.state.activeElement.status)}
                                            textColor={'#ffffff'}
                                            value={this.state.activeElement.status}
                                            icon={'ft-code'}
                                            style={{ marginBottom: 4 }}/>

                                        <TextFieldDisplay
                                            label={'Detalii'}
                                            value={this.state.activeElement.description}
                                            icon={'ft-file-text'}
                                            action={'ft-edit'}
                                            style={{ marginBottom: 4 }}
                                            onPress={() => {
                                                // this.props.openUpdateModal(
                                                //     this.state.activeElement.id,
                                                //     this.state.activeElement.description,
                                                //     'ticket',
                                                //     'description'
                                                // );
                                            }}/>

                                        <TextFieldDisplay
                                            label={'Data'}
                                            value={prettyDateAndTime(this.state.activeElement.created)}
                                            icon={'ft-clock'}
                                            style={{ marginBottom: 4 }}/>

                                        <TextFieldDisplay
                                            label={'Operatorul'}
                                            value={this.state.activeElement.assignedDetails ? this.state.activeElement.assignedDetails.fullName : this.state.activeElement.creatorInfo.fullName}
                                            icon={'ft-users'}
                                            style={{ marginBottom: 4 }}/>

                                        <TextFieldDisplay
                                            label={'Creatorul'}
                                            value={this.state.activeElement.creatorInfo.fullName}
                                            icon={'ft-users'}
                                            style={{ marginBottom: 4 }}/>
                                        
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <OperatorMultiplePicker
                        minimumChoices={0}
                        maximumChoices={100}
                        open={this.state.operatorModalOpen}
                        values={this.state.operators}
                        onClose={this.closeOperatorModal}
                        onDone={this.onOperatorModalDone}
                        setLabels={(labels) => {
                            this.setState({
                                operatorLabels: labels,
                            });
                        }}/>

                    <ClientMultiplePicker
                        minimumChoices={0}
                        maximumChoices={100}
                        open={this.state.clientModalOpen}
                        values={this.state.clients}
                        onClose={this.closeClientModal}
                        onDone={this.onClientModalDone}
                        setLabels={(labels) => {
                            this.setState({
                                clientLabels: labels,
                            });
                        }}/>

                    <PickerModal
                        title={'Alege campurile cautarii'}
                        open={this.state.filterModal}
                        options={kFilterTicketFields}
                        initialSelectedValues={this.state.fields}
                        minimumChoices={1}
                        maximumChoices={100}
                        onClose={this.closeFilterModal}
                        onDone={this.doneFilterModal}
                        returnMatchedFields/>

                    <AddTicketModal
                        open={this.state.addTicketModalOpen}
                        onClose={this.closeAddTicketModal}/>

                    <AddSaleModal
                        open={this.state.addSaleModalOpen}
                        onClose={this.closeAddSaleModal}/>

                    <AddInvoiceModal
                        open={this.state.addInvoiceModalOpen}
                        onClose={this.closeAddInvoiceModal}/>

                    <AddClientModal
                        open={this.state.addClientModalOpen}
                        onClose={this.closeAddClientModal}/>

                    <AddStockModal
                        open={this.state.addStockModalOpen}
                        onClose={this.closeAddStockModal}/>

                    <AddOrderModal
                        open={this.state.addOrderModalOpen}
                        onClose={this.closeAddOrderModal}/>

                </div>
            </React.Fragment>
        );
    }

    buttonsBasedOnLevel = () => {
        const buttons = [];

        if (this.props.user.level === 'god') {
            buttons.push(<AdvancedButton onPress={this.openAddTicketModal} icon={'ft-check-square'} hint={'Adauga'} label={'Solicitare'} description={'Adauga o noua solicitare primita de la client. In cadrul acesteia se va desfasura comunicarea.'}/>);
            buttons.push(<AdvancedButton onPress={this.openAddClientModal} icon={'ft-briefcase'} hint={'Adauga'} label={'Client'} description={'Adauga un nou client in registrul tau. Poti asocia clientul cu un grup de clienti.'}/>);
            buttons.push(<AdvancedButton onPress={this.openAddStockModal} icon={'ft-layers'} hint={'Adauga'} label={'Produs'} description={'Adauga un nou tip de produs in registrul tau. Produsele create astfel vor avea vizibilitate globala.'}/>);
        } else if (this.props.user.level === 'admin') {
            buttons.push(<AdvancedButton onPress={this.openAddTicketModal} icon={'ft-check-square'} hint={'Adauga'} label={'Solicitare'} description={'Adauga o noua solicitare primita de la client. In cadrul acesteia se va desfasura comunicarea.'}/>);
            buttons.push(<AdvancedButton onPress={this.openAddClientModal} icon={'ft-briefcase'} hint={'Adauga'} label={'Client'} description={'Adauga un nou client in registrul tau. Poti asocia clientul cu un grup de clienti.'}/>);
            buttons.push(<AdvancedButton onPress={this.openAddStockModal} icon={'ft-layers'} hint={'Adauga'} label={'Produs'} description={'Adauga un nou tip de produs in registrul tau. Produsele create astfel vor avea vizibilitate globala.'}/>);
        } else if (this.props.user.level === 'operator') {
            buttons.push(<AdvancedButton onPress={this.openAddTicketModal} icon={'ft-check-square'} hint={'Adauga'} label={'Solicitare'} description={'Adauga o noua solicitare primita de la client.'}/>);
        } else if (this.props.user.level === 'outside') {
            buttons.push(<AdvancedButton onPress={this.openAddTicketModal} icon={'ft-check-square'} hint={'Adauga'} label={'Solicitare'} description={'Adauga o noua solicitare primita de la client.'}/>);
        }

        return buttons;
    }

    onStatusPress = (item, sectionPersonal, personalType) => {
        if (!sectionPersonal) {
            this.setState({
                activeStatus: item,
            });
        } else {
            this.setState({
                operators: [ this.props.user.id ],
                operatorLabels: [ `${ this.props.user.firstName } ${ this.props.user.lastName }` ],
                activeStatus: personalType,
            });
        }
    }

    filterForActiveStatus = (list, activeStatus) => {
        if (!activeStatus || activeStatus === 'all') {
            return list;
        }
        const result = [];
        for (let i = 0; i < list.length; ++i) {
            if (list[ i ].status === activeStatus) {
                result.push(list[ i ]);
            }
        }
        return result;
    }

    searchChange = (newValue) => {
        this.setState({
            search: newValue,
        });
    };

    openFilterModal = () => {
        this.setState({
            filterModal: true,
        });
    };

    closeFilterModal = () => {
        this.setState({
            filterModal: false,
        });
    };

    doneFilterModal = (newFilters, matchedFields) => {
        this.setState({
            fields: newFilters,
        });
        this.closeFilterModal();
    };

    openAddTicketModal = () => {
        this.setState({
            addTicketModalOpen: true,
        });
    };

    closeAddTicketModal = () => {
        this.setState({
            addTicketModalOpen: false,
        });
        this.update();
    };

    openAddSaleModal = () => {
        this.setState({
            addSaleModalOpen: true,
        });
    };

    closeAddSaleModal = () => {
        this.setState({
            addSaleModalOpen: false,
        });
        this.update();
    };

    openAddInvoiceModal = () => {
        this.setState({
            addInvoiceModalOpen: true,
        });
    };

    closeAddInvoiceModal = () => {
        this.setState({
            addInvoiceModalOpen: false,
        });
        this.update();
    };

    openAddClientModal = () => {
        this.setState({
            addClientModalOpen: true,
        });
    };

    closeAddClientModal = () => {
        this.setState({
            addClientModalOpen: false,
        });
        this.update();
    };

    openAddStockModal = () => {
        this.setState({
            addStockModalOpen: true,
        });
    };

    closeAddStockModal = () => {
        this.setState({
            addStockModalOpen: false,
        });
        this.update();
    };

    openAddOrderModal = () => {
        this.setState({
            addOrderModalOpen: true,
        });
    };

    closeAddOrderModal = () => {
        this.setState({
            addOrderModalOpen: false,
        });
        this.update();
    };

    filterByClientAndOperator = (items) => {
        const result = items;
        // now foreach operator
        let filteredByOperator = [];
        const operators = this.state.operators;
        console.log('filter', operators);
        if (operators.length > 0) {
            for (let i = 0; i < result.length; ++i) {
                for (let j = 0; j < operators.length; ++j) {
                    if (result[ i ].creator == operators[ j ] || result[ i ].assigned == operators[ j ]) {
                        filteredByOperator.push(result[ i ]);
                        break;
                    }
                }
            }
        } else {
            filteredByOperator = result;
        }

        // now foreach operator
        const filteredByClient = [];
        const clients = this.state.clients;
        if (clients.length > 0) {
            for (let i = 0; i < filteredByOperator.length; ++i) {
                for (let j = 0; j < clients.length; ++j) {
                    if (filteredByOperator[ i ].client == clients[ j ]) {
                        filteredByClient.push(filteredByOperator[ i ]);
                        break;
                    }
                }
            }
        } else {
            return filteredByOperator;
        }

        return filteredByClient;
    };

    getSecondLabels = (list) => {
        if (list.length > 0) {
            return list;
        } else {
            return [ 'Toti' ];
        }
    }

    matchFields = (fields) => {
        const matchedFields = [];
        for (let i = 0; i < kFilterTicketFields.length; ++i) {
            if (fields.includes(kFilterTicketFields[ i ].value)) {
                matchedFields.push(kFilterTicketFields[ i ]);
            }
        }
        return matchedFields.map((item) => { return item.label; });
    };

    onItemPress = (item) => {
        if (this.state.activeElement && this.state.activeElement._id === item._id) {
            this.setState({ activeElement: null, detailsVisible: false });
        } else {
            this.setState({ activeElement: item, detailsVisible: true });
        }
        // toTicketPage(item._id);
    };

    onOperatorModalDone = (newValues) => {
        this.setState({
            operators: newValues,
        });
        this.closeOperatorModal();
    }

    openOperatorModal = () => {
        this.setState({
            operatorModalOpen: true,
        });
    }

    closeOperatorModal = () => {
        this.setState({
            operatorModalOpen: false,
        });
    }

    onClientModalDone = (newValues) => {
        this.setState({
            clients: newValues,
        });
        this.closeClientModal();
    }

    openClientModal = () => {
        this.setState({
            clientModalOpen: true,
        });
    }

    closeClientModal = () => {
        this.setState({
            clientModalOpen: false,
        });
    }

}

const StatusSelectGrid = (props) => {
    const statusGroup = props.statusGroup;
    const personalCounts = props.statusGroup.personal;
    const user = props.user;
    return (
        <div className={'dashboard-status-select-grid'}>
            <StatusSelectGridEntry onPress={props.onItemPress} item={kStatusNew} count={statusGroup[ kStatusNew ]}/>
            <StatusSelectGridEntry onPress={props.onItemPress} item={kStatusInProgress} count={statusGroup[ kStatusInProgress ]}/>
            <StatusSelectGridEntry onPress={props.onItemPress} item={kStatusFinished} count={statusGroup[ kStatusFinished ]}/>

            <StatusSelectGridEntry personal personalType={kStatusNew} color={'#560b04'} onPress={props.onItemPress} item={'Nou - eu'} count={personalCounts && personalCounts[ kStatusNew ] ? personalCounts[ kStatusNew ] : 0}/>
            <StatusSelectGridEntry personal personalType={kStatusInProgress} color={'#052946'} onPress={props.onItemPress} item={'In lucru - eu'} count={personalCounts && personalCounts[ kStatusInProgress ] ? personalCounts[ kStatusInProgress ] : 0}/>
            <StatusSelectGridEntry onPress={props.onItemPress} item={'all'} count={props.all}/>
        </div>
    );
};

/*

            <StatusSelectGridEntry personal personalType={kStatusNew} color={'#833A4F'} onPress={props.onItemPress} item={`Nou - ${ user.firstName }`} count={personalCounts && personalCounts[ kStatusNew ] ? personalCounts[ kStatusNew ] : 0}/>
            <StatusSelectGridEntry personal personalType={kStatusNew} color={'#531a2a'} onPress={props.onItemPress} item={`In lucru - ${ user.firstName }`} count={personalCounts && personalCounts[ kStatusInProgress ] ? personalCounts[ kStatusInProgress ] : 0}/>

*/

const StatusSelectGridEntry = (props) => {
    const style = {};
    const innerContainerStyle = {};
    //let statusColor = (new Color(colorByStatus(props.item))).darken(0.5).hexa();
    let statusColor = '#000000';
    if (!props.personal) {
        statusColor = colorByStatus(props.item);
    } else {
        if (props.color) {
            statusColor = props.color;
        }
    }
    style.backgroundColor = statusColor;
    let count = 0;
    if (props.count) {
        count = props.count;
    }
    let description = '';
    if (kStatusDescription[ props.item ]) {
        description = kStatusDescription[ props.item ];
    }
    let name = '';
    if (props.item) {
        name = props.item;
    }
    if (name === 'all') {
        name = 'Toate';
    }
    if (name === 'Asteapta aprobare') {
        name = 'Asteapta aprob.'; // TMP
    }

    const iconClassname = `feather ${ iconByStatus(props.item) }`;

    let buttonActiveClass = 'another-container-inactive';
    if (count > 0) {
        buttonActiveClass = 'another-container-active';
        innerContainerStyle.backgroundColor = '#eee';
    }

    return (
        <div className="col-lg-2 col-md-4 stretched_card" style={{ paddingTop: 10 }}>
            <Card className="analytics_card_dashboard" style={{ backgroundColor: statusColor, borderRadius: 0, cursor: 'pointer' }} onClick={() => { props.onPress(props.item, props.personal, props.personalType); }}>
                <CardBody style={{ padding: 8, paddingRight: 4 }}>
                    <div className="d-flex flex-row flex-wrap align-items-center justify-content-center text-white">
                        <p className="text-xl-left ms-2 me-2" style={{ marginBottom: 0 }}>{name}</p>
                        <h6 className="mb-0 text-white">{count}</h6>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
};

/*

<div className="col-lg-4 col-md-4 stretched_card" style={{ paddingLeft: 10, paddingTop: 10 }}>
            <Card className="analytics_card_dashboard" style={{ backgroundColor: statusColor, borderRadius: 8 }}>
                <CardBody style={{ padding: 20, paddingRight: 4 }}>
                    <div className="d-flex flex-row flex-wrap align-items-center justify-content-between">
                        <div style={{ backgroundColor: '#ffffff', padding: 10, borderRadius: 100, height: 40, width: 40 }}>
                            {
                                smallIcon(iconByStatus(props.item), statusColor, 20)
                            }
                        </div>
                        <div className="text-white flex-grow-1" style={{ paddingLeft: 20 }}>
                            <p className="mt-xl-0 text-xl-left mb-2">{name}</p>
                            <div className="d-flex flex-row flex-wrap align-items-center ">
                                <h4 className="mb-0 text-white">{count}</h4>
                                <div className="stats_icon" onClick={() => { props.onPress(props.item, props.personal, props.personalType); }}>vezi<span className="feather ft-eye" style={{ paddingLeft: 4 }}/></div>
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </div>

        */


const AdvancedButton = (props) => {
    let style = {};
    if (props.style) {
        style = { ...props.style };
    }

    return (
        <div className={'advanced-button'} onClick={props.onPress} style={style}>

            {
                props.icon &&
                <div className={'icon-container'}>
                    {
                        smallIcon(props.icon, null, 20)
                    }
                </div>
            }

            <div className={'text-container'}>
                <div className={'label-container'}>
                    {
                        props.label
                    }
                </div>
                {
                    props.description &&
                    <div className={'description-container'}>
                        {
                            props.description
                        }
                    </div>
                }
            </div>

        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.application.user,
    };
};

export default connect(mapStateToProps, null)(withRouter(DashboardPage));
