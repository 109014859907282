import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


import { addClientEmailAddress } from '../../functional/api';
import BaseModal from './model/BaseModal';
import TextFieldInput from '../input/TextFieldInput';
import { kToastActionError } from '../general/Toast';
import { inputValidationEmail, inputValidationNonEmpty } from '../../functional/min-support';
import Form from '../input/Form';
import {
    applicationAddSnack,
    applicationCloseLoadingModal,
    applicationOpenLoadingModal,
} from '../../store/application-store';

class Modal extends Component {
    
    static propTypes = {
        parentKey: PropTypes.any,

        open: PropTypes.bool,
        
        onClose: PropTypes.func,

        toast: PropTypes.func,
        openLoading: PropTypes.func,
        closeLoading: PropTypes.func,
    };
    
    state = {
        personName: '',
        rank: '',
        address: '',
        email: '',

        formComplete: false,
    };

    componentWillReceiveProps (nextProps, nextContext) {
        if (!this.props.open && nextProps.open) {
            this.update();
        }
    }

    update = () => {

    };

    render () {

        return (
            <BaseModal
                title={'Adauga adresa de email'}
                open={this.props.open}
                doneActive={this.state.formComplete}
                onClose={this.onClose}
                onDone={this.onDone}>

                <div className={'w-100'}>

                    <Form complete={this.state.formComplete} setComplete={(complete) => { this.setState({ formComplete: complete }); }}>

                        <TextFieldInput
                            required
                            validated={inputValidationNonEmpty(this.state.personName)}

                            icon={'ft-info'}
                            value={this.state.personName}
                            style={{ marginBottom: 4 }}
                            label={'Numele persoanei de contact'}
                            onChange={(newValue) => {
                                this.setState({
                                    personName: newValue,
                                });
                            }}/>

                        <TextFieldInput
                            icon={'ft-info'}
                            value={this.state.rank}
                            style={{ marginBottom: 4 }}
                            label={'Functia persoanei de contact'}
                            onChange={(newValue) => {
                                this.setState({
                                    rank: newValue,
                                });
                            }}/>

                        <TextFieldInput
                            required
                            validated={inputValidationEmail(this.state.address)}

                            icon={'ft-at-sign'}
                            value={this.state.address}
                            label={'Adresa de email'}
                            onChange={(newValue) => {
                                this.setState({
                                    address: newValue,
                                });
                            }}/>

                    </Form>

                </div>

            </BaseModal>
        );
    }

    onClose = () => {
        this.props.onClose();
    };

    onDone = () => {
        if (!this.props.parentKey) {
            return;
        }

        const request = {
            clientId: this.props.parentKey,
            email: {
                address: this.state.address,
                personName: this.state.personName,
                rank: this.state.rank,
            },
        };

        this.props.openLoading();

        addClientEmailAddress(request, (successful) => {
            if (successful) {
                this.clear();
                this.props.toast('Adresa de mail salvata cu succes.');
            } else {
                this.props.toast('Operatiune esuata!', kToastActionError);
            }
            this.props.closeLoading();
            this.onClose();
        });
    };

    clear = () => {
        this.setState({
            address: '',
            email: '',
            rank: '',
            personName: '',
        });
    };

}

const mapDispatchToProps = (dispatch) => {
    return {
        toast: (label, action) => { return dispatch(applicationAddSnack({ label, action })); },

        openLoading: () => { return dispatch(applicationOpenLoadingModal()); },
        closeLoading: () => { return dispatch(applicationCloseLoadingModal()); },
    };
};

export default connect(null, mapDispatchToProps)(Modal);