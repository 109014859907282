import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getAllInvoices, getAllSalesAffiliate } from '../functional/api';
import { withRouter } from 'react-router-dom';
import {
    kFilterInvoiceFields,
    kFilterInvoiceFieldsDefault,
    kFilterSalesFields,
    kFilterSalesFieldsDefault,
} from '../functional/constants';
import ListPage from '../layout/page/ListPage';
import TablePage from '../layout/page/TablePage';
import TextFieldDisplay from '../components/input/TextFieldDisplay';
import { ro } from 'date-fns/locale';
import AddInvoiceModal from '../components/modal/AddInvoiceModal';
import { connect } from 'react-redux';
import {
    compareGenerator,
    compareGeneratorString,
    currentMonth,
    currentMonthNumber,
    getMonthWithNumber,
    prettyDateAndTime,
} from '../functional/min-support';
import { toInvoicePage } from '../functional/navigation';
import MonthPicker from '../components/modal/pickers/MonthPicker';
import AddSaleAffiliateModal from '../components/modal/AddSaleAffiliateModal';
import FileUploadModal from '../components/modal/FileUploadModal';

import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import PickerModal from '../components/modal/model/PickerModal';

class AffiliateDashboardPage extends Component {

    static propTypes = {
        history: PropTypes.object,
    };

    static defaultState = {
        items: null,
        modalOpen: false,
        addModalOpen: false,
        selectedMonth: currentMonth(),
        selectedMonthNumber: currentMonthNumber(),
        total: 0,
        selectedDate: new Date(),
        selectAffiliateOpen: false,
        allAffiliates: [],
        selectedAffiliate: null,
    };

    state = AffiliateDashboardPage.defaultState;

    componentDidMount () {
        this.update();
    }

    generalUpdate = () => {
        this.setState(AffiliateDashboardPage.defaultState, this.update);
    };

    update = () => {
        getAllSalesAffiliate(this.state.selectedMonthNumber, (successful, data) => {
            console.log('result', successful, data);
            if (successful) {
                const allAffiliates = [];
                data = data.map((item) => {
                    return {
                        ...item,
                        commission: parseInt(item.valueNoVAT) / 10,
                        accountInfoLevel: item.userInfo && item.userInfo.cid && item.userInfo.tri && item.userInfo.bankTitle && item.userInfo.bankAccount ? 'Toate datele' : item.userInfo ? 'Cont fara date' : 'Fara cont',
                    };
                });
                data.forEach((item) => {
                    if (!allAffiliates.includes(item.affiliateUsername)) {
                        allAffiliates.push(item.affiliateUsername);
                    }
                });
                this.setState({
                    items: data,
                    // total: data.reduce((acc, curr) => { return parseInt(acc) + parseInt(curr.valueNoVAT); }, 0) / 10,
                    allAffiliates,
                });
            } else {
                console.error('no clients');
            }
        });
    };

    render () {
        const actions = [
            {
                title: 'Schimba luna',
                icon: { title: 'ft-calendar' },
                onPress: () => {
                    this.setState({
                        modalOpen: true,
                    });
                },
            },
        ];
        // console.log('level', this.props.user.level);
        if (this.props.user.level != 'affiliate') {
            actions.push({
                title: 'Adauga sumar',
                icon: { title: 'ft-plus' },
                onPress: () => {
                    this.setState({
                        addModalOpen: true,
                    });
                },
            });
            actions.push({
                title: 'Incarca fisier',
                icon: { title: 'ft-upload-cloud' },
                onPress: () => {
                    this.setState({
                        uploadOpen: true,
                    });
                },
            });

            actions.push({
                title: 'Selecteaza afiliat',
                icon: { title: 'ft-user' },
                onPress: () => {
                    this.setState({
                        selectAffiliateOpen: true,
                    });
                },
            });
        }

        let filteredData = this.state.items;
        if (this.state.selectedAffiliate) {
            filteredData = filteredData.filter((item) => { return item.affiliateUsername === this.state.selectedAffiliate; });
        }
        console.log('filtered', filteredData);
        const total = filteredData ? filteredData.reduce((acc, curr) => { return parseInt(acc) + parseInt(curr.valueNoVAT); }, 0) / 10 : 0;

        // TODO: if(user is not authorized) => no add button
        return (
            <TablePage
                onRefresh={this.generalUpdate}
                deepSearch={false}
                loading={this.state.items === null}
                actions={actions}
                labels={[
                    {
                        connection: 'pentru luna:',
                        tags: [ this.state.selectedMonth ],
                    },
                    {
                        connection: 'total comision:',
                        tags: [ `${ total } RON` ],
                    },
                    {
                        connection: 'pentru operatorul:',
                        tags: [ this.state.selectedAffiliate ? this.state.selectedAffiliate : 'Toti' ],
                    },
                ]}

                tableFields={{
                    'client-name': {
                        title: 'Clientul',
                        transform: (item) => { return item.clientName; },
                        action: (item) => { },
                        compare: compareGeneratorString('clientName'),
                        icon: 'ft-briefcase',
                    },
                    'client-cif': {
                        title: 'CIF',
                        transform: (item) => { return item.clientCIF; },
                        action: (item) => { },
                        compare: compareGeneratorString('clientCIF'),
                        icon: 'ft-hash',
                    },
                    'value-no-vat': {
                        title: 'Total fara TVA',
                        transform: (item) => { return `${ item.valueNoVAT } RON`; },
                        compare: compareGenerator('valueNoVAT'),
                        icon: 'ft-dollar-sign',
                    },
                    'value-with-vat': {
                        title: 'Total cu TVA',
                        transform: (item) => { return `${ item.valueWithVAT } RON`; },
                        compare: compareGenerator('valueWithVAT'),
                        icon: 'ft-dollar-sign',
                    },
                    'commision': {
                        title: 'Comision',
                        transform: (item) => { return `${ item.commission } RON`; },
                        compare: compareGenerator('commission'),
                        icon: 'ft-percent',
                    },
                    'affiliate-username': {
                        title: 'Afiliat',
                        transform: (item) => { return `${ item.affiliateUsername } (${ item.accountInfoLevel })`; },
                        compare: compareGeneratorString('affiliateUsername'),
                        icon: 'ft-user',
                    },
                }}
                defaultSort={'client-name'}

                allListItems={this.state.selectedAffiliate ? this.state.items.filter((item) => { return item.affiliateUsername === this.state.selectedAffiliate; }) : this.state.items}
                defaultFields={kFilterSalesFieldsDefault}
                allFields={kFilterSalesFields}>

                <LocalizationProvider locale={ro} dateAdapter={AdapterDateFns}>
                    <DatePicker
                        views={[ 'year', 'month' ]}
                        label="Anul si luna"
                        openTo={'month'}
                        minDate={new Date('2020-01-01')}
                        maxDate={new Date('2023-12-01')}
                        value={this.state.selectedDate}
                        onChange={(newValue) => {
                            this.setState({ selectedDate: newValue, modalOpen: false, selectedMonthNumber: newValue.getMonth() + 1, selectedMonth: getMonthWithNumber(newValue.getMonth() + 1) }, () => {
                                this.update();
                            });
                        }}
                        open={this.state.modalOpen}
                        renderInput={(params) => { return null; }}
                    />
                </LocalizationProvider>

                {/*<MonthPicker*/}
                {/*    value={this.state.selectedMonthNumber}*/}
                {/*    onDone={(newMonthNumber) => {*/}
                {/*        this.setState({*/}
                {/*            selectedMonthNumber: newMonthNumber[ 0 ],*/}
                {/*            selectedMonth: getMonthWithNumber(newMonthNumber[ 0 ]),*/}
                {/*            modalOpen: false,*/}
                {/*        }, () => {*/}
                {/*            this.update();*/}
                {/*        });*/}
                {/*    }}*/}
                {/*    onClose={() => { this.setState({ modalOpen: false }); }}*/}
                {/*    open={this.state.modalOpen}/>*/}

                <PickerModal
                    open={this.state.selectAffiliateOpen}
                    options={this.state.allAffiliates.map((item) => { return { value: item, label: item }; })}
                    initialSelectedValues={[]}

                    title={'Alege afiliatul'}

                    search={true}
                    searchInDescription={false}

                    minimumChoices={0}
                    maximumChoices={1}

                    onClose={() => { this.setState({ selectAffiliateOpen: false }); }}
                    onDone={(result) => {
                        if (!result || result.length <= 0 || result[ 0 ] === -1) {
                            this.setState({
                                selectedAffiliate: null,
                                selectAffiliateOpen: false,
                            });
                        } else {
                            this.setState({
                                selectedAffiliate: result[ 0 ],
                                selectAffiliateOpen: false,
                            });
                        }
                    }}/>

                <AddSaleAffiliateModal
                    onDone={() => {
                        this.update();
                    }}
                    onClose={() => {
                        this.setState({ addModalOpen: false });
                        this.update();
                    }}
                    open={this.state.addModalOpen}/>

                <FileUploadModal
                    monthNumber={this.state.selectedMonthNumber}
                    onDone={() => {
                        this.setState({ uploadOpen: false });
                        setTimeout(() => {
                            this.update();
                        }, 1000);
                    }}
                    onClose={() => {
                        this.setState({ uploadOpen: false });
                        setTimeout(() => {
                            this.update();
                        }, 1000);
                    }}
                    open={this.state.uploadOpen}/>

            </TablePage>
        );
    }

    onItemClick = (item) => {
        toInvoicePage(item.id);
    };
}

const ListItem = (props) => {
    return (
        <div className={'grid-list-item'} style={{ height: 'fit-content' }}>

            <div className={'inside-card'} style={{ backgroundColor: props.dark ? '#dddddd' : '#eeeeee' }}>

                <div className={'internal-container'} style={{ paddingBottom: 10 }}>

                    <TextFieldDisplay
                        oneLine
                        value={props.item.clientName}
                        icon={'ft-briefcase'}
                        style={{ marginBottom: 4 }}/>

                    <TextFieldDisplay
                        oneLine
                        value={props.item.clientCIF}
                        icon={'ft-hash'}
                        style={{ marginBottom: 4 }}/>

                    <TextFieldDisplay
                        oneLine
                        icon={'ft-dollar-sign'}
                        value={`${ props.item.valueNoVAT }`}
                        style={{ marginBottom: 4 }}/>

                    <TextFieldDisplay
                        oneLine
                        icon={'ft-dollar-sign'}
                        value={`${ props.item.valueWithVAT }`}
                        style={{ marginBottom: 4 }}/>

                    <TextFieldDisplay
                        oneLine
                        value={props.item.affiliateUsername}
                        icon={'ft-users'}
                        style={{ marginBottom: 4 }}/>

                    <TextFieldDisplay
                        oneLine
                        value={prettyDateAndTime(props.item.created)}
                        icon={'ft-calendar'}
                        style={{ marginBottom: 4 }}/>
                </div>

            </div>

        </div>
    );
};

ListItem.propTypes = {
    item: PropTypes.object,
    onPress: PropTypes.func,

    empty: PropTypes.bool,
};

const mapStateToProps = (state) => {
    return {
        user: state.application.user,
    };
};

export default connect(mapStateToProps, false)(withRouter(AffiliateDashboardPage));
