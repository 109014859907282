import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { addAction } from '../../functional/api';
import BaseModal from './model/BaseModal';
import { kToastActionError } from '../general/Toast';
import SingleChoiceField from '../input/SingleChoiceField';
import { kStatusAssigned, kStatusCanceled, kStatusScheduled, kStatusTransition } from '../../functional/status';
import Form from '../input/Form';
import {
    inputValidationSingleChoice,
    inputValidationYesSelected,
    kDateFormatDisplay,
    kDateFormatServer,
} from '../../functional/min-support';
import InternalCalendar from '../calendar/InternalCalendar';
import moment from 'moment';
import TextFieldInput from '../input/TextFieldInput';
import {
    applicationAddSnack,
    applicationCloseLoadingModal,
    applicationOpenLoadingModal,
} from '../../store/application-store';

class Modal extends Component {
    
    static propTypes = {
        fromStatus: PropTypes.string,
        parentKey: PropTypes.number,

        open: PropTypes.bool,
        
        onClose: PropTypes.func,

        toast: PropTypes.func,
        openLoading: PropTypes.func,
        closeLoading: PropTypes.func,
    };
    
    state = {
        actionType: -1,

        operatorId: -1,

        cancelValue: -1,

        formComplete: false,
    
        scheduleDate: new Date(),
        fromDate: moment(new Date()).startOf('month'),
        scheduleTime: '08:00',
    };

    render () {

        return (
            <BaseModal
                title={'Adauga actiune'}
                open={this.props.open}
                doneActive={this.state.formComplete}
                onClose={this.onClose}
                onDone={this.onDone}>

                <div className="w-100">

                    <Form complete={this.state.formComplete} setComplete={(complete) => { this.setState({ formComplete: complete }); }}>

                        <SingleChoiceField
                            required
                            validated={inputValidationSingleChoice(this.state.actionType)}

                            icon={'ft-code'}
                            label={'Tipul actiunii'}
                            options={this.getToStatuses().map((status) => { return { label: status, value: status }; })}
                            value={this.state.actionType}
                            style={{ marginBottom: 4 }}
                            onSelect={(value) => {
                                this.setState({
                                    actionType: value,
                                });
                            }}/>

                        {
                            this.advanced(this.state.actionType).map((item) => { return item; })
                        }

                    </Form>

                </div>

            </BaseModal>
        );
    }

    getToStatuses = () => {
        if (this.props.fromStatus in kStatusTransition) {
            return kStatusTransition[ this.props.fromStatus ];
        } else {
            return kStatusTransition.all;
        }
    };

    advanced = (actionType) => {
        const items = [];
        switch (actionType) {
        case kStatusScheduled:
            items.push(
                <InternalCalendar
                    activeDate={this.state.scheduleDate}
                    startDate={this.state.fromDate}
                    onDayPressed={this.onCalendarDayPressed}/>
            );
            items.push(
                <TextFieldInput
                    icon={'time'}
                    value={this.state.scheduleTime}
                    style={{ marginBottom: 0, maxWidth: 200 }}
                    textStyle={{ fontSize: 16 }}
                    label={'Ora'}
                    time
                    onChange={(newValue) => { this.setState({ scheduleTime: newValue }); }}/>
            );
            break;
        case kStatusAssigned:
            items.push(
                <SingleChoiceField
                    required
                    validated={inputValidationSingleChoice(this.state.operatorId)}

                    parentKey={this.state.clientId}
                    icon={'name'}
                    label={'Atribuie operatorului'}
                    predefinedModal={'operator'}
                    value={this.state.operatorId}
                    style={{ marginBottom: 4 }}
                    onSelect={(value) => {
                        this.setState({
                            operatorId: value,
                        });
                    }}/>
            );
            break;
        case kStatusCanceled:
            items.push(
                <SingleChoiceField
                    required
                    validated={inputValidationYesSelected(this.state.cancelValue)}

                    icon={'warn'}
                    label={'Esti sigur ca doresti anularea solicitarii?'}
                    options={[ { label: 'Da, doresc', value: 'yes' } ]}
                    value={this.state.cancelValue}
                    style={{ marginBottom: 4 }}
                    onSelect={(value) => {
                        this.setState({
                            cancelValue: value,
                        });
                    }}/>
            );
            break;
        }
        return items;
    };

    onClose = () => {
        this.props.onClose();
    };

    onDone = () => {
        if (!this.props.parentKey) {
            return;
        }

        const request = {
            ticketId: this.props.parentKey,
            newStatus: this.state.actionType,
            toStatus: this.state.actionType,
            description: 'Modificare din centrul de control.',
        };
        
        console.log('req stat', request);

        if (request.toStatus == kStatusAssigned) {
            request.assigned = this.state.operatorId;
            console.log('assigned', request.assigned);
        }
        
        if (request.toStatus == kStatusScheduled) {
            request.schedule = moment(this.state.scheduleDate, kDateFormatDisplay).format(kDateFormatServer);
            request.scheduleTime = this.state.scheduleTime;
        }

        this.props.openLoading();

        console.log('request', request);
        addAction(request, (successful) => {
            if (successful) {
                this.clear();
                this.props.toast('Actiune inregistrata cu succes.');
            } else {
                this.props.toast('Operatiune esuata!', kToastActionError);
            }
            this.props.closeLoading();
            this.onClose();
        });
    };

    componentWillReceiveProps (nextProps, nextContext) {
        if (!this.props.open && nextProps.open) {
            this.update();
        }
    }
    
    update = () => {

    };

    clear = () => {
        this.setState({
            actionType: -1,
        });
    };
    
    onCalendarDayPressed = (currentInterval, newDate, direction) => {
        if (!currentInterval) {
            if (direction === 'left' && new moment().isSame(this.state.fromDate, 'month')) {
                return;
            }
            this.onChangeIntervalPressed(direction);
        }
        const today = new moment();
        const newDateMoment = new moment(newDate, kDateFormatDisplay);
        if (newDateMoment.isBefore(today, 'day')) {
            return;
        }
        this.setState({
            scheduleDate: newDate,
        });
    };
    
    onChangeIntervalPressed = (type) => {
        const currentDate = new moment();
        let isCurrentMonth = false;
        
        const currentStart = new moment(this.state.fromDate, kDateFormatDisplay);
        let newStart = new moment(this.state.fromDate, kDateFormatDisplay);
        
        let newEnd = new moment(this.state.toDate, kDateFormatDisplay);
        
        if (type === 'left') {
            newStart = currentStart.subtract(1, 'months');
        } else if (type === 'right') {
            newStart = currentStart.add(1, 'months');
        }
        
        isCurrentMonth = currentDate.isSame(newStart, 'month');
        
        if (true || !isCurrentMonth) { // whole month
            newEnd = new moment(newStart);
            newEnd = newEnd.add(1, 'months').subtract(1, 'days');
        } else {
            newEnd = new moment(currentDate);
        }
        
        this.setState({
            fromDate: newStart.format(kDateFormatDisplay),
            toDate: newEnd.format(kDateFormatDisplay),
            scheduleDate: isCurrentMonth ? currentDate.format(kDateFormatDisplay) : newStart.format(kDateFormatDisplay),
        });
    };

}

const mapDispatchToProps = (dispatch) => {
    return {
        toast: (label, action) => { return dispatch(applicationAddSnack({ label, action })); },

        openLoading: () => { return dispatch(applicationOpenLoadingModal()); },
        closeLoading: () => { return dispatch(applicationCloseLoadingModal()); },
    };
};

export default connect(null, mapDispatchToProps)(Modal);