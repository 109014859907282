import React, { Component } from 'react';
import { withRouter, Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SimpleButton from '../input/SimpleButton';
import routes from '../../functional/routes';

class InternalToolbar extends Component {
    
    static propTypes = {
        search: PropTypes.bool,
        onSearchChange: PropTypes.func,
        searchValue: PropTypes.string,
        
        refresh: PropTypes.bool,
        onRefresh: PropTypes.func,
        
        actions: PropTypes.array,
        labels: PropTypes.array,

        user: PropTypes.object,
        onBack: PropTypes.func,
        history: PropTypes.object,
    };

    static defaultState = {
        showLabels: false,
    };

    state = InternalToolbar.defaultState;

    generalUpdate = () => {
        this.setState(InternalToolbar.defaultState);
    };

    constructor (props) {
        super(props);
        this.searchInput = React.createRef();
    }

    componentDidMount () {
        if (this.props.search) {
            // This.searchInput.current.focus();
        }
    }

    // searchInRoutes = (received, level) => {
    //     const found = routes[ level ].filter((route) => { return route.id === received; });
    //     if (found.length > 0) {
    //         return found[ 0 ];
    //     }
    //     return null;
    // };

    // contextualPage = (lastRoute, position) => {
    //     if (position === 1) {
    //         return this.props.itemPageTitle;
    //     }
    //     return 'Necunoscut';
    // };

    /*

    <div className="rt_subheader_main">
                            <div className="rt_breadcrumb">
                                <Link to="/dashboard" className="rt_breadcrumb_home_icon"><span className={'breadcrumb_link'} style={{ paddingRight: 4 }}>{'Centru'}</span><i className="feather ft-home"/></Link>
                                {
                                    breadcrumbs.map((breadcrumb) => {
                                        return (
                                            <>
                                                <span className="rt_breadcrumb_separator"><i className="feather ft-chevrons-right"/></span>
                                                <Link to={breadcrumb.path} className="breadcrumb_link pl-4">{breadcrumb.title}</Link>
                                            </>
                                        );
                                    })
                                }
                            </div>
                        </div>

                        */

    render () {

        // const currentPathname = this.props.history.location.pathname;
        // const routesLocal = currentPathname.split('/').filter((token) => { return token.length !== 0; });
        // let combinedRoute = '';
        // let lastRoute = null;
        // const breadcrumbs = routesLocal.map((route, position) => {
        //     combinedRoute += `/${ route }`;
        //     const foundRoute = this.searchInRoutes(route, this.props.user.level);
        //     const title = foundRoute ? foundRoute.title : currentPathname === '/dashboard' ? 'Centrul principal' : this.contextualPage(lastRoute, position);
        //     lastRoute = foundRoute;
        //     return {
        //         title,
        //         path: combinedRoute,
        //     };
        // });
    
        return (
            <div className={'internal-toolbar'}>
                <div className={'internal-toolbar-container'}>
                    <div className={'internal-toolbar-left-container'}>
                        
                        {
                            this.props.backButton &&
                            <SimpleButton value={'Inapoi'} icon={'ft-arrow-left'} onPress={this.onBack}/>
                        }
    
                        {
                            this.props.refresh &&
                            <SimpleButton value={'Reincarca'} icon={'ft-refresh-ccw'} onPress={this.props.onRefresh}/>
                        }

                        {
                            this.props.labels && !this.state.showLabels &&
                            <SimpleButton value={'Vezi filtre'} icon={'ft-sliders'} onPress={() => { this.setState({ showLabels: true }); }}/>
                        }

                        {
                            this.props.labels && this.state.showLabels &&
                            <SimpleButton value={'Ascunde filtre'} icon={'ft-sliders'} onPress={() => { this.setState({ showLabels: false }); }}/>
                        }
                    
                        {
                            this.props.search &&
                            <input
                                ref={this.searchInput}
                                placeholder={'Cauta'}
                                value={this.props.searchValue}
                                className={'toolbar-search-input'}
                                onChange={(event) => {
                                    if (this.props.onSearchChange) {
                                        this.props.onSearchChange(event.target.value);
                                    }
                                }}/>
                        }
                        
                    </div>
                
                    <div className="internal-toolbar-right-container">
                        {
                            this.props.actions && this.props.actions.map((action, actionKey) => {
                                return (
                                    <SimpleButton key={action.id} value={action.title} onPress={action.onPress} style={action.style} icon={action.icon ? action.icon.title : null} color={action.icon ? action.icon.color : null} size={action.icon ? action.icon.size : null}/>
                                );
                            })
                        }
                
                    </div>
            
                </div>
                
                {
                    this.props.labels && this.state.showLabels &&
                    <div className={'internal-toolbar-filter-text-display'}>
                        {
                            this.props.labels.map((label, labelKey) => {
                                return (
                                    <React.Fragment key={labelKey}>
                                        {
                                            label.connection &&
                                            <div className={'internal-toolbar-filter-text-description'}>
                                                {label.connection}
                                            </div>
                                        }
                    
                                        {
                                            label.tags && label.tags.map((tag, tagKey) => {
                                                return (
                                                    <div key={`${ labelKey }-${ tagKey }`} className={'internal-toolbar-filter-text-entry'}>
                                                        {tag}
                                                    </div>
                                                );
                                            })
                                        }
                                    </React.Fragment>
                                );
                            })
                        }
                    </div>
                }
            </div>
        );
    }

    onBack = () => {
        this.props.history.goBack();
    };
    
}

const mapStateToProps = (state) => {
    return {
        drawerOpen: state.application.drawerOpen,
        itemPageTitle: state.application.itemPageTitle,
        user: state.application.user,
    };
};

export default connect(mapStateToProps, null)(withRouter(InternalToolbar));