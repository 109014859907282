import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getAllGlobalProducts } from '../functional/api';
import { withRouter } from 'react-router-dom';
import { kFilterStockFields, kFilterStockFieldsDefault } from '../functional/constants';
import TextFieldDisplay from '../components/input/TextFieldDisplay';
import ListPage from '../layout/page/ListPage';
import PickerModal from '../components/modal/model/PickerModal';
import ImageFieldDisplay from '../components/input/ImageFieldDisplay';
import AddStockModal from '../components/modal/AddStockModal';

class StockPage extends Component {
    
    static propTypes = {
        history: PropTypes.object,
    };
    
    static defaultState = {
        items: null,
        modalOpen: false,
    
        suppliers: [],
        supplierLabels: [],
        operators: [],
        operatorLabels: [],
        search: '',
        sortType: 'Data adaugarii',
    
        loading: false,
    };
    
    state = StockPage.defaultState;
    
    componentDidMount () {
        this.update();
    }
    
    retrieveInitial = (operators) => {
        getAllGlobalProducts((successful, data) => {
            if (successful) {
                this.setState({
                    items: data,
                    loading: false,
                });
            } else {
                this.setState({
                    loading: false,
                });
            }
        });
    };
    
    generalUpdate = () => {
        this.setState(StockPage.defaultState, this.update);
    };
    
    update = (operators = null) => {
        this.setState({
            items: null,
            loading: true,
        });
        if (!operators) {
            operators = this.state.operators;
        }
        this.retrieveInitial(operators);
    };
    
    render () {
        const list = this.state.items;
        const toolbarActions = [
            {
                title: 'Ordonare',
                icon: { title: 'ft-chevrons-down' },
                onPress: this.openSortModal,
            },
            {
                title: 'Adauga produs',
                icon: { title: 'ft-plus' },
                onPress: () => {
                    this.setState({
                        modalOpen: true,
                    });
                },
            },
        ];
        const toolbarLabels = [
            {
                connection: 'ordonate dupa: ',
                tags: [ this.getSecondLabels(this.state.sortType) ],
            },
        ];
        return (
            <ListPage
                onRefresh={this.generalUpdate}
                grid
                deepSearch={false}
                loading={this.state.items === null}
                actions={toolbarActions}
                labels={toolbarLabels}
                allListItems={list}
                renderListItem={(item, dark) => {
                    return (
                        <ListItem
                            key={item.id}
                            item={item}
                            dark={dark}
                            onPress={this.onItemClick}/>
                    );
                }}
                defaultFields={kFilterStockFieldsDefault}
                allFields={kFilterStockFields}
                
                additionalActions={[ 'Ordonare' ]}
                onAdditionalActions={[ this.openSortModal ]}
                additionalLabels={[ this.getSecondLabels([ this.state.sortType ]) ]}
                connectionText={[ 'ordonare dupa:' ]}>
            
                <AddStockModal
                    open={this.state.modalOpen}
                    onClose={() => {
                        this.setState({
                            modalOpen: false,
                        });
                        this.update();
                    }}/>
    
                <PickerModal
                    title={'Alege modul de ordonare'}
                    open={this.state.sortModal}
                    options={[ { label: 'Data adaugarii', value: 'Data adaugarii' }, { label: 'Numarul de bucati', value: 'Numarul de bucati' } ]}
                    initialSelectedValues={[ this.state.sortType ]}
                    minimumChoices={1}
                    maximumChoices={1}
                    onClose={this.closeSortModal}
                    onDone={(newValues) => { this.setState({ sortModal: false, sortType: newValues[ 0 ] }, () => { this.update(); }); }}/>
        
            </ListPage>
        );
    }
    
    onItemClick = (item) => {
        this.props.history.push(`/stock/${ item._id }`);
    };
    
    getSecondLabels = (list) => {
        if (list && list.length > 0) {
            return list;
        } else {
            return [ 'Toti' ];
        }
    };
    
    openSortModal = () => {
        this.setState({
            sortModal: true,
        });
    };
    
    
    closeSortModal = () => {
        this.setState({
            sortModal: false,
        });
    };
    
}

const ListItem = (props) => {
    let imageSource = null;
    if (props.item.pictureO) {
        imageSource = props.item.pictureO;
    }
    
    return (
        <div className={'grid-list-item'} style={{ height: 'fit-content' }}>
        
            <div className={'inside-card'} style={{ backgroundColor: props.dark ? '#dddddd' : '#eeeeee' }}>
    
                <div style={{ width: '100%', height: 300 }}>
                    <ImageFieldDisplay value={imageSource} full={props.item.pictureO} imageStyle={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}/>
                </div>
                
                <div className={'internal-container'}>
    
                    <TextFieldDisplay
                        oneLine
                        value={props.item.title}
                        style={{ marginBottom: 4 }}/>
    
                    <TextFieldDisplay
                        oneLine
                        label={'Clasa produsului'}
                        icon={'ft-folder'}
                        value={props.item.class}
                        style={{ marginBottom: 4 }}/>
                    
                    <TextFieldDisplay
                        oneLine
                        label={'Descrierea produsului'}
                        icon={'ft-info'}
                        value={props.item.description}
                        style={{ marginBottom: 4 }}/>
    
                    <TextFieldDisplay
                        oneLine
                        label={'Modelul produsului'}
                        icon={'ft-cpu'}
                        value={props.item.model}
                        style={{ marginBottom: 4 }}/>
                    
                    <TextFieldDisplay
                        oneLine
                        label={'Numarul in stoc'}
                        value={`${ props.item.stock ? props.item.stock : 0 } ${ props.item.counter }`}
                        icon={'ft-layers'}
                        style={{ marginBottom: 4 }}/>
                        
                </div>
            
                <div className={'list-item-section-with-button'} style={{ flex: 1 }}>
                
                    <div className={'item-action-button'}>
                        <div onClick={() => { props.onPress(props.item); }} className={'item-action-button-inside'}>
                            {'Vezi produsul'}
                        </div>
                    </div>
            
                </div>
        
            </div>
    
        </div>
    );
};

ListItem.propTypes = {
    item: PropTypes.object,
    onPress: PropTypes.func,

    empty: PropTypes.bool,
};

export default withRouter(StockPage);