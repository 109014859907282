import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getProduct } from '../functional/api';
import InternalToolbar from '../components/general/InternalToolbar';
import TextFieldDisplay from '../components/input/TextFieldDisplay';
import ImageFieldDisplay from '../components/input/ImageFieldDisplay';
import TimelineList from '../components/lists/TimelineList';
import { filter, prettyDateAndTime } from '../functional/min-support';
import AddStockActionModal from '../components/modal/AddStockActionModal';
import { applicationOpenUpdateModal, applicationSetItemPageTitle } from '../store/application-store';

class StockItemPage extends Component {

    static propTypes = {
        history: PropTypes.object,
    };

    static defaultState = {
        search: '',
        item: null,

        modalOpen: false,
        changePictureModal: false,
    };

    state = StockItemPage.defaultState;

    componentWillReceiveProps (nextProps, nextContext) {
        if (this.props.updateModal && !nextProps.updateModal) {
            this.update();
        }
    }

    componentDidMount () {
        this.props.onSetItemPageTitle();
        this.update();
    }

    generalUpdate = () => {
        this.setState(StockItemPage.defaultState, this.update);
    };

    update = () => {
        const key = this.props.match.params.key;
        getProduct(key, (successful, data) => {
            if (successful) {
                this.setState({
                    item: data,
                });
                this.props.onSetItemPageTitle(data.title);
            }
        });
    };

    render () {
        let translatedActions = this.state.item && this.state.item.history ? this.state.item.history : [];
        const toolbarActions = [
            {
                title: 'Schimba poza',
                onPress: () => { this.setState({ changePictureModal: true }); },
                icon: { title: 'ft-image' },
            },
            {
                title: 'Adauga actiune',
                onPress: () => { this.setState({ modalOpen: true }); },
                icon: { title: 'ft-plus' },
            },
        ];
        const toolbarLabels = [
            {
                connection: 'schimbarile dupa:',
                tags: [ 'Numele operatorului', 'Numele sursei', 'Numele destinatiei' ],
            },
        ];
        if (translatedActions && this.state.search.length > 0) {
            translatedActions = filter(translatedActions, [ 'search_poster_name', 'search_from_name', 'search_to_name' ], this.state.search, false);
        }
        return (
            <React.Fragment>
                <InternalToolbar
                    backButton
                    refresh
                    onRefresh={this.generalUpdate}
                    search
                    searchValue={this.state.search}
                    onSearchChange={(newValue) => { this.setState({ search: newValue }); }}
                    actions={toolbarActions}
                    labels={toolbarLabels}/>

                <div className="w-100 h-100 overflow-hidden d-flex flex-row">

                    <div className="h-100 overflow-scroll" style={{ flex: 1, padding: 20 }}>
                        {
                            this.state.item &&
                            <React.Fragment>
                                <div className={'general-card'}>
                                    <TextFieldDisplay
                                        title
                                        value={this.state.item.title}
                                        style={{ marginBottom: 4 }}
                                        action={'ft-edit'}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item.id,
                                                this.state.item.title,
                                                'stock',
                                                'title'
                                            );
                                        }}/>

                                    <TextFieldDisplay
                                        label={'descrierea produsului'}
                                        icon={'ft-info'}
                                        value={this.state.item.description}
                                        style={{ marginBottom: 4 }}
                                        action={'ft-edit'}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item.id,
                                                this.state.item.description,
                                                'stock',
                                                'description'
                                            );
                                        }}/>

                                    <TextFieldDisplay
                                        label={'clasa produsului'}
                                        icon={'ft-folder'}
                                        value={this.state.item.class}
                                        style={{ marginBottom: 4 }}
                                        action={'ft-edit'}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item.id,
                                                this.state.item.class,
                                                'stock',
                                                'class'
                                            );
                                        }}/>

                                    <TextFieldDisplay
                                        label={'modelul produsului'}
                                        value={this.state.item.model}
                                        icon={'ft-cpu'}
                                        action={'ft-edit'}
                                        style={{ marginBottom: 4 }}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item.id,
                                                this.state.item.model,
                                                'stock',
                                                'model'
                                            );
                                        }}/>

                                    <TextFieldDisplay
                                        label={'pretul produsului'}
                                        value={`${ this.state.item.price }`}
                                        icon={'ft-dollar-sign'}
                                        action={'ft-edit'}
                                        style={{ marginBottom: 4 }}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item.id,
                                                this.state.item.price,
                                                'stock',
                                                'price'
                                            );
                                        }}/>

                                    <TextFieldDisplay
                                        label={'tipul cantitatii'}
                                        value={this.state.item.counter}
                                        icon={'ft-droplet'}
                                        style={{ marginBottom: 4 }}/>

                                    <TextFieldDisplay
                                        label={'stocul tau'}
                                        value={`${ this.state.item.stock } ${ this.state.item.counter }`}
                                        icon={'ft-layers'}
                                        style={{ marginBottom: 4 }}/>

                                </div>
                            </React.Fragment>
                        }

                        {
                            !this.state.item &&
                            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                                <div className="spinner_loader secondary_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            </div>
                        }
                    </div>

                    <div className="h-100 overflow-scroll" style={{ width: '30%', padding: 20, paddingLeft: 0 }}>

                        {
                            this.state.item &&
                            <React.Fragment>
                                <div className={'general-card'} style={{ padding: 0, height: 400 }}>
                                    <ImageFieldDisplay
                                        value={this.state.item.pictureO}
                                        full={this.state.item.pictureO}/>
                                </div>
                                <TimelineList
                                    items={translatedActions}
                                    renderItem={(item) => {
                                        return (
                                            <div className="general-card card-small-padding" style={{ paddingLeft: 20 }}>
                                                <TextFieldDisplay
                                                    style={{ marginBottom: 4 }}
                                                    value={item.description}
                                                    icon={'ft-file-text'}/>

                                                <TextFieldDisplay
                                                    style={{ marginBottom: 4 }}
                                                    value={prettyDateAndTime(item.created)}
                                                    icon={'ft-clock'}/>

                                                <TextFieldDisplay
                                                    value={`numar bucati: ${ item.variation }`}
                                                    icon={'ft-layers'}
                                                    style={{ marginBottom: 4 }}/>

                                                {
                                                    item.creator &&
                                                    <TextFieldDisplay
                                                        label={'inregistrat de'}
                                                        value={item.creator.fullName}
                                                        icon={'ft-users'}/>
                                                }
                                            </div>
                                        );
                                    }}/>
                            </React.Fragment>
                        }

                        {
                            !this.state.item &&
                            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                                <div className="spinner_loader secondary_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            </div>
                        }

                    </div>
                </div>

                {
                    this.state.item &&

                    <AddStockActionModal
                        parentKey={this.state.item._id}
                        open={this.state.modalOpen}
                        onClose={() => {
                            this.setState({
                                modalOpen: false,
                            });
                            this.update();
                        }}/>

                }

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.application.user,
        updateModal: state.application.modals.updateModal,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetItemPageTitle: (value) => { return dispatch(applicationSetItemPageTitle(value)); },
        openUpdateModal: (id, previous, type, on) => { return dispatch(applicationOpenUpdateModal({ id, previous, type, on })); },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StockItemPage));
