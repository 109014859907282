import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { renderIf, smallIcon } from '../../functional/min-support';

class TextFieldInput extends Component {

    static propTypes = {
        value: PropTypes.string,
        icon: PropTypes.string,
        placeholder: PropTypes.string,

        onChange: PropTypes.func,

        title: PropTypes.bool,

        label: PropTypes.string,

        style: PropTypes.object,

        required: PropTypes.bool,
        validated: PropTypes.bool,
        requireCompletion: PropTypes.bool,

        textStyle: PropTypes.object,
        labelStyle: PropTypes.object,

        secure: PropTypes.bool,

        bangText: PropTypes.string,
        bangOnPress: PropTypes.func,
        bangStyle: PropTypes.object,

        locked: PropTypes.bool,
        onPress: PropTypes.func,
    };

    constructor (props) {
        super(props);
        this.textareaRef = React.createRef();
    }

    render () {
        let action = null;
        let actionColor = '#00000033';
        let labelStyle = {};
        let style = {};
        let textStyle = {};
        const valueContainerStyle = {};
        let type = '';
        let bangStyle = {};
        let readOnly = false;
        let classname = 'text-field-input';

        if (this.props.classname) {
            classname = `${ classname } ${ this.props.classname }`;
        }

        if (this.props.locked) {
            readOnly = true;
        }

        if (this.props.bangStyle) {
            bangStyle = this.props.bangStyle;
        }

        if (this.props.labelStyle) {
            labelStyle = this.props.labelStyle;
        }

        if (this.props.textStyle) {
            textStyle = this.props.textStyle;
        }

        if (this.props.secure) {
            type = 'password';
        }

        if (this.props.time) {
            type = 'time';
        }

        if (!this.props.value || this.props.value.length === 0) {
            labelStyle.height = 0;
            labelStyle.opacity = 0;
            labelStyle.padding = 0;
            valueContainerStyle.paddingTop = '8px';
        }

        if (this.props.required) {
            action = 'ft-x';
            actionColor = '#66262888';
            if (this.props.validated) {
                action = 'ft-check';
                actionColor = '#0f4a4788';
            }
        }

        if (this.props.style) style = { ...this.props.style };
        if (!this.props.label) valueContainerStyle.paddingTop = '8px';

        if (this.props.iconAtStart) style.alignItems = 'flex-start';

        return (
            <div className={classname} style={style} onClick={this.onClick}>

                {
                    renderIf(this.props.icon,
                        <div className={'text-field-icon'} style={{ paddingTop: this.props.iconAtStart ? 12 : 0 }}>
                            {
                                smallIcon(this.props.icon, this.props.iconColor ? this.props.iconColor : null, 12)
                            }
                        </div>)
                }

                <div className={'text-field-content'}>

                    {
                        renderIf(this.props.label,
                            <div className={'text-field-label'} style={labelStyle}>
                                {
                                    this.label
                                }
                            </div>)
                    }

                    <div className={'text-field-value-container'} style={valueContainerStyle}>

                        {
                            this.props.textarea &&
                            <textarea
                                ref={this.textareaRef}
                                className={'text-field-input-value'}
                                placeholder={this.placeholder}
                                onKeyDown={this.props.onKeyDown ? this.props.onKeyDown : null}
                                onChange={this.onChange}
                                value={this.props.value}
                                readOnly={readOnly}
                                rows={this.props.rows ? this.props.rows : 4}
                                style={{ ...textStyle, flex: 1 }}/>
                        }

                        {
                            !this.props.textarea &&
                            <input
                                className={'text-field-input-value'}
                                placeholder={this.placeholder}
                                onKeyDown={this.props.onKeyDown ? this.props.onKeyDown : null}
                                onChange={this.onChange}
                                value={this.props.value}
                                type={type}
                                readOnly={readOnly}
                                style={textStyle}/>
                        }

                        {
                            renderIf(action,
                                <div className={'text-field-action-icon'}>
                                    {
                                        smallIcon(action, actionColor)
                                    }
                                </div>)
                        }

                    </div>

                </div>

                {
                    renderIf(this.props.bangText,
                        <div className={'text-field-action-button'} onClick={this.props.bangOnPress} style={bangStyle}>
                            {
                                this.props.bangText
                            }
                        </div>)
                }

            </div>
        );
    }

    onClick = () => {
        if (this.props.onPress) {
            this.props.onPress();
        }
    };

    get label () {
        if (this.props.label) {
            return this.props.label; // .toLowerCase();
        }
        return '';
    }

    get placeholder () {
        if (this.props.label) {
            return this.props.label;
        }
        return '';
    }

    onChange = (newValue) => {
        if (this.props.onChange) {
            if (this.textareaRef.current) {
                this.textareaRef.current.style.height = 'auto';
                this.textareaRef.current.style.height = `${ this.textareaRef.current.scrollHeight }px`;
            }
            this.props.onChange(newValue.target.value);
        }
    };

}

export default TextFieldInput;
