/**
 * @label Stable Imports
 */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

/**
 * @label Functionality Imports
 */

import {
    applicationClosePDFViewModal,
    applicationCloseUpdateModal,
    applicationModalsSelector,
} from '../../store/application-store';

/**
 * @label Component Imports
 */

import UpdateModal from './UpdateModal';
import LoadingModal from './LoadingModal';
import PDFViewModal from './PDFViewModal';

/**
 * Contains the global modals of the application.
 * @returns {JSX.Element}
 */

const Modals = (props) => {

    /**
     * @label Hooks : Start
     */

    const dispatch = useDispatch();

    /**
     * @label Hooks : End
     */

    /**
     * @label Store : Start
     */

    const modals = useSelector(applicationModalsSelector);

    /**
     * @label Store : End
     */


    /**
     * @label Render
     */

    console.log('modals', modals);

    return (
        <>
            <UpdateModal open={modals.updateModal} onClose={() => { dispatch(applicationCloseUpdateModal()); }}/>
            <LoadingModal open={modals.loadingModal}/>

            <PDFViewModal open={modals.pdfViewModal} onClose={() => { dispatch(applicationClosePDFViewModal()); }}/>
        </>
    );
};

export default Modals;
