/**
 * @label Stable Imports
 */

import React from 'react';
import PropTypes from 'prop-types';

/**
 * @label Component Imports
 */

import BaseModal from './model/BaseModal';
import LoadingComponent from '../general/LoadingAnimation';

/**
 * Loading Modal.
 * @returns {JSX.Element}
 */

const LoadingModal = (props) => {

    /**
     * @label Render
     */

    return (
        <BaseModal
            title={''}
            open={props.open}
            hideDone
            hideClose
            onClose={() => {}}
            doneActive={false}>

            <div className={'w-100 h-100'} style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>

                <LoadingComponent extended />

            </div>

        </BaseModal>
    );
};

/**
 * @label PropTypes
 */

LoadingModal.propTypes = {
    open: PropTypes.bool.isRequired,
};

export default LoadingModal;