import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


import { addClient, addSaleAffiliate } from '../../functional/api';
import BaseModal from './model/BaseModal';
import TextFieldInput from '../input/TextFieldInput';
import { kToastActionError } from '../general/Toast';
import { currentMonthNumber, inputValidationEmail, inputValidationNonEmpty } from '../../functional/min-support';
import Form from '../input/Form';
import {
    applicationAddSnack,
    applicationCloseLoadingModal,
    applicationOpenLoadingModal,
} from '../../store/application-store';

class Modal extends Component {

    static propTypes = {

        open: PropTypes.bool,

        onClose: PropTypes.func,

        toast: PropTypes.func,
        openLoading: PropTypes.func,
        closeLoading: PropTypes.func,
    };

    state = {
        title: '',
        cid: '',
        tri: '',
        bankTitle: '',
        bankAccount: '',
        address: '',
        contactPhone: '',

        valueNoVAT: '',
        valueWithVAT: '',
        username: '',


        emailAddress: '',
        emailTitle: '',

        formComplete: false,
    };

    render () {
        return (
            <BaseModal
                stableHeight
                title={'Adauga sumar vanzari'}
                open={this.props.open}
                doneActive={this.state.formComplete}
                onClose={this.onClose}
                onDone={this.onDone}>

                <div className={'w-100'}>

                    <Form complete={this.state.formComplete} setComplete={(complete) => { this.setState({ formComplete: complete }); }}>

                        <TextFieldInput
                            title
                            required
                            validated={inputValidationNonEmpty(this.state.title)}

                            value={this.state.title}
                            style={{ marginBottom: 4 }}
                            label={'Nume firma client'}
                            onChange={(newValue) => {
                                this.setState({
                                    title: newValue,
                                });
                            }}/>

                        <TextFieldInput
                            icon={'ft-info'}
                            validated={inputValidationNonEmpty(this.state.cid)}
                            value={this.state.cid}
                            style={{ marginBottom: 4 }}
                            label={'Cod identificare fiscala'}
                            onChange={(newValue) => {
                                this.setState({
                                    cid: newValue,
                                });
                            }}/>

                        <TextFieldInput
                            validated={inputValidationNonEmpty(this.state.valueNoVAT)}
                            icon={'ft-dollar-sign'}
                            value={this.state.valueNoVAT}
                            style={{ marginBottom: 4 }}
                            label={'Valoare fara TVA'}
                            onChange={(newValue) => {
                                this.setState({
                                    valueNoVAT: newValue,
                                });
                            }}/>

                        <TextFieldInput
                            validated={inputValidationNonEmpty(this.state.valueWithVAT)}
                            icon={'ft-dollar-sign'}
                            value={this.state.valueWithVAT}
                            style={{ marginBottom: 4 }}
                            label={'Valoare cu TVA'}
                            onChange={(newValue) => {
                                this.setState({
                                    valueWithVAT: newValue,
                                });
                            }}/>

                        <TextFieldInput
                            icon={'ft-user'}
                            validated={inputValidationNonEmpty(this.state.username)}
                            value={this.state.username}
                            style={{ marginBottom: 4 }}
                            label={'Nume utilizator'}
                            onChange={(newValue) => {
                                this.setState({
                                    username: newValue,
                                });
                            }}/>

                    </Form>

                </div>

            </BaseModal>
        );
    }

    onClose = () => {
        this.props.onClose();
    };

    onDone = () => {
        const request = {
            clientName: this.state.title,
            clientCIF: this.state.cid,
            monthNumber: currentMonthNumber(),
            valueNoVAT: this.state.valueNoVAT,
            valueWithVAT: this.state.valueWithVAT,
            affiliateUsername: this.state.username,
        };

        this.props.openLoading();

        addSaleAffiliate(request, (successful) => {
            if (successful) {
                this.clear();
                this.props.toast('Sumar inregistrat cu succes.');
            } else {
                this.props.toast('Operatiune esuata!', kToastActionError);
            }
            this.props.closeLoading();
            this.onClose();
        });
    };

    componentWillReceiveProps (nextProps, nextContext) {
        if (!this.props.open && nextProps.open) {
            this.update();
        }
    }

    update = () => {

    };

    clear = () => {
        this.setState({
            title: '',
            cid: '',
            tri: '',
            bankTitle: '',
            bankAccount: '',
            address: '',
            contactPhone: '',

            valueNoVAT: '',
            valueWithVAT: '',
            username: '',


            emailAddress: '',
            emailTitle: '',
            formComplete: false,
        });
    };

}

const mapStateToProps = (state) => {
    return {
        user: state.application.user,

        updateModal: state.application.modals.updateModal,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toast: (label, action) => { return dispatch(applicationAddSnack({ label, action })); },

        openLoading: () => { return dispatch(applicationOpenLoadingModal()); },
        closeLoading: () => { return dispatch(applicationCloseLoadingModal()); },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);