import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getAllSales } from '../functional/api';
import { withRouter } from 'react-router-dom';
import { kFilterSaleFields, kFilterSaleFieldsDefault } from '../functional/constants';
import ListPage from '../layout/page/ListPage';
import TextFieldDisplay from '../components/input/TextFieldDisplay';
import AddSaleModal from '../components/modal/AddSaleModal';
import { connect } from 'react-redux';
import { prettyDateAndTime } from '../functional/min-support';
import { toSalePage } from '../functional/navigation';

class SalesPage extends Component {
    
    static propTypes = {
        history: PropTypes.object,
    };
    
    static defaultState = {
        items: null,
        modalOpen: false,
    };
    
    state = SalesPage.defaultState;
    
    componentDidMount () {
        this.update();
    }
    
    generalUpdate = () => {
        this.setState(SalesPage.defaultState, this.update);
    };
    
    update = () => {
        getAllSales((successful, data) => {
            if (successful) {
                this.setState({
                    items: data,
                });
            } else {
                console.error('no clients');
            }
        });
    };
    
    render () {
        return (
            <ListPage
                onRefresh={this.generalUpdate}
                grid
                deepSearch={false}
                loading={this.state.items === null}
                actions={[
                    {
                        title: 'Adauga aviz',
                        icon: { title: 'ft-plus' },
                        onPress: () => {
                            this.setState({
                                modalOpen: true,
                            });
                        },
                    },
                ]}
                allListItems={this.state.items}
                renderListItem={(item, dark) => {
                    return (
                        <ListItem
                            dark={dark}
                            key={item.id}
                            item={item}
                            onPress={this.onItemClick}/>
                    );
                }}
                defaultFields={kFilterSaleFieldsDefault}
                allFields={kFilterSaleFields}>

                <AddSaleModal
                    open={this.state.modalOpen}
                    onClose={() => {
                        this.setState({
                            modalOpen: false,
                        });
                        this.update();
                    }}/>

            </ListPage>
        );
    }
    
    onItemClick = (item) => {
        toSalePage(item.id);
    };
}

const ListItem = (props) => {
    return (
        <div className={'grid-list-item'} style={{ height: 'fit-content' }}>

            <div className={'inside-card'} style={{ backgroundColor: props.dark ? '#dddddd' : '#eeeeee' }}>
                
                <div className={'internal-container'}>
                    <TextFieldDisplay
                        oneLine
                        value={props.item.name}
                        style={{ marginBottom: 4 }}/>
    
                    <TextFieldDisplay
                        oneLine
                        value={prettyDateAndTime(props.item.created)}
                        icon={'ft-calendar'}
                        style={{ marginBottom: 4 }}/>
    
                    <TextFieldDisplay
                        oneLine
                        value={props.item.client_name}
                        icon={'ft-briefcase'}
                        style={{ marginBottom: 4 }}/>
        
                    <TextFieldDisplay
                        oneLine
                        value={props.item.username}
                        icon={'ft-users'}
                        style={{ marginBottom: 4 }}/>
                        
                </div>
    
                <div className={'item-action-button'}>
                    <div onClick={() => { props.onPress(props.item); }} className={'item-action-button-inside'}>
                        {'Vezi avizul'}
                    </div>
                </div>
                
            </div>

        </div>
    );
};

ListItem.propTypes = {
    item: PropTypes.object,
    onPress: PropTypes.func,

    empty: PropTypes.bool,
};

const mapStateToProps = (state) => {
    return {
        user: state.application.user,
    };
};

export default connect(mapStateToProps, false)(withRouter(SalesPage));