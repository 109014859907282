import React from 'react';
import { colorByStatus } from '../../functional/status';
import TextFieldDisplay from '../input/TextFieldDisplay';
import { prettyDateAndTime } from '../../functional/min-support';
import { toClientPage } from '../../functional/navigation';
import TimelineList from '../lists/TimelineList';
import { ticketActionAdvancedFields } from '../../pages/TicketPage';
import { connect } from 'react-redux';
import { applicationOpenPDFViewModal } from '../../store/application-store';


class ListItem extends React.Component {
    
    render () {
        const props = this.props;
        const overhaul = false;
        const addStyle = { alignItems: 'stretch' };

        //let actions = translateTicketActionList(props.item.actions);
        const actions = props.item.history;
        const style = {};
        if (this.props.active) {
            style.backgroundColor = '#363f5a33';
        }

        // console.log('ticket', props.item);

        return (
            <div className={'short-list-item'} style={style} onClick={() => { this.props.onPress(this.props.item); }}>
                <div className={'short-list-item-container'}>
                    <div className={'short-list-item-container-inner'}>
                        <div style={{ flex: 2, width: '40%' }}>
                            <TextFieldDisplay
                                noBorders
                                noHover
                                oneLine
                                value={props.item.title}
                                icon={props.item.debug ? 'ft-alert-triangle' : this.props.active ? 'ft-check-square' : 'ft-square'}
                                style={{ flex: 1 }}/>
                        </div>

                        <div style={{ flex: 2, width: '40%' }}>
                            <TextFieldDisplay
                                noBorders
                                noHover
                                oneLine
                                value={props.item.description}
                                icon={'ft-align-justify'}
                                style={{ flex: 1 }}/>
                        </div>

                        <div style={{ flex: 1, width: '20%' }}>
                            <TextFieldDisplay
                                noBorders
                                noHover
                                oneLine
                                value={props.item.status}
                                icon={'ft-code'}
                                textBackgroundColor={colorByStatus(props.item.status)}
                                textColor={'#ffffff'}/>
                        </div>
                    </div>

                    <div className={'short-list-item-container-inner'}>

                        <div style={{ flex: 2 }}>
                            <TextFieldDisplay
                                noBorders
                                noHover
                                oneLine
                                value={props.item.assignedDetails ? props.item.assignedDetails.fullName : props.item.creatorInfo.fullName}
                                icon={'ft-user'}
                                style={{ flex: 1 }}/>
                        </div>

                        <div style={{ flex: 2 }}>
                            <TextFieldDisplay
                                noBorders
                                noHover
                                oneLine
                                value={prettyDateAndTime(props.item.created)}
                                icon={'ft-clock'}
                                style={{ flex: 1 }}/>
                        </div>


                        <div style={{ flex: 1 }}>
                            <TextFieldDisplay
                                noBorders
                                noHover
                                oneLine
                                value={' '}
                                icon={'ft-blank'}/>
                        </div>
                    </div>
                </div>
            </div>
        );

        // <div style={{ flex: 2 }}>
        //     <TextFieldDisplay
        //         noBorders
        //         noHover
        //         oneLine
        //         value={props.item.clientInfo.title}
        //         icon={'ft-briefcase'}/>
        // </div>
        
        return (
            <div className={'sectioned-list-item'}>
                
                <div className="sectioned-list-item-card" style={addStyle}>
                    
                    <div className={'list-item-section'}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 8 }}>
                            <TextFieldDisplay
                                oneLine
                                value={props.item.title}
                                style={{ flex: 1 }}/>
                        </div>
    
                        <TextFieldDisplay
                            oneLine
                            value={props.item.status}
                            icon={'ft-code'}
                            textBackgroundColor={colorByStatus(props.item.status)}
                            textColor={'#ffffff'}
                            style={{ marginBottom: 4 }}/>
    
                        <TextFieldDisplay
                            oneLine
                            label={'descrierea solicitarii'}
                            value={props.item.description}
                            icon={'ft-align-justify'}
                            style={{ marginBottom: 4 }}/>
                    </div>
                    
                    <div className={'list-item-section'} style={{ borderLeftWidth: 1 }}>
                        <TextFieldDisplay
                            oneLine
                            label={'client'}
                            value={props.item.clientInfo.title}
                            icon={'ft-briefcase'}
                            action={'ft-eye'}
                            onPress={() => { toClientPage(props.item.client); }}
                            style={{ marginBottom: 4 }}/>
    
                        <TextFieldDisplay
                            oneLine
                            label={'cod de identificare fiscala'}
                            value={props.item.clientInfo.cid}
                            icon={'ft-hash'}
                            style={{ marginBottom: 4 }}/>
                        
                        <TextFieldDisplay
                            oneLine
                            label={'sediul social al clientului'}
                            value={props.item.clientInfo.address}
                            icon={'ft-map-pin'}
                            style={{ marginBottom: 4 }}/>
                    </div>
                    
                    <div className={'list-item-section-with-button'}>
                        
                        <div className={'inside-list-item-section'}>
                            <TextFieldDisplay
                                oneLine
                                label={'creat la data'}
                                value={prettyDateAndTime(props.item.created)}
                                icon={'ft-calendar'}
                                style={{ marginBottom: 4 }}/>
    
                            <TextFieldDisplay
                                oneLine
                                label={'de catre operatorul'}
                                value={props.item.creatorInfo.fullName}
                                icon={'ft-users'}
                                style={{ marginBottom: 4 }}/>
                        </div>
                        
                        <div className={'item-action-button'}>
                            <div onClick={() => { props.onPress(props.item); }} className={'item-action-button-inside'}>
                                {'Vezi solicitarea'}
                            </div>
                        </div>
                    
                    </div>
                
                </div>
                
                <TimelineList
                    horizontal
                    items={actions}
                    renderItem={(item) => {
                        return (
                            <div className="general-card card-small-padding h-100" style={{ paddingLeft: 20 }}>
                                <TextFieldDisplay
                                    textBackgroundColor={colorByStatus(item.status)}
                                    textColor={'#ffffff'}
                                    style={{ marginBottom: 4 }}
                                    value={item.status}
                                    icon={'ft-code'}/>
    
                                <TextFieldDisplay
                                    style={{ marginBottom: 4 }}
                                    value={item.description}
                                    icon={'ft-align-justify'}/>
                                
                                <TextFieldDisplay
                                    style={{ marginBottom: 4 }}
                                    value={prettyDateAndTime(item.created)}
                                    icon={'ft-clock'}/>
                                
                                <TextFieldDisplay
                                    style={{ marginBottom: 4 }}
                                    value={item.creatorInfo.fullName}
                                    icon={'ft-users'}/>
                                
                                {
                                    ticketActionAdvancedFields(item, this.onPVIPressed)
                                }
                            </div>
                        );
                    }}/>
            
            </div>
        );
    }
    
}

const mapStateToProps = (state) => {
    return {
        updateModal: state.application.modals.updateModal,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        openPDFViewModal: (link, data) => { return dispatch(applicationOpenPDFViewModal({ link, data })); },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListItem);