export const kQuantityTypes = [
    {
        label: 'bucati',
        value: 'bucati',
    },
    {
        label: 'metri',
        value: 'metri',
    },
    {
        label: 'kilograme',
        value: 'kilograme',
    },
    {
        label: 'litri',
        value: 'litri',
    },
];


export const kFilterTicketFields = [
    {
        value: 'title',
        label: 'Denumirea solicitarii',
    },
    {
        value: 'status',
        label: 'Statusul solicitarii',
    },
    {
        value: 'description',
        label: 'Descrierea generala a solicitarii',
    },
    {
        value: 'created',
        label: 'Data si ora adaugarii',
    },
];
export const kFilterTicketFieldsDefault = [ 'title', 'status', 'description', 'created' ];

export const kFilterMailFields = [
    {
        value: 'fromAddress',
        label: 'Adresa sursei',
    },
    {
        value: 'fromName',
        label: 'Numele sursei',
    },
    {
        value: 'subject',
        label: 'Subiectul mesajului',
    },
    {
        value: 'text',
        label: 'Continutul mesajului',
    },
];
export const kFilterMailFieldsDefault = [ 'fromAddress', 'fromName', 'subject', 'text' ];

export const kFilterClientFields = [
    {
        value: 'title',
        label: 'Denumirea clientului',
    },
    {
        value: 'cid',
        label: 'Codul de identificare fiscala',
    },
    {
        value: 'tri',
        label: 'Nr. inregistrare reg. comert',
    },
    {
        value: 'bankTitle',
        label: 'Banca',
    },
    {
        value: 'bankAccount',
        label: 'IBAN cont bancar',
    },
    {
        value: 'address',
        label: 'Adresa sediului social',
    },
];
export const kFilterClientFieldsDefault = [ 'title', 'cid', 'tri', 'bankTitle', 'bankAccount', 'address' ];

export const kFilterSaleFields = [
    {
        value: 'name',
        label: 'Numarul avizului',
    },
];
export const kFilterSaleFieldsDefault = [ 'name' ];

export const kFilterInvoiceFields = [
    {
        value: 'name',
        label: 'Numarul facturii',
    },
    {
        value: 'client_name',
        label: 'Numele clientului',
    },
    {
        value: 'client_cif',
        label: 'Codul fiscal al clientului',
    },
    {
        value: 'client_reg',
        label: 'Numarul de inregistrare al clientului',
    },
];
export const kFilterInvoiceFieldsDefault = [ 'name', 'client_name', 'client_cif', 'client_reg' ];

export const kFilterSalesFields = [
    {
        value: 'clientName',
        label: 'Numele clientului',
    },
    {
        value: 'clientCIF',
        label: 'Codul fiscal al clientului',
    },
];
export const kFilterSalesFieldsDefault = [ 'clientName', 'clientCIF' ];

export const kFilterStockFields = [
    {
        value: 'title',
        label: 'Denumirea produsului',
    },
    {
        value: 'class',
        label: 'Clasa produsului',
    },
    {
        value: 'description',
        label: 'Descrierea produsului',
    },
    {
        value: 'model',
        label: 'Modelul produsului',
    },
];
export const kFilterStockFieldsDefault = [ 'title', 'class', 'description', 'model' ];

export const kFilterSupplierFields = [
    {
        value: 'name',
        label: 'Denumirea furnizorului',
    },
    {
        value: 'cif',
        label: 'Codul de identificare fiscala',
    },
    {
        value: 'reg',
        label: 'Nr. inregistrare reg. comert',
    },
    {
        value: 'bank',
        label: 'Banca',
    },
    {
        value: 'iban',
        label: 'IBAN cont bancar',
    },
    {
        value: 'address',
        label: 'Adresa sediului social',
    },
];
export const kFilterSupplierFieldsDefault = [ 'name', 'cif', 'reg', 'bank', 'iban', 'address' ];

export const kFilterOperatorFields = [
    {
        value: 'first_name',
        label: 'Prenumele utilizatorului',
    },
    {
        value: 'last_name',
        label: 'Numele utilizatorului',
    },
    {
        value: 'username',
        label: 'Nume autentificare',
    },
    {
        value: 'email',
        label: 'Email',
    },
    {
        value: 'phone',
        label: 'Numar telefon',
    },
];
export const kFilterOperatorFieldsDefault = [ 'first_name', 'last_name', 'username', 'email', 'phone' ];

export const kFilterNecessaryFields = [
    {
        value: 'name',
        label: 'Denumirea piesei',
    },
];
export const kFilterNecessaryFieldsDefault = [ 'name' ];

export const kFilterOrderFields = [
    {
        value: 'name',
        label: 'Numarul comenzii',
    },
    {
        value: 'supplier_name',
        label: 'Denumirea furnizorului',
    },
];
export const kFilterOrderFieldsDefault = [ 'name', 'supplier_name' ];

export const kOperatorIDs = [
    '6347df954d9f5304cb682cb0',
    '62f39647173060e5698d2781',
    '6184b54422569f94bcee0f1d',
    '6184b51d22569f94bcee0f1c',
    '6184b4a722569f94bcee0f1b',
    '618477554e819b76a58d1b70',
];