import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BaseModal from './model/BaseModal';
import TextFieldDisplay from '../input/TextFieldDisplay';
import { applicationAddSnack } from '../../store/application-store';
import { addSaleMany } from '../../functional/api';
import {
    compareGenerator,
    compareGeneratorString,
    currentMonthNumber,
    prettyDateAndTime,
} from '../../functional/min-support';
import { kFilterSalesFields, kFilterSalesFieldsDefault } from '../../functional/constants';
import TablePage from '../../layout/page/TablePage';
import SimpleButton from '../input/SimpleButton';


class MailViewModal extends Component {
    static propTypes = {
        open: PropTypes.bool,

        title: PropTypes.string,

        onClose: PropTypes.func,
        onMore: PropTypes.func,
        onDone: PropTypes.func,

        onMoreText: PropTypes.string,

        children: PropTypes.array,
    };

    state = {

    };

    componentWillReceiveProps (nextProps, nextContext) {
        if (!this.props.open && nextProps.open) {
            this.update(nextProps);
        }
    }

    clear = () => {
        this.setState({
            image: null,
        });
    };

    update = (props) => {
        this.clear();
    };

    render () {
        const activeItem = this.props.activeItem;
        if (!activeItem) return null;
        return (
            <BaseModal
                overExpand={true}
                open={this.props.open}
                hideDone={false}
                doneActive={true}
                stableHeight={true}
                stableWidth={true}

                title={this.props.title}

                doneActionTitle={'Inchide'}

                onMore={this.props.onMore}
                onMoreText={this.props.onMoreText}

                onClose={this.props.onClose}
                onDone={this.props.onClose}>

                {
                    activeItem.folder === 'draft' &&
                    <SimpleButton icon={'ft-edit-2'} value={'Continua email'} style={{ marginBottom: 10 }} onPress={() => {
                        //toTicketPage(activeItem._id);
                        console.log('on click', activeItem);
                        this.setState({ loadedEmail: activeItem, modalOpen: true });
                    }}/>
                }

                {
                    activeItem.folder === 'template' &&
                    <SimpleButton icon={'ft-edit-2'} value={'Incarca sablon'} style={{ marginBottom: 10 }} onPress={() => {
                        //toTicketPage(activeItem._id);
                        this.setState({ loadedEmail: activeItem, modalOpen: true });
                    }}/>
                }

                <TextFieldDisplay
                    value={activeItem.from}
                    style={{ marginBottom: 4 }}/>

                <TextFieldDisplay
                    value={activeItem.subject}
                    style={{ marginBottom: 4, fontWeight: 600 }}/>

                {
                    true || activeItem.folder === 'sent' &&
                    <div style={{ paddingLeft: 10, paddingRight: 10 }} dangerouslySetInnerHTML={{ __html: activeItem.body }}>
                    </div>
                }

                <div style={{ width: '100%', maxWidth: '100%', overflow: 'auto' }} dangerouslySetInnerHTML={{ __html: activeItem.originalBody }}/>

                <TextFieldDisplay
                    value={prettyDateAndTime(activeItem.created)}
                    style={{ marginBottom: 4 }}/>

                {
                    this.props.children
                }

            </BaseModal>
        );
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        toast: (label, action) => { return dispatch(applicationAddSnack({ label, action })); },
    };
};

export default connect(null, mapDispatchToProps)(withRouter(MailViewModal));
