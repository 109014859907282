import { is } from './min-support';
import { createAccount, login, logout, verifyToken } from './api';
import axios from 'axios';
import { deleteCredentials, getCredentials, saveCredentials, saveLoginUsername } from './storage';
import { colorByStatus, iconByStatus } from './status';

export const logicLogout = (callback) => {
    logout((result) => {
        // Don't really care;
    });
    deleteCredentials();
    callback(true);
};

export const isAuthenticated = (callback) => {
    const credentials = getCredentials();
    if (is(credentials.username) && is(credentials.token)) {
        verifyToken(credentials.token, credentials.username, callback);
    } else {
        callback(false);
    }
};

export const attemptAuthenticate = (username, password, callback) => {
    if (is(username) && is(password)) {
        login(username, password, (successful, token, username, data) => {
            if (successful) {
                saveCredentials(username, token);
                saveLoginUsername(username);
                callback(true, data);
            } else {
                callback(false);
            }
        });
    } else {
        callback(false);
    }
};

export const attemptCreateAccount = (firstName, lastName, username, password, email, county, cid, tri, bankTitle, bankAccount, callback) => {
    if (is(firstName) && is(lastName) && is(username) && is(password) && is(email) && is(county)) {
        createAccount(firstName, lastName, username, password, email, county, cid, tri, bankTitle, bankAccount, (successful, createResult) => {
            if (successful) {
                saveCredentials(createResult.username, createResult.token);
                callback(true, createResult);
            } else {
                callback(false);
            }
        });
    } else {
        callback(false);
    }
};

export const attemptLogout = (callback) => {
    logout((successful) => { if (!successful) console.log('Login not successful!'); });

    deleteCredentials();

    callback();
};

export const mincodeServicesGeneratePDF = (url, callback) => {
    const params = new URLSearchParams();
    params.append('source', url);
    params.append('provider', 'html/uri');
    params.append('mimetype', 'application/pdf');
    params.append('process', 'save');

    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'mincode-user': 'mincode-polidom-host',
            'mincode-password': 'token-token-token-token',
            'mincode-application': 'polidom',
            'mincode-class': 'pdf-generated-production',
        },
    };

    axios.post('https://services.devmincode.ro/media/protected/generate', params, config).then((result) => {
        if (is(result) && result.data.successful) {
            const data = result.data.data;
            if (data !== null) {
                callback(true, data);
            } else {
                callback(false);
            }
        } else {
            callback(false);
        }
    }, (error) => {
        callback(false);
    });
};

export const translateTicketActionList = (actions) => {
    actions.sort((first, second) => {
        if (first.created > second.created) {
            return -1;
        } else if (first.created < second.created) {
            return 1;
        }
        return 0;
    });
    const result = actions.map((action) => {
        return {
            ...action,
            key: action.id,
            icon: iconByStatus(action.to_status),
            color: colorByStatus(action.to_status),
            status: action.to_status,
            'search_first_name': action.user.first_name,
            'search_last_name': action.user.last_name,
        };
    });
    return result;
};

export const translateSaleProducts = (products) => {
    const result = products.map((product) => {
        return {
            ...product,
        };
    });
    return result;
};

export const translateClientHeadquarters = (headquarters) => {
    const result = headquarters.map((hq) => {
        return {
            ...hq,
        };
    });
    return result;
};

export const translateUserOperators = (items) => {
    const result = items.map((item) => {
        return {
            ...item,
        };
    });
    return result;
};

export const translateUserTickets = (items) => {
    const result = items.map((item) => {
        return {
            ...item,
        };
    });
    return result;
};

export const translateSupplierStock = (items) => {
    const result = items.map((item) => {
        return {
            ...item,
        };
    });
    return result;
};

export const translateSupplierOrders = (items) => {
    const result = items.map((item) => {
        return {
            ...item,
        };
    });
    return result;
};

export const translateOrderProducts = (items) => {
    const result = items.map((item) => {
        return {
            ...item,
        };
    });
    return result;
};

export const translateClientTickets = (items) => {
    const result = items.map((item) => {
        return {
            ...item,
        };
    });
    return result;
};

export const translateInvoiceEntries = (papers, sales) => {
    const result = [];
    result.push(...papers);
    result.push(...sales);
    return result;
};

export const translateUser = (data) => {
    if (!data) return data;
    return {
        _id: data._id,
        id: data._id,
        firstName: data.firstName,
        lastName: data.lastName,
        username: data.username,
        level: userLevelTranslate(data.level),
        levelNumber: data.level,
        gender: data.gender,
        applications: data.applications,
        signature: data.signature,
    };
};

const userLevelKeys = {
    0: 'outside',
    150: 'affiliate',
    200: 'operator',
    400: 'admin',
    450: 'affiliate_admin',
    600: 'god',
};

export const userLevelTranslate = (levelNumber) => {
    const keys = Object.keys(userLevelKeys);
    let maxLevel = 0;
    let maxLevelTitle = 'outside';
    for (let i = 0; i < keys.length; ++i) {
        if (keys[ i ] <= levelNumber && keys[ i ] >= maxLevel) {
            maxLevel = keys[ i ];
            maxLevelTitle = userLevelKeys[ keys[ i ] ];
        }
    }
    return maxLevelTitle;
};
