import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


import { addClient } from '../../functional/api';
import BaseModal from './model/BaseModal';
import TextFieldInput from '../input/TextFieldInput';
import { kToastActionError } from '../general/Toast';
import { inputValidationEmail, inputValidationNonEmpty, inputValidationPhone, inputValidationSingleChoice } from '../../functional/min-support';
import Form from '../input/Form';
import {
    applicationAddSnack,
    applicationCloseLoadingModal,
    applicationOpenLoadingModal,
} from '../../store/application-store';
import SingleChoiceField from '../input/SingleChoiceField';

class Modal extends Component {

    static propTypes = {

        open: PropTypes.bool,

        onClose: PropTypes.func,

        toast: PropTypes.func,
        openLoading: PropTypes.func,
        closeLoading: PropTypes.func,
    };

    state = {
        title: '',
        cid: '',
        tri: '',
        bankTitle: '',
        bankAccount: '',
        address: '',
        contactPhone: '',

        emailAddress: '',
        emailPersonName: '',
        emailPersonRank: '',

        country: 'Romania',
        city: '',
        zipCode: '',

        formComplete: true,

        personOrCompany: 'company',

        deliveryAddressSame: 'same',
        deliveryCountry: 'Romania',
        deliveryCity: '',
        deliveryZipCode: '',
        deliveryAddress: '',
    };

    render () {
        if (this.state.personOrCompany === 'person') {
            return (
                <BaseModal
                    stableHeight
                    stableWidth
                    title={'Adauga client'}
                    open={this.props.open}
                    doneActive={this.state.formComplete}
                    onClose={this.onClose}
                    onDone={this.onDone}>


                    <div className="modal-column-container">
                        <div className={'modal-column'}>


                            <SingleChoiceField
                                required
                                validated={inputValidationSingleChoice(this.state.personOrCompany)}

                                icon={'ft-briefcase'}
                                label={'Tipul clientului'}
                                options={[ { value: 'person', label: 'Persoana fizica' }, { value: 'company', label: 'Persoana juridica' } ]}
                                value={this.state.personOrCompany}
                                style={{ marginBottom: 4 }}
                                onSelect={(value) => {
                                    this.setState({
                                        personOrCompany: value,
                                    });
                                }}/>

                            <TextFieldInput
                                title
                                required
                                icon={'blank'}
                                validated={inputValidationNonEmpty(this.state.title)}

                                value={this.state.title}
                                style={{ marginBottom: 4 }}
                                label={'Nume client'}
                                onChange={(newValue) => {
                                    this.setState({
                                        title: newValue,
                                    });
                                }}/>

                            <TextFieldInput
                                required
                                validated={inputValidationNonEmpty(this.state.cid)}
                                icon={'ft-info'}
                                value={this.state.cid}
                                style={{ marginBottom: 4 }}
                                label={'CNP client'}
                                onChange={(newValue) => {
                                    this.setState({
                                        cid: newValue,
                                    });
                                }}/>

                            {
                                false &&
                                <div className="field-group">
                                    <TextFieldInput
                                        icon={'ft-credit-card'}
                                        value={this.state.bankTitle}
                                        style={{ marginBottom: 4 }}
                                        label={'Banca client'}
                                        onChange={(newValue) => {
                                            this.setState({
                                                bankTitle: newValue,
                                            });
                                        }}/>

                                    <TextFieldInput
                                        icon={'blank'}
                                        value={this.state.bankAccount}
                                        style={{ marginBottom: 4 }}
                                        label={'IBAN client'}
                                        onChange={(newValue) => {
                                            this.setState({
                                                bankAccount: newValue,
                                            });
                                        }}/>
                                </div>
                            }
                                
                            <span className="field-group-title">Facturare</span>
                            <div className="field-group">

                                <TextFieldInput
                                    validated={inputValidationNonEmpty(this.state.country)}

                                    required={true}
                                    icon={'ft-map-pin'}
                                    value={this.state.country}
                                    style={{ marginBottom: 4 }}
                                    label={'Tara'}
                                    onChange={(newValue) => {
                                        this.setState({
                                            country: newValue,
                                        });
                                    }}/>

                                <TextFieldInput
                                    validated={inputValidationNonEmpty(this.state.city)}

                                    required={true}
                                    icon={'blank'}
                                    value={this.state.city}
                                    style={{ marginBottom: 4 }}
                                    label={'Oras'}
                                    onChange={(newValue) => {
                                        this.setState({
                                            city: newValue,
                                        });
                                    }}/>

                                <TextFieldInput
                                    required={true}
                                    validated={inputValidationNonEmpty(this.state.address)}
                                    icon={'blank'}
                                    value={this.state.address}
                                    style={{ marginBottom: 4 }}
                                    label={'Adresa'}
                                    onChange={(newValue) => {
                                        this.setState({
                                            address: newValue,
                                        });
                                    }}/>

                                <TextFieldInput
                                    icon={'blank'}
                                    value={this.state.zipCode}
                                    style={{ marginBottom: 4 }}
                                    label={'Cod postal'}
                                    onChange={(newValue) => {
                                        this.setState({
                                            zipCode: newValue,
                                        });
                                    }}/>
                            </div>
                        

                        </div>

                        <div className={'modal-column'} style={{ paddingLeft: 16 }}>

                            <span className="field-group-title">Contact</span>
                            <div className="field-group">
                                <TextFieldInput
                                    validated={inputValidationPhone(this.state.contactPhone)}

                                    required={true}
                                    icon={'ft-phone'}
                                    value={this.state.contactPhone}
                                    style={{ marginBottom: 4 }}
                                    label={'Numar de telefon'}
                                    onChange={(newValue) => {
                                        this.setState({
                                            contactPhone: newValue,
                                        });
                                    }}/>

                                <TextFieldInput
                                    required={true}
                                    validated={inputValidationEmail(this.state.emailAddress)}
                                    icon={'ft-at-sign'}
                                    value={this.state.emailAddress}
                                    style={{ marginBottom: 4 }}
                                    label={'Adresa de email'}
                                    onChange={(newValue) => {
                                        this.setState({
                                            emailAddress: newValue,
                                        });
                                    }}/>
                            </div>

                            <span className="field-group-title">Livrare</span>
                            <div className="field-group">
                                <SingleChoiceField
                                    required
                                    validated={inputValidationSingleChoice(this.state.deliveryAddressSame)}

                                    icon={'ft-truck'}
                                    label={'Adresa de livrare'}
                                    options={[ { value: 'same', label: 'La fel ca cea de facturare' }, { value: 'different', label: 'Diferita fata de ce de facturare' } ]}
                                    value={this.state.deliveryAddressSame}
                                    style={{ marginBottom: 4 }}
                                    onSelect={(value) => {
                                        this.setState({
                                            deliveryAddressSame: value,
                                        });
                                    }}/>

                                {
                                    this.state.deliveryAddressSame === 'different' &&
                                        <>
                                            <TextFieldInput
                                                validated={inputValidationNonEmpty(this.state.deliveryCountry)}

                                                required={true}
                                                icon={'ft-map-pin'}
                                                value={this.state.deliveryCountry}
                                                style={{ marginBottom: 4 }}
                                                label={'Tara'}
                                                onChange={(newValue) => {
                                                    this.setState({
                                                        deliveryCountry: newValue,
                                                    });
                                                }}/>

                                            <TextFieldInput
                                                validated={inputValidationNonEmpty(this.state.deliveryCity)}

                                                required={true}
                                                icon={'blank'}
                                                value={this.state.deliveryCity}
                                                style={{ marginBottom: 4 }}
                                                label={'Oras'}
                                                onChange={(newValue) => {
                                                    this.setState({
                                                        deliveryCity: newValue,
                                                    });
                                                }}/>

                                            <TextFieldInput
                                                validated={inputValidationNonEmpty(this.state.deliveryAddress)}

                                                required={true}
                                                icon={'blank'}
                                                value={this.state.deliveryAddress}
                                                style={{ marginBottom: 4 }}
                                                label={'Adresa'}
                                                onChange={(newValue) => {
                                                    this.setState({
                                                        deliveryAddress: newValue,
                                                    });
                                                }}/>

                                            <TextFieldInput
                                                validated={inputValidationNonEmpty(this.state.deliveryZipCode)}

                                                icon={'blank'}
                                                value={this.state.deliveryZipCode}
                                                style={{ marginBottom: 4 }}
                                                label={'Cod postal'}
                                                onChange={(newValue) => {
                                                    this.setState({
                                                        deliveryZipCode: newValue,
                                                    });
                                                }}/>
                                        </>
                                }
                            </div>

                            
                        </div>

                    </div>

                </BaseModal>
            );
        } else if (this.state.personOrCompany === 'company') {
            return (
                <BaseModal
                    stableHeight
                    stableWidth
                    title={'Adauga client'}
                    open={this.props.open}
                    doneActive={this.state.formComplete}
                    onClose={this.onClose}
                    onDone={this.onDone}>

                    <div className={'modal-column-container'}>

                        <div className={'modal-column'}>
                            <SingleChoiceField
                                required
                                validated={inputValidationSingleChoice(this.state.personOrCompany)}

                                icon={'ft-briefcase'}
                                label={'Tipul clientului'}
                                options={[ { value: 'person', label: 'Persoana fizica' }, { value: 'company', label: 'Persoana juridica' } ]}
                                value={this.state.personOrCompany}
                                style={{ marginBottom: 4 }}
                                onSelect={(value) => {
                                    this.setState({
                                        personOrCompany: value,
                                    });
                                }}/>

                            <span className="field-group-title">Date companie</span>
                            <div className="field-group">

                                <TextFieldInput
                                    title
                                    required
                                    icon={'blank'}
                                    validated={inputValidationNonEmpty(this.state.title)}

                                    value={this.state.title}
                                    style={{ marginBottom: 4 }}
                                    label={'Nume firma client'}
                                    onChange={(newValue) => {
                                        this.setState({
                                            title: newValue,
                                        });
                                    }}/>

                                <TextFieldInput
                                    required
                                    validated={inputValidationNonEmpty(this.state.cid)}

                                    icon={'ft-info'}
                                    value={this.state.cid}
                                    style={{ marginBottom: 4 }}
                                    label={'Cod identificare fiscala'}
                                    onChange={(newValue) => {
                                        this.setState({
                                            cid: newValue,
                                        });
                                    }}/>

                                <TextFieldInput
                                
                                    icon={'blank'}
                                    value={this.state.tri}
                                    style={{ marginBottom: 4 }}
                                    label={'Nr. inregistrare registrul comertului'}
                                    onChange={(newValue) => {
                                        this.setState({
                                            tri: newValue,
                                        });
                                    }}/>
                            </div>

                            <span className="field-group-title">Facturare</span>
                            <div className="field-group">

                                <TextFieldInput
                                    required={true}
                                    validated={inputValidationNonEmpty(this.state.country)}

                                    icon={'ft-map-pin'}
                                    value={this.state.country}
                                    style={{ marginBottom: 4 }}
                                    label={'Tara'}
                                    onChange={(newValue) => {
                                        this.setState({
                                            country: newValue,
                                        });
                                    }}/>

                                <TextFieldInput
                                    validated={inputValidationNonEmpty(this.state.city)}

                                    required={true}
                                    icon={'blank'}
                                    value={this.state.city}
                                    style={{ marginBottom: 4 }}
                                    label={'Oras'}
                                    onChange={(newValue) => {
                                        this.setState({
                                            city: newValue,
                                        });
                                    }}/>

                                <TextFieldInput

                                    icon={'blank'}
                                    value={this.state.address}
                                    style={{ marginBottom: 4 }}
                                    label={'Adresa'}
                                    onChange={(newValue) => {
                                        this.setState({
                                            address: newValue,
                                        });
                                    }}/>

                                <TextFieldInput

                                    icon={'blank'}
                                    value={this.state.zipCode}
                                    style={{ marginBottom: 4 }}
                                    label={'Cod postal'}
                                    onChange={(newValue) => {
                                        this.setState({
                                            zipCode: newValue,
                                        });
                                    }}/>

                            </div>
                        </div>

                        <div className={'modal-column'} style={{ paddingLeft: 16 }}>

                            <span className="field-group-title">Informatii bancare</span>
                            <div className="field-group">

                                <TextFieldInput
                                    icon={'ft-credit-card'}
                                    value={this.state.bankTitle}
                                    style={{ marginBottom: 4 }}
                                    label={'Banca client'}
                                    onChange={(newValue) => {
                                        this.setState({
                                            bankTitle: newValue,
                                        });
                                    }}/>

                                <TextFieldInput
                                    icon={'blank'}
                                    value={this.state.bankAccount}
                                    style={{ marginBottom: 4 }}
                                    label={'IBAN client'}
                                    onChange={(newValue) => {
                                        this.setState({
                                            bankAccount: newValue,
                                        });
                                    }}/>

                            </div>

                            <span className="field-group-title">Contact</span>
                            <div className="field-group">
                                <TextFieldInput
                                    validated={inputValidationPhone(this.state.contactPhone)}

                                    required={true}
                                    icon={'ft-phone'}
                                    value={this.state.contactPhone}
                                    style={{ marginBottom: 4 }}
                                    label={'Numar de telefon'}
                                    onChange={(newValue) => {
                                        this.setState({
                                            contactPhone: newValue,
                                        });
                                    }}/>
                                    
                                <TextFieldInput
                                    required={true}
                                    validated={inputValidationEmail(this.state.emailAddress)}
                                    icon={'ft-at-sign'}
                                    value={this.state.emailAddress}
                                    style={{ marginBottom: 4 }}
                                    label={'Adresa de email'}
                                    onChange={(newValue) => {
                                        this.setState({
                                            emailAddress: newValue,
                                        });
                                    }}/>

                                <TextFieldInput
                                    required={true}
                                    validated={inputValidationNonEmpty(this.state.emailPersonName)}
                                    icon={'blank'}
                                    value={this.state.emailPersonName}
                                    style={{ marginBottom: 4 }}
                                    label={'Numele persoanei de contact'}
                                    onChange={(newValue) => {
                                        this.setState({
                                            emailPersonName: newValue,
                                        });
                                    }}/>

                                <TextFieldInput
                                    icon={'blank'}
                                    value={this.state.emailPersonRank}
                                    style={{ marginBottom: 4 }}
                                    label={'Functia persoanei de contact'}
                                    onChange={(newValue) => {
                                        this.setState({
                                            emailPersonRank: newValue,
                                        });
                                    }}/>
                            </div>

                            <span className="field-group-title">Livrare</span>
                            <div className="field-group">
                                <SingleChoiceField
                                    required
                                    validated={inputValidationSingleChoice(this.state.deliveryAddressSame)}

                                    icon={'ft-truck'}
                                    label={'Adresa de livrare'}
                                    options={[ { value: 'same', label: 'La fel ca cea de facturare' }, { value: 'different', label: 'Diferita fata de ce de facturare' } ]}
                                    value={this.state.deliveryAddressSame}
                                    style={{ marginBottom: 4 }}
                                    onSelect={(value) => {
                                        this.setState({
                                            deliveryAddressSame: value,
                                        });
                                    }}/>

                                {
                                    this.state.deliveryAddressSame === 'different' &&
                                            <>
                                                <TextFieldInput
                                                    validated={inputValidationNonEmpty(this.state.deliveryCountry)}

                                                    required={true}
                                                    icon={'ft-map-pin'}
                                                    value={this.state.deliveryCountry}
                                                    style={{ marginBottom: 4 }}
                                                    label={'Tara'}
                                                    onChange={(newValue) => {
                                                        this.setState({
                                                            deliveryCountry: newValue,
                                                        });
                                                    }}/>
    
                                                <TextFieldInput
                                                    validated={inputValidationNonEmpty(this.state.deliveryCity)}

                                                    required={true}
                                                    icon={'ft-map-pin'}
                                                    value={this.state.deliveryCity}
                                                    style={{ marginBottom: 4 }}
                                                    label={'Oras'}
                                                    onChange={(newValue) => {
                                                        this.setState({
                                                            deliveryCity: newValue,
                                                        });
                                                    }}/>
    
                                                <TextFieldInput
                                                    validated={inputValidationNonEmpty(this.state.deliveryAddress)}

                                                    required={true}
                                                    icon={'ft-map-pin'}
                                                    value={this.state.deliveryAddress}
                                                    style={{ marginBottom: 4 }}
                                                    label={'Adresa'}
                                                    onChange={(newValue) => {
                                                        this.setState({
                                                            deliveryAddress: newValue,
                                                        });
                                                    }}/>
    
                                                <TextFieldInput
                                                    validated={inputValidationNonEmpty(this.state.deliveryZipCode)}

                                                    icon={'ft-map-pin'}
                                                    value={this.state.deliveryZipCode}
                                                    style={{ marginBottom: 4 }}
                                                    label={'Cod postal'}
                                                    onChange={(newValue) => {
                                                        this.setState({
                                                            deliveryZipCode: newValue,
                                                        });
                                                    }}/>
                                            </>
                                }
                            </div>

                            
                        </div>
                        
                    </div>

                </BaseModal>
            );
        } else {
            return null;
        }
        return (
            <BaseModal
                stableHeight
                stableWidth
                title={'Adauga client'}
                open={this.props.open}
                doneActive={this.state.formComplete}
                onClose={this.onClose}
                onDone={this.onDone}>

                <div className={'w-100'}>

                    <Form complete={this.state.formComplete} setComplete={(complete) => { this.setState({ formComplete: complete }); }}>

                        <SingleChoiceField
                            required
                            validated={inputValidationSingleChoice(this.state.personOrCompany)}

                            icon={'ft-briefcase'}
                            label={'Tipul clientului'}
                            options={[ { value: 'person', label: 'Persoana fizica' }, { value: 'company', label: 'Persoana juridica' } ]}
                            value={this.state.personOrCompany}
                            style={{ marginBottom: 4 }}
                            onSelect={(value) => {
                                this.setState({
                                    personOrCompany: value,
                                });
                            }}/>

                        {
                            this.state.personOrCompany === 'person' &&
                                <>
                                    <TextFieldInput
                                        title
                                        required
                                        icon={'blank'}
                                        validated={inputValidationNonEmpty(this.state.title)}

                                        value={this.state.title}
                                        style={{ marginBottom: 4 }}
                                        label={'Nume client'}
                                        onChange={(newValue) => {
                                            this.setState({
                                                title: newValue,
                                            });
                                        }}/>

                                    <TextFieldInput
                                        required
                                        validated={inputValidationNonEmpty(this.state.cid)}
                                        icon={'ft-info'}
                                        value={this.state.cid}
                                        style={{ marginBottom: 4 }}
                                        label={'CNP client'}
                                        onChange={(newValue) => {
                                            this.setState({
                                                cid: newValue,
                                            });
                                        }}/>

                                    <div className="field-group">
                                        <TextFieldInput
                                            icon={'ft-credit-card'}
                                            value={this.state.bankTitle}
                                            style={{ marginBottom: 4 }}
                                            label={'Banca client'}
                                            onChange={(newValue) => {
                                                this.setState({
                                                    bankTitle: newValue,
                                                });
                                            }}/>

                                        <TextFieldInput
                                            icon={'blank'}
                                            value={this.state.bankAccount}
                                            style={{ marginBottom: 4 }}
                                            label={'IBAN client'}
                                            onChange={(newValue) => {
                                                this.setState({
                                                    bankAccount: newValue,
                                                });
                                            }}/>
                                    </div>
                                    

                                    <div className="field-group">

                                        <TextFieldInput
                                            validated={inputValidationNonEmpty(this.state.country)}

                                            required={true}
                                            icon={'ft-map-pin'}
                                            value={this.state.country}
                                            style={{ marginBottom: 4 }}
                                            label={'Tara'}
                                            onChange={(newValue) => {
                                                this.setState({
                                                    country: newValue,
                                                });
                                            }}/>

                                        <TextFieldInput
                                            validated={inputValidationNonEmpty(this.state.city)}

                                            required={true}
                                            icon={'blank'}
                                            value={this.state.city}
                                            style={{ marginBottom: 4 }}
                                            label={'Oras'}
                                            onChange={(newValue) => {
                                                this.setState({
                                                    city: newValue,
                                                });
                                            }}/>

                                        <TextFieldInput
                                            required={true}
                                            validated={inputValidationNonEmpty(this.state.address)}
                                            icon={'blank'}
                                            value={this.state.address}
                                            style={{ marginBottom: 4 }}
                                            label={'Adresa'}
                                            onChange={(newValue) => {
                                                this.setState({
                                                    address: newValue,
                                                });
                                            }}/>

                                        <TextFieldInput
                                            icon={'blank'}
                                            value={this.state.zipCode}
                                            style={{ marginBottom: 4 }}
                                            label={'Cod postal'}
                                            onChange={(newValue) => {
                                                this.setState({
                                                    zipCode: newValue,
                                                });
                                            }}/>
                                    </div>
                                    

                                    <SingleChoiceField
                                        required
                                        validated={inputValidationSingleChoice(this.state.deliveryAddressSame)}

                                        icon={'ft-truck'}
                                        label={'Adresa de livrare'}
                                        options={[ { value: 'same', label: 'La fel ca cea de facturare' }, { value: 'different', label: 'Diferita fata de ce de facturare' } ]}
                                        value={this.state.deliveryAddressSame}
                                        style={{ marginBottom: 4 }}
                                        onSelect={(value) => {
                                            this.setState({
                                                deliveryAddressSame: value,
                                            });
                                        }}/>

                                    {
                                        this.state.deliveryAddressSame === 'different' &&
                                            <div className="field-group">
                                                <TextFieldInput
                                                    validated={inputValidationNonEmpty(this.state.deliveryCountry)}

                                                    required={true}
                                                    icon={'ft-map-pin'}
                                                    value={this.state.deliveryCountry}
                                                    style={{ marginBottom: 4 }}
                                                    label={'Tara livrare'}
                                                    onChange={(newValue) => {
                                                        this.setState({
                                                            deliveryCountry: newValue,
                                                        });
                                                    }}/>
    
                                                <TextFieldInput
                                                    validated={inputValidationNonEmpty(this.state.deliveryCity)}

                                                    required={true}
                                                    icon={'ft-map-pin'}
                                                    value={this.state.deliveryCity}
                                                    style={{ marginBottom: 4 }}
                                                    label={'Oras livrare'}
                                                    onChange={(newValue) => {
                                                        this.setState({
                                                            deliveryCity: newValue,
                                                        });
                                                    }}/>
    
                                                <TextFieldInput
                                                    validated={inputValidationNonEmpty(this.state.deliveryAddress)}

                                                    required={true}
                                                    icon={'ft-map-pin'}
                                                    value={this.state.deliveryAddress}
                                                    style={{ marginBottom: 4 }}
                                                    label={'Adresa livrare'}
                                                    onChange={(newValue) => {
                                                        this.setState({
                                                            deliveryAddress: newValue,
                                                        });
                                                    }}/>
    
                                                <TextFieldInput
                                                    validated={inputValidationNonEmpty(this.state.deliveryZipCode)}

                                                    icon={'ft-map-pin'}
                                                    value={this.state.deliveryZipCode}
                                                    style={{ marginBottom: 4 }}
                                                    label={'Cod postal livrare'}
                                                    onChange={(newValue) => {
                                                        this.setState({
                                                            deliveryZipCode: newValue,
                                                        });
                                                    }}/>
                                            </div>
                                    }

                                    <TextFieldInput
                                        validated={inputValidationPhone(this.state.contactPhone)}

                                        required={true}
                                        icon={'ft-phone'}
                                        value={this.state.contactPhone}
                                        style={{ marginBottom: 4 }}
                                        label={'Numar de telefon'}
                                        onChange={(newValue) => {
                                            this.setState({
                                                contactPhone: newValue,
                                            });
                                        }}/>

                                    <TextFieldInput
                                        required={true}
                                        validated={inputValidationEmail(this.state.emailAddress)}
                                        icon={'ft-at-sign'}
                                        value={this.state.emailAddress}
                                        style={{ marginBottom: 4 }}
                                        label={'Adresa de email'}
                                        onChange={(newValue) => {
                                            this.setState({
                                                emailAddress: newValue,
                                            });
                                        }}/>
                                </>
                        }

                        {
                            this.state.personOrCompany === 'company' &&
                            <>
                                <TextFieldInput
                                    title
                                    required
                                    icon={'blank'}
                                    validated={inputValidationNonEmpty(this.state.title)}

                                    value={this.state.title}
                                    style={{ marginBottom: 4 }}
                                    label={'Nume firma client'}
                                    onChange={(newValue) => {
                                        this.setState({
                                            title: newValue,
                                        });
                                    }}/>

                                <div className="field-group">

                                    <TextFieldInput
                                        required
                                        validated={inputValidationNonEmpty(this.state.cid)}

                                        icon={'ft-info'}
                                        value={this.state.cid}
                                        style={{ marginBottom: 4 }}
                                        label={'Cod identificare fiscala'}
                                        onChange={(newValue) => {
                                            this.setState({
                                                cid: newValue,
                                            });
                                        }}/>

                                    <TextFieldInput
                                
                                        icon={'blank'}
                                        value={this.state.tri}
                                        style={{ marginBottom: 4 }}
                                        label={'Nr. inregistrare registrul comertului'}
                                        onChange={(newValue) => {
                                            this.setState({
                                                tri: newValue,
                                            });
                                        }}/>
                                </div>

                                <div className="field-group">

                                    <TextFieldInput
                                        icon={'ft-credit-card'}
                                        value={this.state.bankTitle}
                                        style={{ marginBottom: 4 }}
                                        label={'Banca client'}
                                        onChange={(newValue) => {
                                            this.setState({
                                                bankTitle: newValue,
                                            });
                                        }}/>

                                    <TextFieldInput
                                        icon={'blank'}
                                        value={this.state.bankAccount}
                                        style={{ marginBottom: 4 }}
                                        label={'IBAN client'}
                                        onChange={(newValue) => {
                                            this.setState({
                                                bankAccount: newValue,
                                            });
                                        }}/>

                                </div>

                                <div className="field-group">

                                    <TextFieldInput
                                        required={true}
                                        validated={inputValidationNonEmpty(this.state.country)}

                                        icon={'ft-map-pin'}
                                        value={this.state.country}
                                        style={{ marginBottom: 4 }}
                                        label={'Tara'}
                                        onChange={(newValue) => {
                                            this.setState({
                                                country: newValue,
                                            });
                                        }}/>

                                    <TextFieldInput
                                        validated={inputValidationNonEmpty(this.state.city)}

                                        required={true}
                                        icon={'blank'}
                                        value={this.state.city}
                                        style={{ marginBottom: 4 }}
                                        label={'Oras'}
                                        onChange={(newValue) => {
                                            this.setState({
                                                city: newValue,
                                            });
                                        }}/>

                                    <TextFieldInput

                                        icon={'blank'}
                                        value={this.state.address}
                                        style={{ marginBottom: 4 }}
                                        label={'Adresa sediu social'}
                                        onChange={(newValue) => {
                                            this.setState({
                                                address: newValue,
                                            });
                                        }}/>

                                    <TextFieldInput

                                        icon={'blank'}
                                        value={this.state.zipCode}
                                        style={{ marginBottom: 4 }}
                                        label={'Cod postal'}
                                        onChange={(newValue) => {
                                            this.setState({
                                                zipCode: newValue,
                                            });
                                        }}/>

                                </div>

                                <SingleChoiceField
                                    required
                                    validated={inputValidationSingleChoice(this.state.deliveryAddressSame)}

                                    icon={'ft-truck'}
                                    label={'Adresa de livrare'}
                                    options={[ { value: 'same', label: 'La fel ca cea de facturare' }, { value: 'different', label: 'Diferita fata de ce de facturare' } ]}
                                    value={this.state.deliveryAddressSame}
                                    style={{ marginBottom: 4 }}
                                    onSelect={(value) => {
                                        this.setState({
                                            deliveryAddressSame: value,
                                        });
                                    }}/>

                                {
                                    this.state.deliveryAddressSame === 'different' &&
                                            <div className="field-group">
                                                <TextFieldInput
                                                    validated={inputValidationNonEmpty(this.state.deliveryCountry)}

                                                    required={true}
                                                    icon={'ft-map-pin'}
                                                    value={this.state.deliveryCountry}
                                                    style={{ marginBottom: 4 }}
                                                    label={'Tara livrare'}
                                                    onChange={(newValue) => {
                                                        this.setState({
                                                            deliveryCountry: newValue,
                                                        });
                                                    }}/>
    
                                                <TextFieldInput
                                                    validated={inputValidationNonEmpty(this.state.deliveryCity)}

                                                    required={true}
                                                    icon={'ft-map-pin'}
                                                    value={this.state.deliveryCity}
                                                    style={{ marginBottom: 4 }}
                                                    label={'Oras livrare'}
                                                    onChange={(newValue) => {
                                                        this.setState({
                                                            deliveryCity: newValue,
                                                        });
                                                    }}/>
    
                                                <TextFieldInput
                                                    validated={inputValidationNonEmpty(this.state.deliveryAddress)}

                                                    required={true}
                                                    icon={'ft-map-pin'}
                                                    value={this.state.deliveryAddress}
                                                    style={{ marginBottom: 4 }}
                                                    label={'Adresa livrare'}
                                                    onChange={(newValue) => {
                                                        this.setState({
                                                            deliveryAddress: newValue,
                                                        });
                                                    }}/>
    
                                                <TextFieldInput
                                                    validated={inputValidationNonEmpty(this.state.deliveryZipCode)}

                                                    icon={'ft-map-pin'}
                                                    value={this.state.deliveryZipCode}
                                                    style={{ marginBottom: 4 }}
                                                    label={'Cod postal livrare'}
                                                    onChange={(newValue) => {
                                                        this.setState({
                                                            deliveryZipCode: newValue,
                                                        });
                                                    }}/>
                                            </div>
                                }

                                <TextFieldInput
                                    validated={inputValidationPhone(this.state.contactPhone)}

                                    required={true}
                                    icon={'ft-phone'}
                                    value={this.state.contactPhone}
                                    style={{ marginBottom: 4 }}
                                    label={'Numar de telefon'}
                                    onChange={(newValue) => {
                                        this.setState({
                                            contactPhone: newValue,
                                        });
                                    }}/>

                                <div className="field-group">

                                    <TextFieldInput
                                        required={true}
                                        validated={inputValidationEmail(this.state.emailAddress)}
                                        icon={'ft-at-sign'}
                                        value={this.state.emailAddress}
                                        style={{ marginBottom: 4 }}
                                        label={'Adresa de email'}
                                        onChange={(newValue) => {
                                            this.setState({
                                                emailAddress: newValue,
                                            });
                                        }}/>

                                    <TextFieldInput
                                        required={true}
                                        validated={inputValidationNonEmpty(this.state.emailPersonName)}
                                        icon={'blank'}
                                        value={this.state.emailPersonName}
                                        style={{ marginBottom: 4 }}
                                        label={'Numele persoanei de contact'}
                                        onChange={(newValue) => {
                                            this.setState({
                                                emailPersonName: newValue,
                                            });
                                        }}/>

                                    <TextFieldInput
                                        icon={'blank'}
                                        value={this.state.emailPersonRank}
                                        style={{ marginBottom: 4 }}
                                        label={'Functia persoanei de contact'}
                                        onChange={(newValue) => {
                                            this.setState({
                                                emailPersonRank: newValue,
                                            });
                                        }}/>

                                </div>

                            </>
                        }


                    </Form>

                </div>

            </BaseModal>
        );
    }

    onClose = () => {
        this.props.onClose();
    };

    onDone = () => {
        const request = {
            title: this.state.title,
            cid: this.state.cid,
            tri: this.state.tri,
            bankTitle: this.state.bankTitle,
            bankAccount: this.state.bankAccount,
            address: this.state.address,
            contactPhone: this.state.contactPhone,
            emailAddresses: [
                {
                    address: this.state.emailAddress,
                    personName: this.state.emailPersonName,
                    personRank: this.state.emailPersonRank,
                },
            ],
            deliveryAddressSame: this.state.deliveryAddressSame,
            deliveryCountry: this.state.deliveryAddress,
            deliveryCity: this.state.deliveryCity,
            deliveryAddress: this.state.deliveryAddress,
            deliveryZipCode: this.state.deliveryZipCode,
        };

        this.props.openLoading();

        addClient(request, (successful) => {
            if (successful) {
                this.clear();
                this.props.toast('Client inregistrat cu succes.');
            } else {
                this.props.toast('Operatiune esuata!', kToastActionError);
            }
            this.props.closeLoading();
            this.onClose();
        });
    };

    componentWillReceiveProps (nextProps, nextContext) {
        if (!this.props.open && nextProps.open) {
            this.update();
        }
    }

    update = () => {

    };

    clear = () => {
        this.setState({
            title: '',
            cid: '',
            tri: '',
            bankTitle: '',
            bankAccount: '',
            address: '',
            contactPhone: '',
            emailAddress: '',
            emailPersonName: '',
            emailPersonRank: '',
        });
    };

}

const mapStateToProps = (state) => {
    return {
        user: state.application.user,

        updateModal: state.application.modals.updateModal,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toast: (label, action) => { return dispatch(applicationAddSnack({ label, action })); },

        openLoading: () => { return dispatch(applicationOpenLoadingModal()); },
        closeLoading: () => { return dispatch(applicationCloseLoadingModal()); },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
