import React, { useEffect, useState } from 'react';
import TextFieldDisplay from '../input/TextFieldDisplay';
import { prettyDateAndTime, smallIcon } from '../../functional/min-support';
import HorizontalSnapper from '../lists/HorizontalSnapper';
import ImageFieldDisplay from '../input/ImageFieldDisplay';
import { connect } from 'react-redux';
import { applicationOpenPDFViewModal } from '../../store/application-store';

import Autosuggest from 'react-autosuggest';

const MailInput = (props) => {
    const { allAddresses, selected, setSelected } = props;

    const [ value, setValue ] = useState('');

    const [ suggestions, setSuggestions ] = useState([]);

    useEffect(() => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        const newSuggestions = inputLength === 0 ?
            [] :
            allAddresses.filter((item) => { return item.address.toLowerCase().slice(0, inputLength) === inputValue; });
        setSuggestions(newSuggestions.filter((item) => { return !selected.includes(item.address); }).slice(0, 3));
    }, [ value, allAddresses ]);

    const onValueChange = (event, { newValue }) => {
        setValue(newValue);
    };

    const addSelection = (newValue) => {
        if (newValue.length > 0) {
            setSelected([ ...selected, newValue ]);
            setValue('');
        }
    };

    const removeLast = () => {
        if (selected.length > 0) {
            setSelected(selected.slice(0, selected.length - 1));
        }
    };

    const onKeyPress = (event) => {
        console.log('event', event);
        console.log('event key', event.key);
        console.log('event code', event.code);
        if (event.code === 'Enter') {
            addSelection(value);
            event.preventDefault();
        } else if (event.code === 'Space') {
            addSelection(value);
            event.preventDefault();
        } else if (event.code === 'Backspace') {
            if (value.length === 0) {
                removeLast();
            }
        }
    };

    const removeSelected = (item) => {
        setSelected(selected.filter((element) => { return element !== item; }));
    };

    return (
        <div style={{ width: '100%', flex: 1, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', marginBottom: 4, overflowX: 'hidden' }}>
            {
                smallIcon(props.icon ? props.icon : 'ft-at-sign', null, 12, { marginRight: 12 })
            }
            {
                selected && selected.map((item) => {
                    return (
                        <div onClick={() => { removeSelected(item); }} style={{ padding: '4px 8px', borderRadius: '4px', backgroundColor: '#eee', cursor: 'pointer', marginRight: 4, marginTop: 4 }}>
                            {item}
                            {smallIcon('ft-x', null, 12, { marginLeft: 4 })}
                        </div>
                    );
                })
            }
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={() => {}}
                onSuggestionsClearRequested={() => {}}
                onSuggestionSelected={(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
                    const newSelected = [ ...selected, suggestionValue ];
                    setSelected(newSelected);
                    setValue('');
                }}
                getSuggestionValue={(item) => { return item.address; }}
                renderSuggestion={(item) => {
                    return (
                        <div style={{ backgroundColor: 'white', paddingTop: 4, paddingBottom: 4, cursor: 'pointer' }}>
                            {item.address}
                        </div>
                    );
                }}
                inputProps={{
                    placeholder: props.title ? props.title : 'Adauga destinatar',
                    value,
                    onChange: onValueChange,
                    style: { width: '100%' },
                    onKeyDown: onKeyPress,
                    onBlur: () => { addSelection(value); },
                }}
                containerProps={{
                    style: { flex: 1, backgroundColor: 'white', padding: '4px 8px', marginTop: 4, borderRadius: 4 },
                }}
            />
        </div>

    );
};

export default MailInput;
