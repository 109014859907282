import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getClientGroup } from '../functional/api';
import InternalToolbar from '../components/general/InternalToolbar';
import TextFieldDisplay from '../components/input/TextFieldDisplay';
import { filter } from '../functional/min-support';
import { applicationOpenUpdateModal, applicationSetItemPageTitle } from '../store/application-store';

class ClientGroupPage extends Component {

    static propTypes = {
        history: PropTypes.object,
    };

    static defaultState = {
        item: null,
        search: '',
        modalOpen: false,
    };

    state = ClientGroupPage.defaultState;

    componentWillReceiveProps (nextProps, nextContext) {
        if (this.props.updateModal && !nextProps.updateModal) {
            this.update();
        }
    }

    componentDidMount () {
        this.props.onSetItemPageTitle();
        this.update();
    }

    generalUpdate = () => {
        this.setState(ClientGroupPage.defaultState, this.update);
    };

    update = () => {
        const key = this.props.match.params.key;
        getClientGroup(key, (successful, data) => {
            if (successful) {
                this.setState({
                    item: data,
                });
                this.props.onSetItemPageTitle(data.title);
            }
        });
    };

    render () {
        let clients = this.state.item ? this.state.item.clients : null;
        const toolbarActions = [];
        const toolbarLabels = [
            {
                connection: 'clientii dupa:',
                tags: [ 'Denumirea clientului' ],
            },
        ];
        if (clients && this.state.search.length > 0) {
            clients = filter(clients, [ 'title' ], this.state.search, false);
        }

        return (
            <React.Fragment>
                <InternalToolbar
                    backButton
                    refresh
                    onRefresh={this.generalUpdate}
                    search
                    searchValue={this.state.search}
                    onSearchChange={(newValue) => { this.setState({ search: newValue }); }}
                    actions={toolbarActions}
                    labels={toolbarLabels}/>
                <div className="w-100 h-100 overflow-hidden d-flex flex-row">

                    <div className="h-100 overflow-scroll" style={{ flex: 1, padding: 20 }}>
                        {
                            this.state.item &&
                            <React.Fragment>
                                <div className={'general-card'}>

                                    <TextFieldDisplay
                                        title
                                        value={this.state.item.title}
                                        style={{ marginBottom: 4 }}/>

                                </div>

                                {
                                    clients && clients.length > 0 &&
                                        <h4 style={{ marginTop: 20 }}>
                                            Clientii asociati:
                                        </h4>
                                }

                                {
                                    clients && clients.length > 0 &&
                                    clients.map((item, index) => {
                                        return (
                                            <div key={index} className="d-flex flex-column" style={{ marginBottom: 20 }}>
                                                <div className={'general-card'} style={{ width: '100%' }}>

                                                    <TextFieldDisplay
                                                        title
                                                        value={item.title}
                                                        style={{ marginBottom: 4 }}/>

                                                    <TextFieldDisplay
                                                        label={'cod identificare fiscala'}
                                                        value={item.cid}
                                                        icon={'ft-info'}
                                                        style={{ marginBottom: 4 }}/>

                                                    <TextFieldDisplay
                                                        label={'nr. inregistrare la registrul comertului'}
                                                        value={item.tri}
                                                        icon={'blank'}
                                                        style={{ marginBottom: 4 }}/>

                                                    <TextFieldDisplay
                                                        label={'banca'}
                                                        value={item.bankTitle}
                                                        icon={'ft-credit-card'}
                                                        style={{ marginBottom: 4 }}/>

                                                    <TextFieldDisplay
                                                        label={'IBAN cont bancar'}
                                                        value={item.bankAccount}
                                                        icon={'blank'}
                                                        style={{ marginBottom: 4 }}/>

                                                    <TextFieldDisplay
                                                        label={'adresa sediului social'}
                                                        value={item.address}
                                                        icon={'ft-map-pin'}
                                                        style={{ marginBottom: 4 }}/>

                                                    <TextFieldDisplay
                                                        label={'nr. telefon contact'}
                                                        value={item.contactPhone}
                                                        icon={'ft-phone'}
                                                        style={{ marginBottom: 4 }}/>
                                                </div>

                                            </div>
                                        );
                                    })
                                }

                                {
                                    clients && clients.length <= 0 &&
                                    <h4 style={{ marginTop: 20 }}>
                                        Nicio client asociat
                                    </h4>
                                }

                            </React.Fragment>
                        }

                        {
                            !this.state.item &&
                            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                                <div className="spinner_loader secondary_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            </div>
                        }

                    </div>
                </div>

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.application.user,
        updateModal: state.application.modals.updateModal,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetItemPageTitle: (value) => { return dispatch(applicationSetItemPageTitle(value)); },
        openUpdateModal: (id, previous, type, on) => { return dispatch(applicationOpenUpdateModal({ id, previous, type, on })); },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClientGroupPage));
