import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BaseModal from './model/BaseModal';
import TextFieldDisplay from '../input/TextFieldDisplay';
import { applicationAddSnack } from '../../store/application-store';
import { addSaleMany } from '../../functional/api';
import {
    compareGenerator,
    compareGeneratorString,
    currentMonthNumber,
    prettyDateAndTime,
} from '../../functional/min-support';
import { kFilterSalesFields, kFilterSalesFieldsDefault } from '../../functional/constants';
import TablePage from '../../layout/page/TablePage';


class FileUploadModal extends Component {
    static propTypes = {
        open: PropTypes.bool,

        title: PropTypes.string,

        onClose: PropTypes.func,
        onMore: PropTypes.func,
        onDone: PropTypes.func,

        onMoreText: PropTypes.string,

        children: PropTypes.array,
    };

    state = {
        image: null,
        imageURL: null,

        result: null,
        loading: false,
        error: null,
    };

    componentWillReceiveProps (nextProps, nextContext) {
        if (!this.props.open && nextProps.open) {
            this.update(nextProps);
        }
    }

    clear = () => {
        this.setState({
            image: null,
        });
    };

    update = (props) => {
        this.clear();
    };

    onChange = (event) => {
        this.setState({
            image: event.target.files[ 0 ],
            imageURL: URL.createObjectURL(event.target.files[ 0 ]),
            loading: true,
            error: null,
            result: null,
        }, () => {
            const reader = new FileReader();
            reader.onload = async (e) => {
                const text = e.target.result;
                this.setState({ loading: false, error: null, result: this.parseText(text) });
            };
            reader.readAsText(event.target.files[ 0 ]);
        });
    };

    parseText = (originalString) => {
        const rows = originalString.split('\n');
        const result = [];
        rows.forEach((row, index) => {
            if (index < rows.length - 1) {
                const values = row.split(',');
                if (values.length === 10) {
                    const entry = {
                        clientName: values[ 1 ],
                        clientCIF: values[ 6 ],
                        affiliateUsername: values[ 7 ],
                        valueNoVAT: values[ 2 ],
                        valueWithVAT: values[ 3 ],
                        monthNumber: this.props.monthNumber,
                    };
                    result.push(entry);
                }
            }
        });
        return result;
    }

    render () {
        return (
            <BaseModal
                open={this.props.open}
                hideDone={false}
                doneActive={this.inParameters}
                stableHeight={false}
                stableWidth={true}

                title={this.props.title}

                onMore={this.props.onMore}
                onMoreText={this.props.onMoreText}

                onClose={this.props.onClose}
                onDone={this.onDone}>

                <div className={'image-upload-modal'}>
                    <input ref={(ref) => { return this.uploadElement = ref; }} type={'file'} id={'file-upload-element'} onChange={this.onChange} style={{ width: 0, height: 0 }} accept=".txt"/>
                    <TextFieldDisplay
                        label={this.state.image != null ? 'Ai ales un fisier' : 'Inca nu ai ales un fisier'}
                        value={this.state.image != null ? 'Schimba' : 'Alege'}
                        icon={'ft-image'}
                        action={'edit'}
                        onPress={() => {
                            if (this.uploadElement) {
                                this.uploadElement.click();
                            }
                        }}
                        style={{ marginBottom: 4 }}/>

                    {
                        this.state.image && this.state.result &&
                        <TablePage
                            hideToolbar
                            onRefresh={() => {}}
                            deepSearch={false}
                            loading={this.state.result === null}
                            actions={[]}
                            labels={[]}

                            tableFields={{
                                'client-name': {
                                    title: 'Clientul',
                                    transform: (item) => { return item.clientName; },
                                    action: (item) => { },
                                    compare: compareGeneratorString('clientName'),
                                    icon: 'ft-briefcase',
                                },
                                'client-cif': {
                                    title: 'CIF',
                                    transform: (item) => { return item.clientCIF; },
                                    action: (item) => { },
                                    compare: compareGeneratorString('clientCIF'),
                                    icon: 'ft-hash',
                                },
                                'value-no-vat': {
                                    title: 'Total fara TVA',
                                    transform: (item) => { return `${ item.valueNoVAT } RON`; },
                                    compare: compareGenerator('valueNoVAT'),
                                    icon: 'ft-dollar-sign',
                                },
                                'value-with-vat': {
                                    title: 'Total cu TVA',
                                    transform: (item) => { return `${ item.valueWithVAT } RON`; },
                                    compare: compareGenerator('valueWithVAT'),
                                    icon: 'ft-dollar-sign',
                                },
                                'operator': {
                                    title: 'Afiliat',
                                    transform: (item) => { return item.affiliateUsername; },
                                    action: (item) => { },
                                    compare: compareGeneratorString('affiliateUsername'),
                                    icon: 'ft-user',
                                },
                            }}
                            defaultSort={'client-name'}

                            allListItems={this.state.result}
                            defaultFields={[]}
                            allFields={[]}>
                        </TablePage>
                    }
                </div>

                {
                    this.props.children
                }

            </BaseModal>
        );
    }

    get inParameters () {
        return this.state.image != null && !this.state.loading && !this.state.error;
    }

    onDone = () => {
        //this.props.onDone(this.state.image, this.state.imageURL);

        addSaleMany({ monthNumber: this.props.monthNumber, sales: this.state.result }, () => {
            this.props.onDone();
        });

    };

}

const mapDispatchToProps = (dispatch) => {
    return {
        toast: (label, action) => { return dispatch(applicationAddSnack({ label, action })); },
    };
};

export default connect(null, mapDispatchToProps)(withRouter(FileUploadModal));
