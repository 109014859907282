/**
 * @label Stable Imports
 */

import React from 'react';

/**
 * @label Component Imports
 */

import PageError from '../../components/general/PageError';

/**
 * Logout Page.
 * @returns {JSX.Element}
 */

const LogoutPage = (props) => {

    /**
     * @label Render
     */

    return (
        <>
            <PageError
                art={'•OLA•'}
                title={'O zi faina!'}
                message={'Deautentificare a fost realizata cu succes!'}
                button={{ title: 'Inapoi la Autentificare', link: '/login' }}/>
        </>
    );
};

export default LogoutPage;