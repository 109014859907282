export const counties = [
    {
        'label': 'Alba - AB',
        'value': 'Alba',
    },
    {
        'label': 'Arad - AR',
        'value': 'Arad',
    },
    {
        'label': 'Argeş - AG',
        'value': 'Argeş',
    },
    {
        'label': 'Bacău - BC',
        'value': 'Bacău',
    },
    {
        'label': 'Bihor - BH',
        'value': 'Bihor',
    },
    {
        'label': 'Bistriţa-Năsăud - BN',
        'value': 'Bistriţa-Năsăud',
    },
    {
        'label': 'Botoşani - BT',
        'value': 'Botoşani',
    },
    {
        'label': 'Brăila - BR',
        'value': 'Brăila',
    },
    {
        'label': 'Braşov - BV',
        'value': 'Braşov',
    },
    {
        'label': 'Bucureşti - B',
        'value': 'Bucureşti',
    },
    {
        'label': 'Buzău - BZ',
        'value': 'Buzău',
    },
    {
        'label': 'Călăraşi - CL',
        'value': 'Călăraşi',
    },
    {
        'label': 'Caraş-Severin - CS',
        'value': 'Caraş-Severin',
    },
    {
        'label': 'Cluj - CJ',
        'value': 'Cluj',
    },
    {
        'label': 'Constanţa - CT',
        'value': 'Constanţa',
    },
    {
        'label': 'Covasna - CV',
        'value': 'Covasna',
    },
    {
        'label': 'Dâmboviţa - DB',
        'value': 'Dâmboviţa',
    },
    {
        'label': 'Dolj - DJ',
        'value': 'Dolj',
    },
    {
        'label': 'Galaţi - GL',
        'value': 'Galaţi',
    },
    {
        'label': 'Giurgiu - GR',
        'value': 'Giurgiu',
    },
    {
        'label': 'Gorj - GJ',
        'value': 'Gorj',
    },
    {
        'label': 'Harghita - HR',
        'value': 'Harghita',
    },
    {
        'label': 'Hunedoara - HD',
        'value': 'Hunedoara',
    },
    {
        'label': 'Ialomiţa - IL',
        'value': 'Ialomiţa',
    },
    {
        'label': 'Iaşi - IS',
        'value': 'Iaşi',
    },
    {
        'label': 'Ilfov - IF',
        'value': 'Ilfov',
    },
    {
        'label': 'Maramureş - MM',
        'value': 'Maramureş',
    },
    {
        'label': 'Mehedinţi - MH',
        'value': 'Mehedinţi',
    },
    {
        'label': 'Mureş - MS',
        'value': 'Mureş',
    },
    {
        'label': 'Neamţ - NT',
        'value': 'Neamţ',
    },
    {
        'label': 'Olt - OT',
        'value': 'Olt',
    },
    {
        'label': 'Prahova - PH',
        'value': 'Prahova',
    },
    {
        'label': 'Sălaj - SJ',
        'value': 'Sălaj',
    },
    {
        'label': 'Satu Mare - SM',
        'value': 'Satu Mare',
    },
    {
        'label': 'Sibiu - SB',
        'value': 'Sibiu',
    },
    {
        'label': 'Suceava - SV',
        'value': 'Suceava',
    },
    {
        'label': 'Teleorman - TR',
        'value': 'Teleorman',
    },
    {
        'label': 'Timiş - TM',
        'value': 'Timiş',
    },
    {
        'label': 'Tulcea - TL',
        'value': 'Tulcea',
    },
    {
        'label': 'Vâlcea - VL',
        'value': 'Vâlcea',
    },
    {
        'label': 'Vaslui - VS',
        'value': 'Vaslui',
    },
    {
        'label': 'Vrancea - VN',
        'value': 'Vrancea',
    },
];
