import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { renderIf, smallIcon } from '../../functional/min-support';

class TextFieldDisplay extends Component {

    static propTypes = {
        label: PropTypes.string,
        value: PropTypes.string,
        icon: PropTypes.string,

        action: PropTypes.string,
        actionColor: PropTypes.string,

        onPress: PropTypes.func,

        oneLine: PropTypes.bool,

        title: PropTypes.bool,
        onlyLabel: PropTypes.bool,

        style: PropTypes.object,
        textStyle: PropTypes.object,

        hideLabel: PropTypes.bool,
    };

    render () {
        let style = {};
        let textStyle = {};
        const contentStyle = {};
        const valueContainerStyle = {};
        const labelStyle = {};
        let actionColor = '#00000033';
        if (this.props.actionColor) actionColor = this.props.actionColor;
        if (this.props.textStyle) textStyle = { ...this.props.textStyle };
        if (this.props.style) style = { ...this.props.style };
        if (this.props.title) textStyle.fontSize = 20;
        if (this.props.oneLine) textStyle.whiteSpace = 'nowrap';
        if (this.props.textBackgroundColor) contentStyle.backgroundColor = this.props.textBackgroundColor;
        if (this.props.textColor) contentStyle.color = this.props.textColor;
        if (this.props.action) contentStyle.cursor = 'pointer';
        if (!this.props.label) valueContainerStyle.paddingTop = '8px';
        if (this.props.oneLine) style.overflow = 'hidden'; // Scroll - but problem with scroll load
        if (this.props.hideLabel) {
            labelStyle.height = 0;
            labelStyle.opacity = 0;
            labelStyle.padding = 0;
            valueContainerStyle.paddingTop = '8px';
        }

        if (this.props.textBackgroundColor) {
            contentStyle.backgroundColor = this.props.textBackgroundColor;
            contentStyle.border = 0;
            // contentStyle.borderBottomLeftRadius = 4;
            // contentStyle.borderBottomRightRadius = 4;
        }
        if (this.props.textColor) {
            textStyle.color = this.props.textColor;
            labelStyle.color = this.props.textColor;
        }

        let classname = 'text-field-input';

        if (this.props.classname) {
            classname = `${ classname } ${ this.props.classname }`;
        }


        if (this.props.onlyLabel) {

            return (
                <div className={classname} style={style}>

                    {
                        renderIf(this.props.icon,
                            <div className={'text-field-icon'}>
                                {
                                    smallIcon(this.props.icon)
                                }
                            </div>)
                    }

                    <div className={'text-field-label'} style={textStyle}>
                        {
                            this.props.label
                        }
                    </div>

                </div>
            );
        }

        let value = '. . . . . . . . . . . . . . . . . . . . .';
        if (this.props.value && this.props.value.length > 0) {
            value = this.props.value;
        }
        if (this.props.value === '0' || this.props.value === 0) {
            value = this.props.value;
        }

        let contentClassName = 'text-field-content';
        if (this.props.noHover) {
            contentClassName = 'text-field-content-no-hover';
        }

        if (this.props.noBorders) {
            contentStyle.borderBottomLeftRadius = 0;
            contentStyle.borderBottomRightRadius = 0;
            contentStyle.borderTopRightRadius = 0;
            contentStyle.borderTopLeftRadius = 0;
        }

        if (!this.props.noSameLineIcon && this.props.label) {
            textStyle.paddingBottom = 4;
        }

        return (
            <div className={classname} style={style} onClick={this.onClick}>

                {
                    renderIf(this.props.icon && (!this.props.label || this.props.noSameLineIcon),
                        <div className={'text-field-icon'}>
                            {
                                smallIcon(this.props.icon, null, 12)
                            }
                        </div>)
                }

                <div className={contentClassName} style={contentStyle} onClick={this.onPress}>


                    {
                        renderIf(this.props.label && (!this.props.icon || this.props.noSameLineIcon),
                            <div className={'text-field-label'} style={labelStyle}>
                                {
                                    this.props.label
                                }
                            </div>)
                    }

                    {
                        renderIf(this.props.label && this.props.icon && !this.props.noSameLineIcon,
                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>

                                <div className={'text-field-icon'} style={{ marginLeft: 12 }}>
                                    {
                                        smallIcon(this.props.icon, null, 12)
                                    }
                                </div>

                                <div className={'text-field-label'} style={{ ...labelStyle, paddingLeft: 2 }}>
                                    {
                                        this.props.label
                                    }
                                </div>
                            </div>)
                    }

                    <div className={'text-field-value-container'} style={valueContainerStyle}>

                        {
                            this.props.dangerously &&
                            <div
                                className={'text-field-display-value'}
                                style={textStyle} dangerouslySetInnerHTML={{ __html: value }}>
                            </div>
                        }

                        {
                            !this.props.dangerously &&
                            <div
                                className={'text-field-display-value'}
                                style={textStyle}>
                                {
                                    value
                                }
                            </div>
                        }


                        {
                            renderIf(this.props.action,
                                <div className={'text-field-action-icon'}>
                                    {
                                        smallIcon(this.props.action, actionColor)
                                    }
                                </div>)
                        }

                    </div>

                </div>

            </div>
        );
    }

    onPress = () => {
        if (this.props.onPress) {
            this.props.onPress();
        }
    };

}

export default TextFieldDisplay;
