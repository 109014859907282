export const kStatusNew = 'Nou';
export const kStatusNewPersonal = 'Nou';
export const kStatusInProgress = 'In lucru';
export const kStatusStopped = 'Oprit';
export const kStatusAwaitsResponse = 'Asteapta raspuns';
export const kStatusFinished = 'Finalizat';

export const kStatusAssigned = 'Atribuit';
export const kStatusCanceled = 'Anulat';

export const kStatusAll = 'all';


export const kStatusScheduled = 'Programat';

export const kStatusNotInvoiced = 'Nefacturat';

export const kStatusAwaitsApproval = 'Asteapta aprobare';

export const kStatusDescription = {};
kStatusDescription[ kStatusAwaitsApproval ] = 'Solicitari primite direct de la client, inca neaprobate.';
kStatusDescription[ kStatusNew ] = 'Solicitari noi.';
kStatusDescription[ kStatusInProgress ] = 'Solicitari pentru care se desfasoara acum o interventie.';
kStatusDescription[ kStatusStopped ] = 'Solicitari pentru care interventia curenta a fost oprita.';
kStatusDescription[ kStatusAwaitsResponse ] = 'Solicitari pentru care se asteapta raspuns de la client.';
kStatusDescription[ kStatusFinished ] = 'Solicitari finalizate.';
kStatusDescription[ kStatusAssigned ] = 'Solicitari atribuite deja unui operator.';

export const kStatusHasPVI = [];

export const kStatusTransition = {};

kStatusTransition.all = [
    kStatusAssigned,
    kStatusCanceled,
    kStatusFinished,
];

kStatusTransition[ kStatusAssigned ] = [ ...kStatusTransition.all ];

const kStatusColor = {};

// kStatusColor[ kStatusNew ] = '#934A5F';
// kStatusColor[ kStatusInProgress ] = '#632a3a';
// kStatusColor[ kStatusAwaitsResponse ] = '#4e1e2c';
//
// kStatusColor[ kStatusStopped ] = '#3a3247';
// kStatusColor[ kStatusFinished ] = '#282331';
// kStatusColor[ kStatusAll ] = '#17151c';
//
// kStatusColor[ kStatusCanceled ] = '#545459';
// kStatusColor[ kStatusAssigned ] = '#363f5a';

kStatusColor[ kStatusNew ] = '#79170d';
// kStatusColor[ kStatusNew ] = '#560b04';
kStatusColor[ kStatusInProgress ] = '#063860';
// kStatusColor[ kStatusInProgress ] = '#052946';
kStatusColor[ kStatusAwaitsResponse ] = '#000000';

kStatusColor[ kStatusStopped ] = '#f5c245';
kStatusColor[ kStatusFinished ] = '#1c5406';
kStatusColor[ kStatusAll ] = '#17151c';

kStatusColor[ kStatusCanceled ] = '#545459';
kStatusColor[ kStatusAssigned ] = '#363f5a';

const kStatusIcon = {};


kStatusIcon[ kStatusNew ] = 'ft-phone-incoming';
kStatusIcon[ kStatusAwaitsResponse ] = 'ft-watch';
kStatusIcon[ kStatusAssigned ] = 'ft-award';
kStatusIcon[ kStatusInProgress ] = 'ft-play';
kStatusIcon[ kStatusStopped ] = 'ft-pause';
kStatusIcon[ kStatusFinished ] = 'ft-thumbs-up';
kStatusIcon[ kStatusAll ] = 'ft-copy';

export const colorByStatus = (status) => {
    if (kStatusColor.hasOwnProperty(status)) {
        return kStatusColor[ status ];
    } else {
        return '#35373a';
    }
};

export const iconByStatus = (status) => {
    if (kStatusIcon.hasOwnProperty(status)) {
        return kStatusIcon[ status ];
    } else {
        return 'ft-trending-up';
    }
};

export const kActionToStatus = [ kStatusAssigned ];
